import TeamFields from "../fields";
import { Localize } from "components/service";

const teamColumns = [
  {
    title: <Localize>TABLES.Column_Title_TeamName</Localize>,
    dataIndex: "title",
    columnIndex: "TITLE",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_Head</Localize>,
    dataIndex: "relHead",
    columnIndex: "HEAD_ID",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_NumbProjectManager</Localize>,
    dataIndex: "count_project_manager",
    columnIndex: "COUNT_PROJECT_MANAGER",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_NumbDraftsMen</Localize>,
    dataIndex: "count_draftsmen",
    columnIndex: "COUNT_DRAFTSMEN",
    sorter: true,
  },

  {
    // title: '',
    dataIndex: "action",
    align: "right",
    width: "5%",
  },
];

const teamData = (team) => {
  if (!team) {
    return [];
  }

  return team.map((team) => {
    return {
      key: team.id,
      id: team.id,
      title: <TeamFields.Info team={team} />,
      relHead: team?.relHead?.name ? (
        `${team?.relHead?.name} ${team?.relHead?.surname}`
      ) : (
        <strong>&mdash;</strong>
      ),
      count_project_manager: team?.count_project_manager ?? (
        <strong>&mdash;</strong>
      ),
      count_draftsmen: team?.count_draftsmen ?? <strong>&mdash;</strong>,
      action: <TeamFields.Action teamId={team.id} teamName={team.title} />,
    };
  });
};

const TeamsTableHelper = {
  columns: teamColumns,
  data: teamData,
};

export default TeamsTableHelper;
