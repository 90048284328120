import React from "react";

import { Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { variationUseMutation } from "../hooks";
import VariationForms from "../forms";
import {
  EntityRemoveButton,
  Localize,
  ModalStandard,
} from "components/service";
import Icons from "components/icons";

const VariationActionField = ({ variation, variables }) => {
  const { _setMutationVariationDelete, loadingMutationVariationDelete } =
    variationUseMutation.delete(variation?.id);

  const items = [
    {
      key: "1",
      icon: (
        <ModalStandard
          width={500}
          extraClass={"modal-form"}
          modalButton={
            <span style={{ display: "flex", alignItems: "center", width: 100 }}>
              <Icons.Edit /> <Localize>MENUS_DROP.Label_Edit</Localize>
            </span>
          }
        >
          <strong className="modal-title">
            <span className="text">
              <Localize>FORMS.Title_EditVariation</Localize>
            </span>
          </strong>

          <VariationForms.Edit
            groupID={variation?.equipment_group}
            variation={variation}
            variables={variables}
          />
        </ModalStandard>
      ),
    },
    {
      key: "2",
      icon: (
        <EntityRemoveButton
          modalButton={
            <span style={{ display: "flex", alignItems: "center", width: 100  }}>
              <Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize>
            </span>
          }
          nameEntity={
            Localize({ children: "ENTITY.Modal_Title_Variation" }).props
              .children
          }
          dataNameEntity={variation?.title}
          loading={loadingMutationVariationDelete}
          deleteMutation={_setMutationVariationDelete}
          variables={{
            id: variation?.id,
          }}
        />
      ),
    },
  ];

  return (
    <Dropdown
      // trigger={['click']}
      placement="bottomRight"
      menu={{ items }}
      overlayClassName="drop-more-menu"
      arrow
    >
      <EllipsisOutlined className="btn-more" />
    </Dropdown>
  );
};

export default VariationActionField;
