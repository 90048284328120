import { Switch, Form } from "antd";
import { organizerUseMutation } from "../hooks";
import { Localize } from "components/service";

const OrganizerStatusField = ({ id, status }) => {
  const [form] = Form.useForm();

  const { _setOrganizerCreateUpdate } = organizerUseMutation.createUpdate(
    id,
    false
  );

  const valueFieldStatus = Form.useWatch("status", form);

  return (
    <Form form={form} className="">
      <div className="switch-holder">
        <Form.Item
          name="status"
          initialValue={status === "active" ? true : false}
          style={{ marginBottom: 0 }}
        >
          <Switch
            checked={status === "active" ? true : false}
            onChange={(checked) => {
              _setOrganizerCreateUpdate({
                variables: {
                  input: {
                    id,
                    status: checked ? "active" : "closed",
                  },
                },
              });
            }}
          />
        </Form.Item>

        {valueFieldStatus ? (
          <span
            style={{ color: "var(--switch_colorPrimary)" }}
            className="switch-status-text"
          >
            <Localize>GLOBAL.Text_Active</Localize>
          </span>
        ) : (
          <span
            style={{ color: "var(--switch_colorTextQuaternary)" }}
            className="switch-status-text"
          >
            <Localize>GLOBAL.Text_Blocked</Localize>
          </span>
        )}
      </div>
    </Form>
  );
};

export default OrganizerStatusField;
