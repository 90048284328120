import useMutationVariableCreateUpdate from './variable-use-mutation-create-update';
import useMutationVariableDelete from "./variable-use-mutation-delete";
import useMutationVariableUploadFile from "./variable-use-mutation-upload-file";
import useMutationVariableGroupDelete from "./variable-use-mutation-group-delete";


export const variableUseMutation = {
  createUpdate: useMutationVariableCreateUpdate,
  uploadFile: useMutationVariableUploadFile,
  delete: useMutationVariableDelete,
  deleteGroup: useMutationVariableGroupDelete
}