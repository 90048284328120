import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { ORGANIZER_DELETE } from "graphql/mutation/organizer-gql";
import OrganizerConst from "../organizer-const";
import {
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationOrganizerDelete = (id) => {
  const history = useHistory();

  const [_setMutationOrganizerDelete, { loading }] = useMutation(
    ORGANIZER_DELETE,
    {
      update(cache, { data }) {
        const {
          organizerDelete: { label, message },
        } = data;

        history.push(OrganizerConst.basePath);

        cache.evict({
          id: cache.identify({ id, __typename: "Organizer" }),
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message,
        });
      },
      onError(error) {
        errorNotification(error);
      },
    }
  );

  return {
    _setMutationOrganizerDelete,
    loadingMutationOrganizerDelete: loading,
  };
};

export default useMutationOrganizerDelete;
