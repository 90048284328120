import ExhibitionFields from "../fields";
import { Localize } from "components/service";

const exhibitionsColumns = [
  {
    title: <Localize>TABLES.Column_Title_ID</Localize>,
    dataIndex: "id",
    columnIndex: "ID",
    sorter: true,
    width: "4%",
  },
  {
    title: <Localize>TABLES.Column_Title_Name</Localize>,
    dataIndex: "title",
    columnIndex: "TITLE",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_Organizer</Localize>,
    dataIndex: "organizer",
    columnIndex: "ORGANIZER_ID",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_NumberStands</Localize>,
    dataIndex: "total_stands",
    columnIndex: "TOTAL_STANDS",
    // sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_NumberEquipment</Localize>,
    dataIndex: "total_equipment",
    columnIndex: "TOTAL_EQUIPMENT",
    // sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_NumberFlooring</Localize>,
    dataIndex: "total_floorings",
    columnIndex: "TOTAL_FLOORINGS",
    // sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_Status</Localize>,
    dataIndex: "status",
    columnIndex: "STATUS",
    // sorter: true
  },
  {
    // title: '',
    dataIndex: "action",
    align: "right",
    width: "5%",
  },
];

const exhibitionsData = (exhibitions, currentObject) => {
  if (!exhibitions) {
    return [];
  }

  return exhibitions.map((exhibition) => {
    return {
      key: exhibition.id,
      id: exhibition.id,
      title: <ExhibitionFields.Info exhibition={exhibition} />,
      organizer: exhibition.organizer?.title ?? <strong>&mdash;</strong>,
      total_stands: exhibition.total_stands ?? <strong>&mdash;</strong>,
      total_equipment: exhibition.total_equipment ?? <strong>&mdash;</strong>,
      total_floorings: exhibition.total_floorings ?? <strong>&mdash;</strong>,
      status: (
        <ExhibitionFields.Status
          id={exhibition.id}
          status={exhibition?.status}
          dafaultExhibition={exhibition?.default}
        />
      ),
      action: (
        <ExhibitionFields.Action
          exhibition={exhibition}
          organizer={currentObject}
        />
      ),
    };
  });
};

const ExhibitionsTableHelper = {
  columns: exhibitionsColumns,
  data: exhibitionsData,
};

export default ExhibitionsTableHelper;
