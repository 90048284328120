import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import SubPage from "components/pages/equipment/sub-pages";

const EquipmentRoute = ({
  equipment,
  equipmentsGroup,
  history,
  conditionCreate,
  lang,
}) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/variations`}>
        <SubPage.Variations equipment={equipment} lang={lang} />
      </Route>

      <Route path={`${path}/variations/page/:pageNum`}>
        <SubPage.Variations equipment={equipment} lang={lang} />
      </Route>

      <Route path={`${path}/substitude`}>
        <SubPage.Substitude equipment={equipment} lang={lang} />
      </Route>

      <Route path={`${path}`}>
        <SubPage.OverView
          equipment={equipment}
          equipmentsGroup={equipmentsGroup}
          history={history}
          conditionCreate={conditionCreate}
          lang={lang}
        />
      </Route>
    </Switch>
  );
};

export default EquipmentRoute;
