import { useLocation } from "react-router-dom";
import EquipmentFields from "../fields";
import Exhibition from "components/exhibition";
import { Localize } from "components/service";

const equipmentColumns = [
  {
    title: <Localize>TABLES.Column_Title_Image</Localize>,
    dataIndex: "image",
    columnIndex: "IMAGE",
    width: "6%",
    // sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_Name</Localize>,
    dataIndex: "title",
    columnIndex: "TITLE",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_NavisionNumb</Localize>,
    dataIndex: "navision_number",
    columnIndex: "NAVISION_NUMBER",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_OctacadNumb</Localize>,
    dataIndex: "octacad_number",
    columnIndex: "OCTACAD_NUMBER",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_Color</Localize>,
    dataIndex: "color",
    columnIndex: "COLOR",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_SupplierNumb</Localize>,
    dataIndex: "supplier_article",
    columnIndex: "SUPPLIER_ARTICLE",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_Supplier</Localize>,
    dataIndex: "supplier",
    columnIndex: "SUPPLIER",
    // sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_ContentLang</Localize>,
    dataIndex: "lang",
    columnIndex: "LANG",
    sorter: true,
  },
  {
    // title: '',
    dataIndex: "action",
    align: "right",
    width: "5%",
  },
];

const EquipmentData = (
  equipment,
  currentObject,
  variables,
  language,
  extraObj
) => {
  const location = useLocation();

  if (!equipment) {
    return [];
  }

  const containsSubstitude = location.pathname.includes("substitude");
  const containsOrganizers = location.pathname.includes("organizers");
  const containsExhibitions = location.pathname.includes("exhibition-types");

  return equipment.map((equipment) => {
    return {
      key: equipment.id,
      image: <EquipmentFields.Image equipment={equipment} />,
      title: <EquipmentFields.Info equipment={equipment} lang={language} />,
      navision_number: <EquipmentFields.Article equipment={equipment} />,
      octacad_number: equipment?.octacad_number ?? <strong>&mdash;</strong>,
      color: (
        <EquipmentFields.Color equipment={equipment} disabledColor={extraObj} />
      ),
      supplier_article: equipment?.supplier_article ?? <strong>&mdash;</strong>,
      supplier: <EquipmentFields.Supplier equipment={equipment} />,
      lang: <EquipmentFields.Langs equipment={equipment} />,
      action: containsSubstitude ? (
        <EquipmentFields.Remove
          groupID={currentObject.group_id}
          equipment={equipment}
          variables={variables}
        />
      ) : containsOrganizers ? (
        <Exhibition.Fields.Remove
          model="Equipment"
          et_id={currentObject?.et_default_id}
          dataQuery={equipment}
          variables={variables}
        />
      ) : containsExhibitions ? (
        <Exhibition.Fields.Remove
          model="Equipment"
          et_id={currentObject?.id}
          dataQuery={equipment}
          variables={variables}
        />
      ) : (
        <EquipmentFields.Action
          equipment={equipment}
          variables={variables}
          language={language}
        />
      ),
    };
  });
};

const EquipmentsTableHelper = {
  columns: equipmentColumns,
  data: EquipmentData,
};

export default EquipmentsTableHelper;
