import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { GET_EQUIPMENT } from "graphql/query/equipment-gql";
import {
  EQUIPMENT_UPDATE,
  EQUIPMENT_CREATE,
} from "graphql/mutation/equipment-gql";
import EquipmentConst from "../equipment-const";
import {
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationEquipmentCreateUpdate = (id) => {
  const history = useHistory();

  const containsCreate = (url) => url.includes("create");

  const condition = containsCreate(history.location.pathname);

  const EQUIPMENT_CREATE_UPDATE = condition
    ? EQUIPMENT_CREATE
    : EQUIPMENT_UPDATE;
  const equipmentCreateUpdate = condition
    ? "equipmentCreate"
    : "equipmentUpdate";

  const [_setEquipmentCreateUpdate, { loading }] = useMutation(
    EQUIPMENT_CREATE_UPDATE,
    {
      update(cache, { data }) {
        const {
          [equipmentCreateUpdate]: { label, message },
        } = data;

        if (condition) {
          history.push(
            `${EquipmentConst.basePath}/edit/${data[equipmentCreateUpdate]?.equipment?.group_id}/${data[equipmentCreateUpdate]?.equipment?.lang}`
          );
        }

        successNotification({
          title: label,
          description: message,
        });
      },
      onError(error) {
        errorNotification(error);
      },
      refetchQueries: [
        {
          query: GET_EQUIPMENT,
          variables: {
            id: id,
          },
        },
      ],
    }
  );

  return {
    _setEquipmentCreateUpdate,
    loadingMutationEquipmentCreateUpdate: loading,
  };
};

export default useMutationEquipmentCreateUpdate;
