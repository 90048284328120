import { useState } from "react";
import { Button, Input } from "antd";
import { GET_STAND_TYPE_FILES_CURSOR } from "graphql/query/stand-gql";
import { useBreadCrumbs } from "components/use-hooks";
import { ModalStandard, Localize, UploadFile } from "components/service";
import { Tables } from "components/layout";
import Stand from "components/stand";
import Icons from "components/icons";

const { Search } = Input;

const StandFilesSubPage = ({ stand, lang }) => {
  const basePath = Stand.Const.basePath;
  const tableHelper = Stand.Helpers.TableFiles;

  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_StandTypes" }).props
        .children,
      path: `${basePath}`,
    },
    {
      label: stand?.title ?? Localize({ children: "VARIABLE.Label_EMPTY" }),
      path: `${basePath}/edit/${stand?.group_id}/${lang}`,
    },
    {
      label: Localize({ children: "BREADCRUMBS.Label_Files" }).props.children,
      path: `${basePath}/edit/${stand?.group_id}/${lang}/files`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const [variables, setVariables] = useState("");
  const [searchText, setSearchText] = useState("");
  const [dataModel, setDataModel] = useState({});

  const {
    _setStandFileCreateUpdate,
    loadingMutationStandFileCreateUpdate,
    dataMutationStandFile,
  } = Stand.Hooks.fileCreateUpdate({
    variables,
  });

  const standFiles = dataMutationStandFile?.standTypeFileCreate?.standTypeFiles;

  const standFile = standFiles?.filter(
    (obj1) => !dataModel.some((obj2) => obj2.id === obj1.id)
  )[0];

  return (
    <Tables.Cursor
      model="standTypeFilesCursor"
      query={GET_STAND_TYPE_FILES_CURSOR}
      varSet={{ perPage: 50 }}
      tableHelper={tableHelper}
      currentObj={stand}
      setVariables={setVariables}
      searchText={searchText}
      setDataModel={setDataModel}
      objectWhere={{
        column: "STAND_TYPE_GROUP",
        operator: "EQ",
        value: parseInt(stand?.group_id),
      }}
    >
      <div className="table-action-bar">
        <div className="col">
          <Search
            className="filter-search"
            prefix={<Icons.Search />}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>

        <div className="col">
          <UploadFile
            accept="*/*"
            varSetMutation={{
              stand_type_group: stand?.group_id,
            }}
            setMutation={_setStandFileCreateUpdate}
            loading={loadingMutationStandFileCreateUpdate}
            uploadBtn={
              <Button type="primary" icon={<Icons.Plus />}>
                <span className="ellipsis">
                  <Localize>GLOBAL.Button_Text_UploadNew</Localize>
                </span>
              </Button>
            }
          />

          {standFiles && (
            <ModalStandard
              width={500}
              maskClosable={false}
              defaultShow={true}
              closable={false}
              extraClass={"modal-form"}
            >
              <strong className="modal-title">
                <span className="text">
                  <Localize>FORMS.Title_UploadFile</Localize>
                </span>
              </strong>
              <Stand.Forms.EditFile
                stand={stand}
                standFile={standFile}
                variables={variables}
                documentCreate={true}
              />
            </ModalStandard>
          )}
        </div>
      </div>
    </Tables.Cursor>
  );
};

export default StandFilesSubPage;
