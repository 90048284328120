import { useQuery } from "@apollo/client";
import { GET_VARIABLE_LIST_ALL } from "graphql/query/variable-gql";
import { useMe } from "components/use-hooks";

import MockData from "components/mock-data";

const useTranslation = (params) => {
  const { initialLang } = params;

  const { translation } = MockData;

  const { loading, me = {} } = useMe("cache-only", true);

  const { data } = useQuery(GET_VARIABLE_LIST_ALL, {
    skip: !Object?.keys(me).length,
  });

  function convertDataFormat(data) {
    const convertedData = {};

    if (!data || !Array.isArray(data)) {
      return convertedData;
    }

    data.forEach((item) => {
      if (item?.slug) {
        const key = item?.slug.toLowerCase();
        const value = item?.value;
        convertedData[key] = value;
      }
    });

    return convertedData;
  }

  const translationServer = (lang) =>
    convertDataFormat(
      data?.variableListAll.filter((item) => item?.lang === lang)
    );

  const defaultLang = "en";

  if (!loading) {
    return {
      ...translation[defaultLang],
      ...translation[initialLang || me.lang_app],
      ...translationServer(initialLang || me.lang_app),
    };
  }

  return { ...translation[defaultLang] };
};

export default useTranslation;
