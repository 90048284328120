import React from "react";
import { Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { standUseMutation } from "../hooks";
import StandForms from "../forms/index";
import {
  EntityRemoveButton,
  Localize,
  ModalStandard,
} from "components/service";
import Icons from "components/icons";

const StandNoteActionField = ({ stand, standNote, variables }) => {
  const { _setMutationStandNoteDelete, loadingMutationStandNoteDelete } =
    standUseMutation.noteDelete({
      id: standNote?.id,
    });

  const items = [
    {
      key: "1",
      icon: (
        <ModalStandard
          width={500}
          extraClass={"modal-form filters"}
          modalButton={
            <span style={{ display: "flex", alignItems: "center", width: 100 }}>
              <Icons.Edit /> <Localize>MENUS_DROP.Label_Edit</Localize>
            </span>
          }
        >
          <strong className="modal-title">
            <span className="text">
              <Localize>FORMS.Title_Text_EditNote</Localize>
            </span>
          </strong>

          <StandForms.EditNote
            stand={stand}
            standNote={standNote}
            variables={variables}
          />
        </ModalStandard>
      ),
    },
    {
      key: "2",
      icon: (
        <EntityRemoveButton
          modalButton={
            <span style={{ display: "flex", alignItems: "center", width: 100 }}>
              <Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize>
            </span>
          }
          nameEntity={
            Localize({ children: "ENTITY.Modal_Title_Note" }).props.children
          }
          dataNameEntity={standNote?.description || ""}
          loading={loadingMutationStandNoteDelete}
          deleteMutation={_setMutationStandNoteDelete}
          variables={{
            id: standNote?.id,
          }}
        />
      ),
    },
  ];

  return (
    <Dropdown
      // trigger={['click']}
      placement="bottomRight"
      menu={{ items }}
      overlayClassName="drop-more-menu"
      arrow
    >
      <EllipsisOutlined className="btn-more" />
    </Dropdown>
  );
};

export default StandNoteActionField;
