import { Button, Table } from 'antd';
import { Localize, ModalStandard } from 'components/service';

import './elements.scss';


const Imported = ({ data, modalTitle = 'The file is import', isImport = false, model = false }) => {

  data = {
    sync_info: {
      [ model ]: {
        records: data?.sync_info?.records,
        new: data?.sync_info?.new,
        updated: data?.sync_info?.updated,
      }
    }
  };


  function convertData(data) {
    let newData = [];
    for (let key in data.sync_info)
    {
      newData.push({
        key: key,
        records: key,
        found: data.sync_info[ key ].records,
        new: data.sync_info[ key ].new,
        update: data.sync_info[ key ].updated,
      });
    }
    return newData;
  }


  let columns = [
    {
      title: <Localize>TABLES.Column_Title_Records</Localize>,
      dataIndex: 'records',
      className: 'primary-color',
    },
    {
      title: <Localize>TABLES.Column_Title_Found</Localize>,
      dataIndex: 'found',
    },
    {
      title: <Localize>TABLES.Column_Title_New</Localize>,
      dataIndex: 'new',
    },

    {
      title: <Localize>TABLES.Column_Title_Updated</Localize>,
      dataIndex: 'update',
    },
  ];


  columns = isImport ?
    columns.filter(column => column.title !== 'New') : columns;


  const Inner = ({ action }) => (
    <div className='holder-importing'>

      <strong className='modal-title'><span className='text'><Localize>{ modalTitle }</Localize></span></strong>

      <Table className='modal-table' columns={ columns } dataSource={ convertData(data) } pagination={ false } />

      <Button
        className="light-bg"
        onClick={ action }
      >
        Ok
      </Button>

    </div>
  )


  return (

    <ModalStandard
      width={ 400 }
      extraClass={ 'modal-form' }
      defaultShow={ true } >

      <Inner modalTitle={ modalTitle } />

    </ModalStandard>

  );
}

export default Imported;



