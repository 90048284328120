import { useApolloClient } from "@apollo/client";
import { GET_STAND_TYPES_GROUP } from "graphql/query/stand-gql";
import { GET_ME } from "graphql/query/user-gql";
import Stand from "components/stand";
import { useBreadCrumbs } from "components/use-hooks";
import { Localize } from "components/service";
import { UploadMedia } from "components/layout";

const standBasePath = Stand.Const.basePath;

const StandOverviewSubPage = ({
  standTypesGroup,
  conditionCreate,
  stand,
  lang,
}) => {
  const client = useApolloClient();
  const cachedMe = client.readQuery({
    query: GET_ME,
  });

  const defaultLang = cachedMe.me.lang_api;
  const activeLang = lang || defaultLang;

  const disabledField =
    stand?.id !== stand?.group_id ||
    (conditionCreate && activeLang !== defaultLang);

  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_StandTypes" }).props
        .children,
      path: `${standBasePath}`,
    },
    {
      label: stand
        ? stand?.title
        : Localize({ children: "BREADCRUMBS.Label_Create" }).props.children,
      path: stand
        ? `${standBasePath}/edit/${stand?.group_id}/${lang}`
        : `${standBasePath}/create/${lang}`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  return (
    <>
      <Stand.Fields.Langs
        stand={stand}
        standTypesGroup={standTypesGroup}
        activeLang={activeLang}
        conditionCreate={conditionCreate}
        extraClass="form"
      />

      <div className="row-grid col-lg-2">
        <div className={`col-left ${stand?.id && "col-border-right"} `}>
          <Stand.Forms.Edit
            activeLang={activeLang}
            stand={stand}
            conditionCreate={conditionCreate}
            disabledField={disabledField}
          />
        </div>
        <div className="col-right">
          {stand?.id && (
            <UploadMedia
              model="StandType"
              identifier="group_id"
              modelId={stand?.group_id}
              defaultMedia={stand?.getImages}
              query={GET_STAND_TYPES_GROUP}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default StandOverviewSubPage;
