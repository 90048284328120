import React, { useState } from "react";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { useApolloClient } from "@apollo/client";

import { GET_ME } from "graphql/query/user-gql";
import { GET_EQUIPMENT_CURSOR } from "graphql/query/equipment-gql";

import Equipment from "components/equipment";
import Icons from "components/icons";
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import { Localize } from "components/service";
import { gqlBuilderWhere } from "utils";

import "./equipment.scss";

const basePath = Equipment.Const.basePath;
const tableHelper = Equipment.Helpers.TableMain;

const EquipmentsPage = (equipment) => {
  useActiveMenuItem(["equipment"], ["equipment"]);

  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_Equipment" }).props
        .children,
      path: `${basePath}`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const history = useHistory();

  const client = useApolloClient();
  const cachedMe = client.readQuery({
    query: GET_ME,
  });

  const defaultLang = cachedMe.me.lang_api;

  const disabledColor = cachedMe.me.roles.filter(
    ({ slug }) => slug === "role_library_manager"
  ).length ? true : false;

  const objOrderBy = [{ column: "ID", order: "DESC" }];

  const [searchText, setSearchText] = useState("");
  const [viewMode, setViewMode] = useState("card");
  const [filters, setFilters] = useState();
  const [resetSorting, setResetSorting] = useState(false);

  const equipmentFilters = JSON.parse(
    localStorage.getItem(`equipmentFilters_groupID-${equipment?.group_id || 0}`)
  );
  const equipmentsSorting = JSON.parse(
    localStorage.getItem(
      `equipmentsSorting_groupID-${equipment?.group_id || 0}`
    )
  );

  const objectFilters = filters || gqlBuilderWhere(equipmentFilters);

  return (
    <PageWrapDefault
      className="page-equipments"
      dataExist={true}
      title={<Localize>PAGES.Title_Equipment</Localize>}
      staticPath=""
    >
      <Tables.Cursor
        model="equipmentsCursor"
        query={GET_EQUIPMENT_CURSOR}
        varSet={{ perPage: 50 }}
        extraObj={ disabledColor }
        routeUrl={`${basePath}`}
        language={defaultLang}
        searchText={searchText}
        tableHelper={tableHelper}
        viewMode={viewMode}
        viewGrid={Equipment.Helpers.GridMain}
        objOrderBy={equipmentsSorting || objOrderBy}
        sortingName={`equipmentsSorting_groupID-${equipment?.group_id || 0}`}
        resetSorting={ resetSorting }
        objectWhere={{
          ...objectFilters,
          AND: [
            {
              column: "LANG",
              value: defaultLang,
            },
          ],
        }}
      >
        <div className="table-action-bar">
          <div className="col">
            <Equipment.Filters.Table
              {...{
                equipment,
                setSearchText,
                filters,
                setFilters,
                equipmentFilters,
                setResetSorting,
                objOrderBy,
              }}
            />
          </div>

          <div className="col">
            <div className="switch-btn-holder">
              {viewMode === "table" ? (
                <Button
                  onClick={() => setViewMode("card")}
                  icon={<Icons.Grid />}
                />
              ) : (
                <Button
                  onClick={() => setViewMode("table")}
                  icon={<Icons.Table />}
                />
              )}
            </div>

            <Button
              type="primary"
              icon={<Icons.Plus />}
              onClick={() => history.push(`${basePath}/create/${defaultLang}`)}
            >
              <span className="ellipsis">
                <Localize>GLOBAL.Button_Text_CreateNew</Localize>
              </span>
            </Button>
          </div>
        </div>
      </Tables.Cursor>
    </PageWrapDefault>
  );
};

export default EquipmentsPage;
