import { useMutation } from "@apollo/client";
import { GET_STAND_TYPE_EQUIPMENTS_CURSOR } from "graphql/query/stand-gql";
import {
  STAND_TYPE_EQUIPMENT_CREATE,
  STAND_TYPE_EQUIPMENT_UPDATE,
} from "graphql/mutation/stand-gql";

import {
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationStandEquipmentCreateUpdate = (id, variables) => {
  const STAND_TYPE_EQUIPMENT_CREATE_UPDATE = !id
    ? STAND_TYPE_EQUIPMENT_CREATE
    : STAND_TYPE_EQUIPMENT_UPDATE;
  const standCreateUpdate = !id
    ? "standTypeEquipmentCreate"
    : "standTypeEquipmentUpdate";

  const [_setStandEquipmentCreateUpdate, { loading }] = useMutation(
    STAND_TYPE_EQUIPMENT_CREATE_UPDATE,
    {
      update(cache, { data }) {
        const {
          [standCreateUpdate]: { label, message },
        } = data;

        successNotification({
          title: label,
          description: message,
        });
      },
      onError(error) {
        errorNotification(error);
      },
      refetchQueries: [
        {
          query: GET_STAND_TYPE_EQUIPMENTS_CURSOR,
          variables,
        },
      ],
    }
  );

  return {
    _setStandEquipmentCreateUpdate,
    loadingMutationStandEquipmentCreateUpdate: loading,
  };
};

export default useMutationStandEquipmentCreateUpdate;
