import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import SubPage from "components/pages/exhibition/sub-pages";

const ExhibitionRoute = ({ exhibition, history }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/equipment`} exact>
        <SubPage.Equipment exhibition={exhibition} history={history} />
      </Route>
      <Route path={`${path}/flooring`} exact>
        <SubPage.Flooring exhibition={exhibition} history={history} />
      </Route>
      <Route path={`${path}/stand-types`} exact>
        <SubPage.Stand exhibition={exhibition} history={history} />
      </Route>
      <Route path={`${path}`}>
        <SubPage.OverView exhibition={exhibition} history={history} />
      </Route>
    </Switch>
  );
};

export default ExhibitionRoute;
