import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import UsersRoute from "./user/users-route";
import RolesRoute from "./role/roles-route";
import SuppliersRoute from "./supplier/suppliers-route";
import TeamsRoute from "./team/teams-route";
import FlooringsRoute from "./flooring/floorings-route";
import EquipmentsRoute from "./equipment/equipments-route";
import StandsRoute from "./stand/stands-route";
import VariablesRoute from "./variable/variables-route";
import OrganizersRoute from "./organizer/organizers-route";
import ExhibitionsRoute from "./exhibition/exhibitions-route";
import GraphicsRoute from "./graphic/graphics-route";
import { Pages } from "components/pages";

const AppRoutes = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <Redirect to="/users" />
      </Route>

      <Route path="/user-roles">
        <RolesRoute />
      </Route>

      <Route path="/users">
        <UsersRoute />
      </Route>

      <Route path="/suppliers">
        <SuppliersRoute />
      </Route>

      <Route path="/teams">
        <TeamsRoute />
      </Route>

      <Route path="/flooring">
        <FlooringsRoute />
      </Route>

      <Route path="/equipment">
        <EquipmentsRoute />
      </Route>

      <Route path="/stand-types">
        <StandsRoute />
      </Route>

      <Route path="/variable-lists">
        <VariablesRoute />
      </Route>

      <Route path="/graphic-rules">
        <GraphicsRoute />
      </Route>

      <Route path="/organizers">
        <OrganizersRoute />
      </Route>

      <Route path="/exhibition-types">
        <ExhibitionsRoute />
      </Route>

      <Route path="*">
        <Pages.Page404 />
      </Route>
    </Switch>
  );
};

export default AppRoutes;
