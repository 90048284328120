import { useState } from "react";
import { Link } from "react-router-dom";
import { Avatar, Modal } from "antd";
import { FileImageOutlined } from "@ant-design/icons";
import Stand from "components/stand";
import Icons from "components/icons";

const StandImageField = ({ stand, lang, grid = false }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleImageClick = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const getImageUrl = (size) => {
    return !!stand.getImages?.length ? stand.getImages[0]?.sizes[size] : null;
  };

  return (
    <>
      {!grid ? (
        <div className="d-flex align-items-center">
          <div
            style={{
              minWidth: "60px",
              cursor: getImageUrl("200x200") && "pointer",
            }}
            onClick={handleImageClick}
          >
            <Avatar
              shape="square"
              size={50}
              icon={
                getImageUrl("200x200") ? (
                  <img src={getImageUrl("200x200")} alt="img" />
                ) : (
                  <FileImageOutlined />
                )
              }
            />
          </div>
        </div>
      ) : (
        <div>
          <Link
            className="card-image"
            to={`${Stand.Const.basePath}/edit/${stand?.group_id}/${lang}`}
          >
            <Avatar
              shape="square"
              size={100}
              icon={
                getImageUrl("200x200") ? (
                  <img src={getImageUrl("200x200")} alt="img" />
                ) : (
                  <FileImageOutlined />
                )
              }
            />
          </Link>

          {getImageUrl("200x200") && (
            <div className="icon-zoom" onClick={handleImageClick}>
              <Icons.Zoom />
            </div>
          )}
        </div>
      )}

      {getImageUrl("original") && (
        <Modal
          className="modal-image"
          width={800}
          open={modalVisible}
          onCancel={handleCloseModal}
          closeIcon={<Icons.Close />}
          footer={null}
          centered
        >
          <img
            src={getImageUrl("original")}
            alt="img"
            style={{ width: "100%" }}
          />
        </Modal>
      )}
    </>
  );
};

export default StandImageField;
