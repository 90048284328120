import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import SubPage from "components/pages/stand/sub-pages";

const StandTypeRoute = ({
  stand,
  standTypesGroup,
  conditionCreate,
  lang,
  history,
}) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/notes`}>
        <SubPage.Notes stand={stand} lang={lang} history={history} />
      </Route>
      <Route path={`${path}/files`}>
        <SubPage.Files stand={stand} lang={lang} history={history} />
      </Route>
      <Route path={`${path}/floorings`}>
        <SubPage.Floorings stand={stand} lang={lang} history={history} />
      </Route>
      <Route path={`${path}/equipments`}>
        <SubPage.Equipments stand={stand} lang={lang} history={history} />
      </Route>
      <Route path={`${path}`}>
        <SubPage.OverView
          stand={stand}
          standTypesGroup={standTypesGroup}
          conditionCreate={conditionCreate}
          lang={lang}
          history={history}
        />
      </Route>
    </Switch>
  );
};

export default StandTypeRoute;
