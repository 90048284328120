import { Avatar } from 'antd';
import { FileImageOutlined } from '@ant-design/icons';
import VariableForms from '../forms';
import { ModalStandard } from 'components/service';
import { Localize } from 'components/service';


const VariableSlugField = ({ variableList, variables }) => {

    return (

        <div className="d-flex align-items-center">

            <div style={ { minWidth: '45px' } }>
                <Avatar shape="square" size="middle" icon={ variableList?.img_path ? <img src={ variableList?.img_path } alt='avatar' /> : <FileImageOutlined /> } />
            </div>

            <ModalStandard
                width={ 500 }
                extraClass={ 'modal-form' }
                modalButton={
                    <strong className='title' style={ { cursor: 'pointer' } }>{ variableList?.slug }</strong>
                }>

                <strong className='modal-title'><span className='text'><Localize>FORMS.Title_EditKey</Localize></span></strong>

                <VariableForms.Edit variableList={ variableList } variables={ variables } />

            </ModalStandard>
        </div>

    )
};


export default VariableSlugField;