import React, { useState, useEffect } from "react";
import { Button } from "antd";

import { GET_EXHIBITIONS_CURSOR } from "graphql/query/exhibition-gql";
import { GET_ORGANIZERS_CURSOR } from "graphql/query/organizer-gql";

import { FormElements } from "components/layout";
import { Localize } from "components/service";

import Icons from "components/icons";

export const StandOrganizerForm = ({
  form,
  setOrganizers,
  organizers,
  selectedOrganizer,
}) => {
  const [labelModel, setLabelModel] = useState(null);
  const [extraProp, setExtraProp] = useState(null);
  const [organizerId, setOrganizerId] = useState(selectedOrganizer);
  const [btnConfirm, setBtnConfirm] = useState({ addOrganizer: false });

  /*eslint-disable */
  useEffect(() => {
    if (labelModel !== null && labelModel.length) {
      const newOrganizer = {
        id: organizerId,
        organizer_id: organizerId,
        title: labelModel,
        et_default_id: extraProp[0],
      };

      setOrganizers([...organizers, newOrganizer]);

      setLabelModel(null);
    }
  }, [btnConfirm.addOrganizer]);
  /*eslint-enable */

  const handleDeleteOrganizer = (id) => {
    setOrganizers((prevOrganizers) =>
      prevOrganizers.filter((organizer) => organizer.organizer_id !== id)
    );
  };

  return (
    <>
      <br />
      <h5 className="form-heading">
        <Localize>FORMS.Headig_Text_Organizers</Localize>
      </h5>

      {organizers.map((organizer) => {
        return (
          <ul className="stand-organizers-list">
            <li key={organizer?.id}>
              <div className="d-flex justify-content-between align-items-center">
                <h6 style={{ textTransform: "uppercase" }}>
                  {organizer?.title}
                </h6>

                <Button
                  style={{ fontSize: 14, minHeight: 30 }}
                  size="small"
                  icon={<Icons.Delete />}
                  onClick={() => handleDeleteOrganizer(organizer?.organizer_id)}
                >
                  <span className="ellipsis">
                    <Localize>GLOBAL.Button_Text_DeleteOrganizer</Localize>
                  </span>
                </Button>
              </div>
              <FormElements.CheckboxSelect
                form={form}
                query={GET_EXHIBITIONS_CURSOR}
                model="exhibitionTypesCursor"
                name={`et_id-${organizer.organizer_id}`}
                label="FORMS.Input_Label_ExhibitionTypes"
                modalTitle="FORMS.Title_AddExhibitionTypes"
                modalButtonText="MODAL.Button_Text_AddExhibitionType"
                filteredNodes={organizer?.et_default_id}
                filterValue="Regular Exhibition"
                objectWhere={{
                  column: "ORGANIZER_ID",
                  value: organizer.organizer_id,
                }}
                initialValue={
                  organizer?.exhibitionTypes?.length
                    ? organizer.exhibitionTypes.map(({ et_id, title }) => ({
                        value: et_id,
                        label: title,
                      }))
                    : [
                        {
                          value: organizer.et_default_id,
                          label: "Regular Exhibition",
                        },
                      ]
                }
              />
            </li>
          </ul>
        );
      })}

      <FormElements.RadioSelect
        form={form}
        query={GET_ORGANIZERS_CURSOR}
        model="organizersCursor"
        name="organizer_id"
        label="FORMS.Input_Label_Organizers"
        modalTitle="FORMS.Title_AddOrganizers"
        setExtraProp={setExtraProp}
        setLabelModel={setLabelModel}
        setBtnConfirm={setBtnConfirm}
        skipQuery={false}
        formResetConfirm={() => {
          setOrganizerId(selectedOrganizer);
          form.resetFields(["organizer_id"]);
        }}
        extraProp="et_default_id"
        filteredNodes={organizers}
        modalButtonContent={(setSkip) => {
          return (
            <Button
              style={{ fontSize: 14, minHeight: 30 }}
              size="small"
              className="btn-right"
              type="primary"
              icon={<Icons.Plus />}
            >
              <span
                onClick={() => {
                  setSkip(false);
                  setBtnConfirm({
                    addOrganizer: false,
                  });
                }}
                className="ellipsis"
              >
                <Localize>GLOBAL.Button_Text_AddOrganizer</Localize>
              </span>
            </Button>
          );
        }}
      />
    </>
  );
};

export const getInputFromValues = (values, organizers) => {
  const input = [];
  const uniqueEtIds = new Set();

  Object.keys(values).forEach((key) => {
    if (key.startsWith("et_id-")) {
      const organizerId = key.replace("et_id-", "");
      const etIds = values[key];

      const prevEtIds =
        organizers.find((org) => org.organizer_id === organizerId)?.et_id || [];

      const addedEtIds = etIds.filter((etId) => !prevEtIds.includes(etId));
      const removedEtIds = prevEtIds.filter((etId) => !etIds.includes(etId));

      addedEtIds.forEach((etId) => {
        if (!uniqueEtIds.has(etId)) {
          input.push({
            organizer_id: organizerId,
            et_id: etId,
          });
          uniqueEtIds.add(etId);
        }
      });

      removedEtIds.forEach((etId) => {
        if (!uniqueEtIds.has(etId)) {
          input.push({
            organizer_id: organizerId,
            et_id: etId,
          });
          uniqueEtIds.add(etId);
        }
      });
    }
  });

  return input.concat(
    organizers
      .map((organizer) => {
        const etId = organizer.et_default_id;
        if (!uniqueEtIds.has(etId)) {
          uniqueEtIds.add(etId);
          return {
            organizer_id: organizer.organizer_id,
            et_id: etId,
          };
        }
        return null;
      })
      .filter(Boolean)
  );
};
