import React, { useState } from "react";
import { Button, Input } from "antd";
import { useRouteMatch, useHistory } from "react-router-dom";

import { GET_VARIABLE_LISTS } from 'graphql/query/variable-gql';

import Variable from 'components/variable';
import Icons from "components/icons";
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import { EntityRemoveButton, Localize, ModalStandard } from "components/service";

import './variable.scss';



const VariableListPage = () => {

    const basePath = Variable.Const.basePath;
    const tableHelper = Variable.Helpers.TableList();

    const { location: { pathname } } = useHistory();

    const groupName = pathname.substring(pathname.lastIndexOf("/") + 1).replace(/-/g, " ");

    const [ inputValue, setInputValue ] = useState(groupName !== 'create' ? groupName : '');
    const [ variables, setVariables ] = useState({});

    useActiveMenuItem([ "variable-lists" ], [ "variable-lists" ]);

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_VariableLists" }).props.children,
            path: `${ basePath }`
        },
        {
            label: groupName,
            path: `${ basePath }/${ groupName.replace(/ /g, "-") }`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    const objectWhere = {
        AND: [
            {
                column: "LANG",
                operator: "EQ",
                value: "en"
            },
            {
                column: "GROUP_NAME",
                operator: "EQ",
                value: groupName
            }
        ]
    };

    let match = useRouteMatch(`${ basePath }/page/:pageNum`);

    const {
        _setMutationVariableGroupDelete,
        loadingMutationVariableGroupDelete,
    } = Variable.Hooks.deleteGroup(groupName);



    return (
        <PageWrapDefault
            className="page-variables"
            dataExist={ true }
            title={ groupName && groupName !== 'create' ? groupName.charAt(0).toUpperCase() + groupName.slice(1) : <Localize>PAGES.Title_CreateGroup</Localize> }
            staticPath=""
        >
            <Tables.Main
                extraClass="table-variable"
                model="variableLists"
                query={ GET_VARIABLE_LISTS }
                objectWhere={ objectWhere }
                varSet={ { perPage: 30 } }
                routeUrl={ `${ basePath }` }
                currentPage={ match ? parseInt(match.params.pageNum) : 1 }
                tableHelper={ tableHelper }
                setVariables={ setVariables }
            >

                <div className="table-action-bar">

                    <div className="col w-50-md">

                        <div className="w-100">

                            <strong className="label-input"><Localize>FORMS.Input_Label_GroupName</Localize></strong>

                            <Input name="group_name" defaultValue={ inputValue } value={ inputValue } onChange={ (e) => setInputValue(e.target.value) } />
                        </div>

                    </div>

                    <div className="col d-block">

                        { groupName !== 'create' &&
                            <EntityRemoveButton
                                modalButton={
                                    <Button
                                        icon={ <Icons.Delete /> }
                                    >
                                        <span className="ellipsis"><Localize>GLOBAL.Button_Text_DeleteGroup</Localize></span>
                                    </Button>
                                }
                                nameEntity={ Localize({ children: "ENTITY.Modal_Title_Group" }).props.children }
                                dataNameEntity={ groupName }
                                loading={ loadingMutationVariableGroupDelete }
                                deleteMutation={ _setMutationVariableGroupDelete }
                                variables={ {
                                    group_name: groupName
                                } }
                            />
                        }

                        <br className="d-none d-block-md" />
                        <br className="d-none d-block-md" />

                        <ModalStandard
                            width={ 500 }
                            extraClass={ 'modal-form' }
                            disabled={ !(inputValue.length > 0) }
                            modalButton={
                                <Button
                                    type="primary"
                                    icon={ <Icons.Plus /> }>
                                    <span className="ellipsis"><Localize>GLOBAL.Button_Text_AddKey</Localize></span>
                                </Button>
                            }>

                            <strong className='modal-title'><span className='text'><Localize>FORMS.Title_CreateNewKey</Localize></span></strong>

                            <Variable.Forms.Edit inputValue={ inputValue } redirect={ groupName === 'create' } variables={ variables } />

                        </ModalStandard>

                    </div>
                </div>

            </Tables.Main>
        </PageWrapDefault>
    )
};

export default VariableListPage;