import React from "react";
import { useHistory } from "react-router-dom";
import { Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { teamUseMutation } from "../hooks";
import TeamConst from "../team-const";
import { EntityRemoveButton, Localize } from "components/service";
import Icons from "components/icons";

const TeamActionField = ({ teamId, teamName }) => {
  const history = useHistory();

  const { _setMutationTeamDelete, loadingMutationTeamDelete } =
    teamUseMutation.delete(teamId);

  const items = [
    {
      label: <Localize>MENUS_DROP.Label_Edit</Localize>,
      key: "1",
      icon: <Icons.Edit />,
      onClick: () => history.push(`${TeamConst.basePath}/${teamId}`),
    },
    {
      key: "2",
      icon: (
        <EntityRemoveButton
          modalButton={
            <span style={{ display: "flex", alignItems: "center", width: 100  }}>
              <Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize>
            </span>
          }
          nameEntity={
            Localize({ children: "ENTITY.Modal_Title_Team" }).props.children
          }
          dataNameEntity={teamName}
          loading={loadingMutationTeamDelete}
          deleteMutation={_setMutationTeamDelete}
          variables={{
            id: teamId,
          }}
        />
      ),
    },
  ];

  return (
    <Dropdown
      // trigger={['click']}
      placement="bottomRight"
      menu={{ items }}
      overlayClassName="drop-more-menu"
      arrow
    >
      <EllipsisOutlined className="btn-more" />
    </Dropdown>
  );
};

export default TeamActionField;
