import { useLocation, useParams } from "react-router-dom";
import Equipment from "components/equipment";
import Exhibition from "components/exhibition";
import { Localize } from "components/service";

const EquipmentsGridHelper = ({
  model,
  language,
  variables,
  currentObj,
  extraObj,
}) => {
  const location = useLocation();
  const containsSubstitude = location.pathname.includes("substitude");
  const containsOrganizers = location.pathname.includes("organizers");
  const containsExhibitions = location.pathname.includes("exhibition-types");

  const { group_id } = useParams();

  return (
    <div className="card-view-vertical">
      {model?.map((item) => (
        <div key={item.id} className="card">
          <div className="card-image">
            <Equipment.Fields.Image
              equipment={item}
              lang={language}
              grid={true}
            />
          </div>
          <div className="nav-bar">
            <Equipment.Fields.Langs equipment={item} />
            {containsSubstitude ? (
              <Equipment.Fields.Remove
                groupID={group_id}
                equipment={item}
                variables={variables}
              />
            ) : containsOrganizers ? (
              <Exhibition.Fields.Remove
                model="Equipment"
                et_id={currentObj?.et_default_id}
                dataQuery={item}
                variables={variables}
              />
            ) : containsExhibitions ? (
              <Exhibition.Fields.Remove
                model="Equipment"
                et_id={currentObj?.id}
                dataQuery={item}
                variables={variables}
              />
            ) : (
              <Equipment.Fields.Action
                equipment={item}
                variables={variables}
                language={language}
              />
            )}
          </div>
          <div className="card-details">
            <div className="d-flex justify-content-between">
              <span className="title">
                <Equipment.Fields.Info equipment={item} lang={language} />
              </span>
            </div>

            <Equipment.Fields.Article equipment={item} />

            <ul className={`info-list ${extraObj ? "role" : ""}`}>
              <li>
                <Localize>CARD.Info_List_BaseColor</Localize>:
                <span className="info">
                  <Equipment.Fields.Color
                    equipment={item}
                    disabledColor={extraObj}
                  />
                </span>
              </li>
              <li>
                <Localize>CARD.Info_List_OctacadNumb</Localize>:
                <span className="info">
                  {item?.octacad_number ?? <strong>&mdash;</strong>}
                </span>
              </li>
              <li>
                <Localize>CARD.Info_List_SupplierNumb</Localize>:
                <span className="info">
                  {item?.supplier_article ?? <strong>&mdash;</strong>}
                </span>
              </li>
              <li>
                <Localize>CARD.Info_List_Supplier</Localize>:
                <span className="info">
                  <Equipment.Fields.Supplier equipment={item} />
                </span>
              </li>
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EquipmentsGridHelper;
