import React from "react";
import { Localize } from "components/service";

const StandEquipmentRuleField = ({ standEquipment }) => {
  let ruleType = standEquipment?.rule_type;

  switch (ruleType) {
    case "EQ":
      ruleType = "=";
      break;
    case "GTE":
      ruleType = ">=";
      break;
    case "LTE":
      ruleType = "<=";
      break;
    case "BETWEEN":
      ruleType = <Localize>STANDTYPE.Equipment_Rule_BETWEEN</Localize>;
      break;

    default:
      ruleType = "-";
  }

  return (
    <div
      className="d-inline-flex justify-content-center align-items-center"
      style={{
        fontSize: "16px",
        padding: "3px 15px",
        lineHeight: "1.1",
        borderRadius: "10px",
        color: "#fff",
        background: "#4471FF",
        minWidth: "64px",
      }}
    >
      <strong>{ruleType}</strong>
    </div>
  );
};

export default StandEquipmentRuleField;
