import { GET_FLOORING } from "graphql/query/flooring-gql";
import Flooring from "components/flooring";
import { useBreadCrumbs } from "components/use-hooks";
import { Localize } from "components/service";
import { UploadMedia } from "components/layout";

const flooringBasePath = Flooring.Const.basePath;

const FlooringOverviewSubPage = ({ flooring, history }) => {
  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_Flooring" }).props
        .children,
      path: `${flooringBasePath}`,
    },
    {
      label:
        (flooring && `${flooring?.title}`) ||
        Localize({ children: "BREADCRUMBS.Label_Create" }).props.children,
      path:
        (flooring && `${flooringBasePath}/${flooring?.id}`) ||
        `${flooringBasePath}/create`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  return (
    <div className="row-grid col-lg-2">
      <div className={`col-left ${flooring?.id && "col-border-right"} `}>
        <Flooring.Forms.Edit flooring={flooring} history={history} />
      </div>
      <div className="col-right">
        {flooring?.id && (
          <UploadMedia
            model="Flooring"
            modelId={flooring?.id}
            defaultMedia={flooring?.images}
            query={GET_FLOORING}
          />
        )}
      </div>
    </div>
  );
};

export default FlooringOverviewSubPage;
