import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";
import UserConst from "../users-const";
import Icons from "components/icons";

const UserInfoField = ({ user }) => {
  const { sendPassword, id, name, surname } = user;

  return (
    <div className="flex-field-box">
      {sendPassword?.created_at && (
        <span className="col">
          <Tooltip
            // overlayClassName="model-tooltip"
            destroyTooltipOnHide={true}
            title={sendPassword?.message}
          >
            <span className="icon">
              <Icons.Padlock />
            </span>
          </Tooltip>
        </span>
      )}

      <span className="col">
        <Link to={`${UserConst.basePath}/${id}`}> {`${name} ${surname}`}</Link>
      </span>
    </div>
  );
};

export default UserInfoField;