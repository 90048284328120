import { useMutation } from "@apollo/client";

import { VARIABLE_LIST_UPLOAD_FILE } from 'graphql/mutation/variable-gql';
import { GET_VARIABLE_LISTS } from 'graphql/query/variable-gql';

import { successNotification, errorNotification } from "components/request-result";



const useMutationVariableUploadFile = ({ variables }) => {

  const [ _setVariableUploadFile, { loading } ] = useMutation(VARIABLE_LIST_UPLOAD_FILE,
    {
      update(cache, { data }) {

        const {
          fileUploadModel: {
            label,
            message,
          }
        } = data;

        successNotification({
          title: label,
          description: message
        });
      },
      onError(error) {
        errorNotification(error);
      },

      refetchQueries: [
        {
          query: GET_VARIABLE_LISTS,
          variables
        },
      ]
    }
  );


  return {
    _setVariableUploadFile,
    loadingMutationVariableUploadFile: loading,
  }
};


export default useMutationVariableUploadFile;