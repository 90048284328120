import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { ORGANIZER_UPDATE, ORGANIZER_CREATE } from "graphql/mutation/organizer-gql";
import OrganizerConst from '../organizer-const';
import { successNotification, errorNotification } from "components/request-result";



const useMutationOrganizerCreateUpdate = (id, redirect = true) => {

  const history = useHistory();

  const ORGANIZER_CREATE_UPDATE = id ? ORGANIZER_UPDATE : ORGANIZER_CREATE;
  const organizerCreateUpdate = id ? 'organizerUpdate' : 'organizerCreate';

  const [ _setOrganizerCreateUpdate, { loading } ] = useMutation(ORGANIZER_CREATE_UPDATE,
    {
      update(cache, { data }) {

        const {
          [ organizerCreateUpdate ]: {
            label,
            message,
          }
        } = data;


        redirect && history.push(OrganizerConst.basePath);

        successNotification({
          title: label,
          description: message
        });
      },
      onError(error) {
        errorNotification(error);
      }
    }
  );


  return {
    _setOrganizerCreateUpdate,
    loadingMutationOrganizerCreateUpdate: loading,
  }
};

export default useMutationOrganizerCreateUpdate;

