import React from "react";
import { useQuery } from "@apollo/client";
import { GET_EXHIBITION } from "graphql/query/exhibition-gql";
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import ExhibitionRoute from "components/routes/exhibition/exhibition-route";
import { Localize } from "components/service";

const ExhibitionPage = ({ match, history }) => {
  useActiveMenuItem(["exhibition-types"], ["exhibition-types"]);

  const path = history.location.pathname.split("/").pop();
  const exhibitionID = match ? parseInt(match.params.id) : null;

  const { data: { exhibitionType } = {}, loading } = useQuery(GET_EXHIBITION, {
    skip: !exhibitionID,
    variables: {
      id: exhibitionID,
    },
  });

  const pageNavbar = [
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_Overview" }).props
        .children,
      path: "",
    },
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_StandTypes" }).props
        .children,
      path: "stand-types",
    },
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_Equipment" }).props
        .children,
      path: "equipment",
    },
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_Flooring" }).props
        .children,
      path: "flooring",
    },
  ];

  return (
    <PageWrapDefault
      className="page-exhibition"
      loading={loading}
      title={
        path === "create" ? (
          <Localize>PAGES.Title_CreateExhibition</Localize>
        ) : !loading && exhibitionType ? (
          exhibitionType?.title
        ) : (
          "Page 404"
        )
      }
      dataExist={path === "create" ? true : !loading && exhibitionType}
      pageNavbar={!loading && exhibitionType ? pageNavbar : false}
      staticPath={`/exhibition-types/${exhibitionID}`}
    >
      <ExhibitionRoute
        exhibition={exhibitionType}
        history={history}
        loading={loading}
      />
    </PageWrapDefault>
  );
};

export default ExhibitionPage;
