import React from "react";
import { Form, Input, InputNumber, Button } from "antd";

import { variationUseMutation } from "../../hooks";

import { GET_ORGANIZERS_CURSOR } from "graphql/query/organizer-gql";

import { Localize } from "components/service";
import { FormElements } from "components/layout";
import Icons from "components/icons";

const VariationEditForm = ({ groupID, variation, variables, action }) => {
  const [form] = Form.useForm();

  const { _setVariationCreateUpdate, loadingMutationVariationCreateUpdate } =
    variationUseMutation.createUpdate(variation?.id, variables);

  return (
    <Form
      key="edit-variation-form"
      layout="vertical"
      form={form}
      className="model-form edit-variation-form"
      onFinish={(values) => {
        _setVariationCreateUpdate({
          variables: {
            input: {
              id: variation?.id ? +variation.id : undefined,
              equipment_group: groupID,
              ...values,
              organizer_id: values.organizer_id !== "" ? values.organizer_id : undefined,
            },
          },
        });
        action();
        form.resetFields();
      }}
    >
      <div className="row-grid grid-gap-10 row-gap-0 col-md-2">
        <Form.Item
          name="title"
          label={<Localize>FORMS.Input_Label_NameArticle</Localize>}
          initialValue={variation?.title}
          rules={[
            {
              required: true,
              message: <Localize>FORM_RULES.Required_Name</Localize>,
            },
          ]}
        >
          <Input name="title" />
        </Form.Item>

        <Form.Item
          name="article"
          label={<Localize>FORMS.Input_Label_Article</Localize>}
          initialValue={variation?.article}
          rules={[
            {
              required: true,
              message: <Localize>FORM_RULES.Required_Article</Localize>,
            },
          ]}
        >
          <Input name="article" />
        </Form.Item>

        <FormElements.RadioSelect
          form={form}
          query={GET_ORGANIZERS_CURSOR}
          model="organizersCursor"
          name="organizer_id"
          label="FORMS.Input_Label_Organizer"
          modalTitle="FORMS.Title_AddOrganizer"
          location="edit-form"
          initialValue={
            variation?.organizer?.id
              ? [
                  {
                    value: variation.organizer.id,
                    label: variation.organizer.title,
                  },
                ]
              : false
          }
          // rules={[
          //   {
          //     required: true,
          //     message: <Localize>FORM_RULES.Required_Organizer</Localize>,
          //   },
          // ]}
        />

        <Form.Item
          name="price"
          label={
            <>
              <Localize>FORMS.Input_Label_Price</Localize> &#8364;
            </>
          }
          initialValue={variation?.price}
          rules={[
            {
              required: true,
              message: <Localize>FORM_RULES.Required_Price</Localize>,
            },
          ]}
        >
          <InputNumber name="price" style={{ width: "100%" }} />
        </Form.Item>
      </div>

      <div className="form-btn-holder">
        <Button className="light-bg" onClick={action}>
          <Localize>GLOBAL.Button_Text_Cancel</Localize>
        </Button>
        <Button
          className="btn-right"
          type="primary"
          htmlType="submit"
          icon={variation?.id ? <Icons.Edit /> : <Icons.Plus />}
          loading={loadingMutationVariationCreateUpdate}
        >
          <span className="ellipsis">
            {" "}
            {variation?.id ? (
              <Localize>GLOBAL.Button_Text_SaveChanges</Localize>
            ) : (
              <Localize>GLOBAL.Button_Text_CreateNew</Localize>
            )}
          </span>
        </Button>
      </div>
    </Form>
  );
};

export default VariationEditForm;
