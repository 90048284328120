import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import SubPage from "components/pages/graphic/sub-pages";

const GraphicRoute = ({ graphic, history }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/rules`} exact>
        <SubPage.Rules graphic={graphic} history={history} />
      </Route>
      <Route path={`${path}`}>
        <SubPage.OverView graphic={graphic} history={history} />
      </Route>
    </Switch>
  );
};

export default GraphicRoute;
