import React from "react";

import { equipmentUseMutation } from "../hooks";
import { EntityRemoveButton, Localize } from "components/service";
import Icons from "components/icons";

const EquipmentRemoveField = ({
  groupID,
  equipment,
  disabled = false,
  variables,
}) => {
  const { _setMutationEquipmentRemove, loadingMutationEquipmentRemove } =
    equipmentUseMutation.remove(variables);

  return (
    <EntityRemoveButton
      okText={
        Localize({ children: "GLOBAL.Button_Text_Remove" }).props.children
      }
      disabled={disabled}
      modalButton={
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            fontSize: "22px",
            color: !disabled
              ? "var(--table_colorIconRemove)"
              : "var(--table_colorIconRemoveDisabled)",
            cursor: !disabled ? "pointer" : "default",
          }}
        >
          <Icons.Close />
        </span>
      }
      nameEntity={
        Localize({ children: "ENTITY.Modal_Title_Equipment" }).props.children
      }
      dataNameEntity={equipment.title}
      loading={loadingMutationEquipmentRemove}
      deleteMutation={_setMutationEquipmentRemove}
      variables={{
        equipment_group: groupID,
        substitude_group: equipment.group_id,
      }}
    />
  );
};

export default EquipmentRemoveField;
