import React from "react";
import { Button, Select } from "antd";
import Variable from "components/variable";
import { Localize } from "components/service";

const SupplieriltersForm = ({
  form,
  action,
  setCount,
  supplierFilters,
  setFilters,
  setFilterList,
  filterList,
  gqlBuilderWhere,
}) => {
  const onChangeSetFilter = (e, name, value) => {
    if (e) {
      name = e.target.name;
      value = e.target.value;
    }

    if (value[0] === null) {
      setFilterList({
        ...filterList,
        [name]: {
          column: name,
          operator: "IS_NULL",
        },
      });
    } else {
      value.toString().length > 0
        ? setFilterList({
            ...filterList,
            [name]: {
              column: name,
              operator: "IN",
              value: Array.isArray(value) ? value : [value],
            },
          })
        : setFilterList({ ...filterList, [name]: {} });
    }
  };

  return (
    <div className="ant-form ant-form-vertical filters-form light-theme-form">
      <div>
        <Variable.Forms.Fields.VariablesSelect
          form={form}
          name="TYPE"
          groupName="supplier type"
          mode="multiple"
          initialValue={supplierFilters?.TYPE?.value}
          onChangeSetFilter={onChangeSetFilter}
          label={
            Localize({ children: "FORMS.Input_Label_Type" }).props.children
          }
          notAssignedOption={
            <Select.Option key="typeNotSet" value={null}>
              <Localize>FORMS.Select_OptionLabel_TypeNotSet</Localize>
            </Select.Option>
          }
        />
      </div>

      <div className="form-btn-holder">
        <Button
          className="light-bg"
          htmlType="submit"
          onClick={() => {
            setCount({});
            setFilterList({});
            setFilters(gqlBuilderWhere({}));
            localStorage.removeItem("supplierFilters");
            localStorage.removeItem("suppliersSorting");
          }}
        >
          <Localize>FORMS.Button_Text_ResetFilters</Localize>
        </Button>

        <Button
          className="btn-right"
          type="primary"
          htmlType="submit"
          onClick={() => {
            setFilters(gqlBuilderWhere({ ...supplierFilters, ...filterList }));
            setCount({
              ...supplierFilters,
              ...filterList,
            });
            action();
          }}
        >
          <Localize>FORMS.Button_Text_ApplyFilters</Localize>
        </Button>
      </div>
    </div>
  );
};

export default SupplieriltersForm;
