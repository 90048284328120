import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import SubPage from "components/pages/organizer/sub-pages";

const OrganizerRoute = ({ organizer, history }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/exhibition-types/create`} exact>
        <SubPage.ExhibitionCreate organizer={organizer} history={history} />
      </Route>
      <Route path={`${path}/equipment`} exact>
        <SubPage.Equipment organizer={organizer} history={history} />
      </Route>
      <Route path={`${path}/flooring`} exact>
        <SubPage.Flooring organizer={organizer} history={history} />
      </Route>
      <Route path={`${path}/exhibition-types`} exact>
        <SubPage.Exhibition organizer={organizer} history={history} />
      </Route>
      <Route path={`${path}/stand-types`} exact>
        <SubPage.Stand organizer={organizer} history={history} />
      </Route>
      <Route path={`${path}`}>
        <SubPage.OverView organizer={organizer} history={history} />
      </Route>
    </Switch>
  );
};

export default OrganizerRoute;
