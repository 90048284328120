import { useQuery } from "@apollo/client";

import { GET_ME } from "graphql/query/user-gql";


const useMe = ( fetchPolicy = "cache-and-network", withStatus = false ) => {

    const { data: { me } = {}, loading, error } = useQuery(GET_ME, {
        fetchPolicy,
        nextFetchPolicy: 'cache-first'
    } );

    if(withStatus) return { me, loading, error };

    if(me) return { me } ;

    return false;
}

export default useMe;