import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import {
  FLOORING_UPDATE,
  FLOORING_CREATE,
} from "graphql/mutation/flooring-gql";
import FlooringConst from "../flooring-const";
import {
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationFlooringCreateUpdate = (id) => {
  const history = useHistory();

  const FLOORING_CREATE_UPDATE = id ? FLOORING_UPDATE : FLOORING_CREATE;
  const flooringCreateUpdate = id ? "flooringUpdate" : "flooringCreate";

  const [_setFlooringCreateUpdate, { loading }] = useMutation(
    FLOORING_CREATE_UPDATE,
    {
      update(cache, { data }) {
        const {
          [flooringCreateUpdate]: { label, message, flooring },
        } = data;

        if (id) {
          history.push(FlooringConst.basePath);
        } else {
          history.push(`${FlooringConst.basePath}/${flooring?.id}`);
        }

        successNotification({
          title: label,
          description: message,
        });
      },
      onError(error) {
        errorNotification(error);
      },
    }
  );

  return {
    _setFlooringCreateUpdate,
    loadingMutationFlooringCreateUpdate: loading,
  };
};

export default useMutationFlooringCreateUpdate;
