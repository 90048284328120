import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_STAND_TYPES_GROUP } from "graphql/query/stand-gql";
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import Stand from "components/stand";
import StandTypeRoute from "components/routes/stand/stand-route";
import { Localize } from "components/service";

const StandPage = ({ match }) => {
  useActiveMenuItem(["stands"], ["stands"]);

  const history = useHistory();
  const basePath = Stand.Const.basePath;
  const groupID = match ? parseInt(match.params.group_id) : null;
  const { lang } = useParams();

  const containsCreate = (url) => url.includes("create");
  const conditionCreate = containsCreate(history.location.pathname);

  const { data: { standTypesGroup } = {}, loading } = useQuery(
    GET_STAND_TYPES_GROUP,
    {
      skip: !groupID,
      variables: {
        group_id: groupID,
      },
      fetchPolicy: "cache-and-network",
      // nextFetchPolicy: "cache-first",
    }
  );

  const standType = standTypesGroup?.filter((item) => item?.lang === lang)[0];

  const pageNavbar = [
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_Overview" }).props
        .children,
      path: "",
    },
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_Equipments" }).props
        .children,
      path: "equipments",
    },
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_Floorings" }).props
        .children,
      path: "floorings",
    },
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_Files" }).props.children,
      path: "files",
    },
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_Notes" }).props.children,
      path: "notes",
    },
  ];

  return (
    <PageWrapDefault
      className="page-stand"
      loading={loading}
      title={
        conditionCreate ? (
          <Localize>PAGES.Title_CreateStand</Localize>
        ) : !loading && standType ? (
          standType.title ? (
            standType.title
          ) : (
            <Localize>VARIABLE.Label_EMPTY</Localize>
          )
        ) : (
          "Page 404"
        )
      }
      dataExist={conditionCreate ? true : !loading && standType}
      pageNavbar={!loading && standType ? pageNavbar : false}
      staticPath={`${basePath}/edit/${groupID}/${lang}`}
    >
      <StandTypeRoute
        conditionCreate={conditionCreate}
        stand={standType}
        standTypesGroup={standTypesGroup}
        lang={lang}
        history={history}
        loading={loading}
      />
    </PageWrapDefault>
  );
};

export default StandPage;
