import React, { useEffect, useState, useRef } from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { Skeleton } from "antd";
import { Pages } from "components/pages";

import "./page-wrap-default.scss";

const PageWrapDefault = ({
  pageHeaderContent = false,
  pageNavbar = false,
  loading = false,
  headerRightBar = false,
  headerNavRightBar = false,
  dataExist = false,
  pageContentVisible = true,
  staticPath = null,
  title = "",
  className = "",
  tabsIndent = "",
  heightAuto = "",
  children,
}) => {
  const { path } = useRouteMatch();
  const [isScrolled, setIsScrolled] = useState(false);
  const [initialObservationCompleted, setInitialObservationCompleted] =
    useState(false);
  const containerRef = useRef(null);

  /*eslint-disable */
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "50px 0px",
      threshold: 0.1,
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        setIsScrolled(entry.isIntersecting);
        setInitialObservationCompleted(true);
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);
  /*eslint-enable */

  return (
    <div
      className={`page ${className} ${
        initialObservationCompleted && !isScrolled ? "sticky" : ""
      }`}
    >
      <div className="page-header" ref={containerRef}>
        <div className="page-header-top-bar">
          <h1>{!loading ? title : <Skeleton title />}</h1>
          {headerRightBar && <div className="right-bar">{headerRightBar}</div>}
        </div>

        {pageHeaderContent && (
          <div className="page-header-content">{pageHeaderContent}</div>
        )}

        {pageNavbar.length && (
          <nav className="page-navbar">
            <ul className="page-links">
              {pageNavbar.map((item, index) => (
                <li key={index}>
                  <NavLink
                    exact
                    to={`${staticPath ?? path}/${item.path}`}
                    activeClassName="active"
                  >
                    {item?.label}
                  </NavLink>
                </li>
              ))}
            </ul>
            {headerNavRightBar && (
              <div className="right-bar">{headerNavRightBar}</div>
            )}
          </nav>
        )}
      </div>

      {pageContentVisible && (
        <div
          className={`page-content ${tabsIndent && "tabs-indent"} ${
            heightAuto && "height-auto"
          }`}
        >
          {dataExist ? children : !loading && <Pages.Page404 />}
          {loading && <Skeleton active paragraph={{ rows: 15 }} />}
        </div>
      )}
    </div>
  );
};

export default PageWrapDefault;
