import React from "react";
import { Link } from "react-router-dom";
import SupplierConst from "../supplier-const";

const SupplierInfoField = ({ supplier }) => (
  <Link
    className="table-link-underline"
    to={`${SupplierConst.basePath}/${supplier?.id}`}
  >
    {`${supplier?.title}`}
  </Link>
);

export default SupplierInfoField;
