import User from "components/user";
import { useBreadCrumbs } from "components/use-hooks";
import { Localize } from "components/service";

const userBasePath = User.Const.basePath;

const UserOverviewSubPage = ({ user, history }) => {
  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_Users" }).props.children,
      path: `${userBasePath}`,
    },
    {
      label:
        (user && `${user?.name} ${user?.surname}`) ||
        Localize({ children: "BREADCRUMBS.Label_Create" }).props.children,
      path: (user && `${userBasePath}/${user?.id}`) || `${userBasePath}/create`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  return (
    <div className="row-grid col-lg-2">
      <div className="col-left">
        <User.Forms.Edit user={user} history={history} />
      </div>
      <div className="col-right">
        {user?.id && <User.Actions.List userID={user?.id} />}
      </div>
    </div>
  );
};

export default UserOverviewSubPage;
