import React from "react";
import VariationForms from "../forms";
import { Localize, ModalStandard } from "components/service";

const VariationInfoField = ({ variation, variables }) => (
  <ModalStandard
    width={500}
    extraClass={"modal-form"}
    modalButton={
      <strong style={{ cursor: "pointer" }}>{variation?.title}</strong>
    }
  >
    <strong className="modal-title">
      <span className="text">
        <Localize>FORMS.Title_EditVariation</Localize>
      </span>
    </strong>

    <VariationForms.Edit
      groupID={variation?.equipment_group}
      variation={variation}
      variables={variables}
    />
  </ModalStandard>
);

export default VariationInfoField;
