import React from "react";
import { Button, Form, Select } from "antd";
import { Localize } from "components/service";
import { GET_SUPPLIER_CURSOR } from "graphql/query/supplier-gql";
import { GET_ROLES_CURSOR } from "graphql/query/role-gql";
import { GET_TEAMS_CURSOR } from "graphql/query/team-gql";
import { FormElements } from "components/layout";
import Icons from "components/icons";

const UserFiltersForm = ({
  form,
  action,
  setCount,
  usersFilters,
  usersFiltersExtra,
  usersFiltersMore,
  setFilters,
  setFiltersExtra,
  setFiltersMore,
  setFilterList,
  setFilterListExtra,
  setFilterListMore,
  filterList,
  filterListExtra,
  filterListMore,
  gqlBuilderWhere,
}) => {
  const onChangeSetFilter = (e, name, value) => {
    if (e) {
      name = e.target.name;
      value = e.target.value;
    }

    if (name === "ID") {
      value.toString().length > 0
        ? setFilterListExtra({
            ...filterListExtra,
            [name]: {
              column: name,
              operator: "IN",
              value: Array.isArray(value) ? value : [value],
            },
          })
        : setFilterListExtra({ ...filterListExtra, [name]: {} });
    } else if (name === "ID_TEAM") {
      value.toString().length > 0
        ? setFilterListMore({
            ...filterListMore,
            [name]: {
              column: name.substring(0, 2),
              operator: "IN",
              value: Array.isArray(value) ? value : [value],
            },
          })
        : setFilterListMore({ ...filterListMore, [name]: {} });
    } else {
      value.toString().length > 0
        ? setFilterList({
            ...filterList,
            [name]: {
              column: name,
              operator: "IN",
              value: Array.isArray(value) ? value : [value],
            },
          })
        : setFilterList({ ...filterList, [name]: {} });
    }
  };

  return (
    <div className="ant-form ant-form-vertical filters-form">
      <div>
        <FormElements.RadioSelect
          skipQuery={!usersFilters}
          location="filter"
          form={form}
          query={GET_SUPPLIER_CURSOR}
          model="suppliersCursor"
          name="SUPPLIER_ID"
          label="FORMS.Input_Label_Supplier"
          modalTitle="FORMS.Title_SelectSupplier"
          initialValue={
            usersFilters?.SUPPLIER_ID?.value
              ? [{ value: usersFilters?.SUPPLIER_ID?.value }]
              : false
          }
          onChangeSetFilter={onChangeSetFilter}
        />

        <FormElements.CheckboxSelect
          skipQuery={!usersFiltersExtra}
          location="filter"
          query={GET_ROLES_CURSOR}
          form={form}
          model="rolesCursor"
          name="ID"
          label="FORMS.Input_Label_Role"
          modalTitle="FORMS.Title_AddRoles"
          modalButtonText="MODAL.Button_Text_AddRole"
          extraClassName="light-theme"
          disableSearch={true}
          extraClass="searh-border-bottom-none"
          initialValue={
            usersFiltersExtra?.ID?.value
              ? usersFiltersExtra?.ID?.value?.map((item) => ({ value: item }))
              : false
          }
          onChangeSetFilter={onChangeSetFilter}
        />

        <FormElements.CheckboxSelect
          skipQuery={!usersFiltersMore}
          location="filter"
          form={form}
          query={GET_TEAMS_CURSOR}
          model="teamsCursor"
          name="ID_TEAM"
          label="FORMS.Input_Label_Team"
          modalTitle="FORMS.Title_AddTeams"
          modalButtonText="MODAL.Button_Text_AddTeam"
          extraClassName="light-theme"
          initialValue={
            usersFiltersMore?.ID_TEAM?.value
              ? usersFiltersMore?.ID_TEAM?.value.map((item) => ({
                  value: item,
                }))
              : false
          }
          onChangeSetFilter={onChangeSetFilter}
        />

        <Form.Item
          name="STATUS"
          label={<Localize>FORMS.Input_Label_Status</Localize>}
          initialValue={usersFilters?.STATUS?.value}
        >
          <Select
            showSearch
            suffixIcon={<Icons.Arrow />}
            popupClassName="select-serch-dropdown"
            optionFilterProp="value"
            placeholder={
              <Localize>FORMS.Input_Placeholder_PleaseChose</Localize>
            }
            onChange={(value) => {
              form.setFieldsValue({ STATUS: value });
              onChangeSetFilter(false, "STATUS", value);
            }}
          >
            <Select.Option key="active" value="active">
              <Localize>FORMS.Select_OptionLabel_Active</Localize>
            </Select.Option>
            <Select.Option key="block" value="block">
              <Localize>FORMS.Select_OptionLabel_Blocked</Localize>
            </Select.Option>
          </Select>
        </Form.Item>
      </div>

      <div className="form-btn-holder">
        <Button
          className="light-bg"
          htmlType="submit"
          onClick={() => {
            setCount({});
            setFilterList({});
            setFilterListExtra({});
            setFilterListMore({});
            setFilters(gqlBuilderWhere({}));
            setFiltersExtra(gqlBuilderWhere({}));
            setFiltersMore(gqlBuilderWhere({}));
            localStorage.removeItem("usersFilters");
            localStorage.removeItem("usersSorting");
            localStorage.removeItem("usersFiltersExtra");
            localStorage.removeItem("usersFiltersMore");
          }}
        >
          <Localize>FORMS.Button_Text_ResetFilters</Localize>
        </Button>

        <Button
          className="btn-right"
          type="primary"
          htmlType="submit"
          onClick={() => {
            setFilters(gqlBuilderWhere({ ...usersFilters, ...filterList }));
            setFiltersExtra(
              gqlBuilderWhere({ ...usersFiltersExtra, ...filterListExtra })
            );
            setFiltersMore(
              gqlBuilderWhere({ ...usersFiltersMore, ...filterListMore })
            );
            setCount({
              ...usersFilters,
              ...filterList,
              ...usersFiltersExtra,
              ...filterListExtra,
              ...usersFiltersMore,
              ...filterListMore,
            });
            action();
          }}
        >
          <Localize>FORMS.Button_Text_ApplyFilters</Localize>
        </Button>
      </div>
    </div>
  );
};

export default UserFiltersForm;
