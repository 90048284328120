import { useMutation } from "@apollo/client";
import { STAND_TYPE_FILE_DELETE } from "graphql/mutation/stand-gql";
import {
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationStandFileDelete = ({ id, documentCreate }) => {
  const [_setMutationStandFileDelete, { loading }] = useMutation(
    STAND_TYPE_FILE_DELETE,
    {
      update(cache, { data }) {
        if (!documentCreate) {
          const {
            standTypeFileDelete: { label, message },
          } = data;

          successNotification({
            title: label.toUpperCase(),
            description: message,
          });
        }

        cache.evict({
          id: cache.identify({ id, __typename: "StandTypeFile" }),
        });
        cache.gc();
      },
      onError(error) {
        errorNotification(error);
      },
    }
  );

  return {
    _setMutationStandFileDelete,
    loadingMutationStandFileDelete: loading,
  };
};

export default useMutationStandFileDelete;
