import React, { useState } from "react";
import { Drawer, ConfigProvider } from "antd";
import customizeTheme from "components/customize-theme";
import Icons from "components/icons";

const DrawerStandart = ({
  modalButton,
  extraClass = "",
  disabled = false,
  width = 450,
  onCancel = () => {},
  ...props
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      {React.cloneElement(modalButton, {
        disabled,
        onClick: () => setShowModal(true),
      })}
      <Drawer
        className={`drawer-standard ${extraClass}`}
        open={showModal}
        width={width}
        placement="right"
        closeIcon={<Icons.Close />}
        onClose={() => setShowModal(false)}
        header={null}
        footer={null}
        onCancel={() => {
          setShowModal(false);
          onCancel();
        }}
      >
        <ConfigProvider theme={customizeTheme.LightTheme()}>
          {Array.isArray(props.children)
            ? props.children.map((child, index) =>
                React.cloneElement(child, {
                  key: index,
                  action: () => setShowModal(false),
                  showModal,
                })
              )
            : React.cloneElement(props.children, {
                action: () => setShowModal(false),
              })}
        </ConfigProvider>
      </Drawer>
    </>
  );
};

export default DrawerStandart;
