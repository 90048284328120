import React from "react";
import { Link } from "react-router-dom";
import Team from "components/team";
import { Localize } from "components/service";

const UserTeamField = ({ team }) => {
  const uniqueTitles = {};

  const uniqueObjects = team.filter((obj) => {
    const title = obj.title;
    return uniqueTitles.hasOwnProperty(title)
      ? false
      : (uniqueTitles[title] = true);
  });

  return !!uniqueObjects.length ? (
    uniqueObjects.map((uniqueObjects, index) => {
      return (
        <div key={index} style={{ marginBottom: "3px" }}>
          <Link
            className="table-link-underline"
            to={`${Team.Const.basePath}/${uniqueObjects?.id}`}
          >
            <span style={{ fontWeight: 400 }}>
              <Localize>{uniqueObjects?.title}</Localize>
            </span>
          </Link>
          <br />
        </div>
      );
    })
  ) : (
    <strong>&mdash;</strong>
  );
};

export default UserTeamField;
