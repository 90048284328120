import React, { useEffect } from "react";
import { Form, Input, Button } from "antd";
import { GET_SUPPLIER_CURSOR } from "graphql/query/supplier-gql";
import { useApolloClient } from "@apollo/client";

import { GET_ME } from "graphql/query/user-gql";

import { equipmentUseMutation } from "../../hooks";

import { Localize, EntityRemoveButton } from "components/service";
import { FormElements } from "components/layout";
import Variable from "components/variable";
import Icons from "components/icons";

const EquipmentEditForm = ({
  equipment,
  variables,
  conditionCreate,
  // disabledField,
  activeLang,
}) => {
  const [form] = Form.useForm();

  const client = useApolloClient();
  const cachedMe = client.readQuery({
    query: GET_ME,
  });

  const roles = cachedMe.me.roles;
  const disabledColor = roles.filter(
    ({ slug }) => slug === "role_library_manager"
  ).length;

  const { _setEquipmentCreateUpdate, loadingMutationEquipmentCreateUpdate } =
    equipmentUseMutation.createUpdate(equipment?.group_id);

  const { _setMutationEquipmentDelete, loadingMutationEquipmentDelete } =
    equipmentUseMutation.delete(equipment?.group_id, variables);

  useEffect(() => {
    form.setFieldsValue({
      title: !conditionCreate ? equipment?.title : "",
      description: !conditionCreate ? equipment?.description : "",
    });
  }, [equipment, conditionCreate, form]);

  const valueFieldSupplier = Form.useWatch("supplier_id", form);

  // const fieldDisabled = conditionCreate ? !conditionCreate : disabledField;

  return (
    <Form
      key="edit-equipment-form"
      layout="vertical"
      form={form}
      className="model-form edit-equipment-form"
      onFinish={(values) => {
        _setEquipmentCreateUpdate({
          variables: {
            input: {
              id: equipment?.id ? +equipment.id : undefined,
              group_id: equipment?.group_id ? +equipment.group_id : undefined,
              lang: activeLang,
              ...values,
              supplier_id: values.supplier_id ? values.supplier_id : undefined,
            },
          },
        });
      }}
    >
      <Form.Item
        name="title"
        label={<Localize>FORMS.Input_Label_NameArticle</Localize>}
        rules={[
          {
            required: true,
            message: <Localize>FORM_RULES.Required_ArticleEquipment</Localize>,
          },
        ]}
      >
        <Input checked={equipment?.title} name="title" />
      </Form.Item>
      <div className="row-grid grid-gap-10 row-gap-0 col-md-2">
        <Variable.Forms.Fields.VariablesSelect
          form={form}
          name="type"
          groupName="equipment type"
          rules={ true }
          requiredText={Localize({ children: "FORM_RULES.Required_ChooseType" }).props.children}
          /*disabled={fieldDisabled}*/
          initialValue={equipment?.type}
          label={
            Localize({ children: "FORMS.Input_Label_Type" }).props.children
          }
        />

        <Variable.Forms.Fields.VariablesSelect
          form={form}
          name="color"
          groupName="equipment color"
          rules={ true }
          requiredText={Localize({ children: "FORM_RULES.Required_ChooseColor" }).props.children}
          disabledColor={!disabledColor}
          initialValue={equipment?.color}
          label={
            Localize({ children: "FORMS.Input_Label_BaseColor" }).props.children
          }
        />

        <Form.Item
          name="navision_number"
          label={<Localize>FORMS.Input_Label_NavisionNumb</Localize>}
          initialValue={equipment?.navision_number}
          rules={[
            {
              required: true,
              message: <Localize>FORM_RULES.Required_NavisionArticle</Localize>,
            },
          ]}
        >
          <Input name="navision_number" /*disabled={fieldDisabled}*/ />
        </Form.Item>

        <Form.Item
          name="octacad_number"
          label={<Localize>FORMS.Input_Label_OctacadArticle</Localize>}
          initialValue={equipment?.octacad_number}
          rules={[
            {
              required: true,
              message: <Localize>FORM_RULES.Required_OctacadNumb</Localize>,
            },
          ]}
        >
          <Input name="octacad_number" /*disabled={fieldDisabled}*/ />
        </Form.Item>

        <FormElements.RadioSelect
          form={form}
          query={GET_SUPPLIER_CURSOR}
          model="suppliersCursor"
          name="supplier_id"
          label="FORMS.Input_Label_Supplier"
          modalTitle="FORMS.Title_AddSupplier"
          /*disabled={fieldDisabled}*/
          initialValue={
            equipment?.supplier?.id
              ? [
                  {
                    value: equipment.supplier.id,
                    label: equipment.supplier.title,
                  },
                ]
              : false
          }
        />
        {valueFieldSupplier && (
          <Form.Item
            name="supplier_article"
            label={<Localize>FORMS.Input_Label_SupplierArticle</Localize>}
            initialValue={equipment?.supplier_article}
            rules={[
              {
                required: true,
                message: (
                  <Localize>FORM_RULES.Required_SupplierArticle</Localize>
                ),
              },
            ]}
          >
            <Input name="supplier_article" /*disabled={fieldDisabled}*/ />
          </Form.Item>
        )}
      </div>

      <Form.Item
        name="description"
        label={<Localize>FORMS.Input_Label_Description</Localize>}
        initialValue={equipment?.description}
      >
        <Input.TextArea
          /*maxLength={300} */ autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </Form.Item>

      <div className="form-btn-holder">
        {!conditionCreate && (
          <EntityRemoveButton
            modalButton={
              <Button icon={<Icons.Delete />}>
                <span className="ellipsis">
                  <Localize>GLOBAL.Button_Text_DeleteItem</Localize>
                </span>
              </Button>
            }
            nameEntity="Equipment"
            dataNameEntity={`${equipment?.title}`}
            loading={loadingMutationEquipmentDelete}
            deleteMutation={_setMutationEquipmentDelete}
            variables={{
              group_id: equipment?.group_id,
            }}
          />
        )}
        <Button
          className="btn-right"
          type="primary"
          htmlType="submit"
          icon={!conditionCreate ? <Icons.Edit /> : <Icons.Plus />}
          loading={loadingMutationEquipmentCreateUpdate}
        >
          <span className="ellipsis">
            {conditionCreate ? (
              <Localize>GLOBAL.Button_Text_Create</Localize>
            ) : equipment?.title ? (
              <Localize>GLOBAL.Button_Text_SaveChanges</Localize>
            ) : (
              <Localize>GLOBAL.Button_Text_AddTranslation</Localize>
            )}
          </span>
        </Button>
      </div>
    </Form>
  );
};

export default EquipmentEditForm;
