import React from "react";
import { Button } from "antd";
import Variable from "components/variable";
import { Localize } from "components/service";
import { GET_SUPPLIER_CURSOR } from "graphql/query/supplier-gql";
import { FormElements } from "components/layout";

const EquipmentFiltersForm = ({
  equipment,
  form,
  action,
  setCount,
  equipmentFilters,
  setFilters,
  setFilterList,
  filterList,
  gqlBuilderWhere,
}) => {
  const onChangeSetFilter = (e, name, value) => {
    if (e) {
      name = e.target.name;
      value = e.target.value;
    }

    value.toString().length > 0
      ? setFilterList({
          ...filterList,
          [name]: {
            column: name,
            operator: "IN",
            value: Array.isArray(value) ? value : [value],
          },
        })
      : setFilterList({ ...filterList, [name]: {} });
  };

  return (
    <div className="ant-form ant-form-vertical filters-form">
      <div>
        <Variable.Forms.Fields.VariablesSelect
          form={form}
          name="TYPE"
          groupName="equipment type"
          initialValue={equipmentFilters?.TYPE?.value}
          label={
            Localize({ children: "FORMS.Input_Label_Type" }).props.children
          }
          onChangeSetFilter={onChangeSetFilter}
        />

        <Variable.Forms.Fields.VariablesSelect
          form={form}
          name="COLOR"
          groupName="equipment color"
          initialValue={equipmentFilters?.COLOR?.value}
          label={
            Localize({ children: "FORMS.Input_Label_Color" }).props.children
          }
          onChangeSetFilter={onChangeSetFilter}
        />

        <FormElements.RadioSelect
          skipQuery={!equipmentFilters}
          location="filter"
          form={form}
          query={GET_SUPPLIER_CURSOR}
          model="suppliersCursor"
          name="SUPPLIER_ID"
          label="FORMS.Input_Label_Supplier"
          modalTitle="FORMS.Title_SelectSupplier"
          initialValue={
            equipmentFilters?.SUPPLIER_ID?.value
              ? [{ value: equipmentFilters?.SUPPLIER_ID?.value }]
              : false
          }
          onChangeSetFilter={onChangeSetFilter}
        />
      </div>

      <div className="form-btn-holder">
        <Button
          className="light-bg"
          htmlType="submit"
          onClick={() => {
            setCount({});
            setFilterList({});
            setFilters(gqlBuilderWhere({}));
            localStorage.removeItem(
              `equipmentFilters_groupID-${equipment?.group_id|| 0}`
            );
            localStorage.removeItem(
              `equipmentsSorting_groupID-${equipment?.group_id|| 0}`
            );
          }}
        >
          <Localize>FORMS.Button_Text_ResetFilters</Localize>
        </Button>

        <Button
          className="btn-right"
          type="primary"
          htmlType="submit"
          onClick={() => {
            setFilters(gqlBuilderWhere({ ...equipmentFilters, ...filterList }));
            setCount({
              ...equipmentFilters,
              ...filterList,
            });
            action();
          }}
        >
          <Localize>FORMS.Button_Text_ApplyFilters</Localize>
        </Button>
      </div>
    </div>
  );
};

export default EquipmentFiltersForm;
