import React, { useState } from "react";
import { Button } from "antd";
import { useHistory } from "react-router-dom";

import { useApolloClient } from "@apollo/client";

import { GET_ME } from "graphql/query/user-gql";
import { GET_FLOORING_CURSOR } from "graphql/query/flooring-gql";

import Flooring from "components/flooring";
import Icons from "components/icons";
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import { Localize } from "components/service";
import { gqlBuilderWhere } from "utils";

import "./flooring.scss";

const basePath = Flooring.Const.basePath;
const tableHelper = Flooring.Helpers.TableMain;

const FlooringsPage = () => {
  useActiveMenuItem([ "flooring" ], [ "flooring" ]);

  const client = useApolloClient();
  const cachedMe = client.readQuery({
    query: GET_ME,
  });


  const disabledColor = cachedMe.me.roles.filter(
    ({ slug }) => slug === "role_library_manager"
  ).length ? true : false;

  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_Flooring" }).props
        .children,
      path: `${basePath}`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const history = useHistory();

  const objOrderBy = [{ column: "ID", order: "DESC" }];

  const [searchText, setSearchText] = useState("");
  const [viewMode, setViewMode] = useState("card");
  const [filters, setFilters] = useState();
  const [resetSorting, setResetSorting] = useState(false);

  const flooringFilters = JSON.parse(localStorage.getItem("flooringFilters"));
  const flooringsSorting = JSON.parse(localStorage.getItem("flooringsSorting"));

  const objectFilters = filters || gqlBuilderWhere(flooringFilters);

  return (
    <PageWrapDefault
      className="page-floorings"
      dataExist={true}
      title={<Localize>PAGES.Title_Flooring</Localize>}
      staticPath=""
    >
      <Tables.Cursor
        model="flooringsCursor"
        query={GET_FLOORING_CURSOR}
        varSet={ { perPage: 50 } }
        extraObj={ disabledColor }
        routeUrl={`${basePath}`}
        searchText={searchText}
        tableHelper={tableHelper}
        viewMode={viewMode}
        viewGrid={Flooring.Helpers.GridMain}
        objectWhere={objectFilters}
        objOrderBy={flooringsSorting || objOrderBy}
        sortingName="flooringsSorting"
        resetSorting={resetSorting}
      >
        <div className="table-action-bar">
          <div className="col">
            <Flooring.Filters.Table
              {...{
                setSearchText,
                filters,
                setFilters,
                flooringFilters,
                setResetSorting,
                objOrderBy,
              }}
            />
          </div>

          <div className="col">
            <div className="switch-btn-holder">
              {viewMode === "table" ? (
                <Button
                  onClick={() => setViewMode("card")}
                  icon={<Icons.Grid />}
                />
              ) : (
                <Button
                  onClick={() => setViewMode("table")}
                  icon={<Icons.Table />}
                />
              )}
            </div>

            <Button
              type="primary"
              icon={<Icons.Plus />}
              onClick={() => history.push(`${basePath}/create`)}
            >
              <span className="ellipsis">
                <Localize>GLOBAL.Button_Text_CreateNew</Localize>
              </span>
            </Button>
          </div>
        </div>
      </Tables.Cursor>
    </PageWrapDefault>
  );
};

export default FlooringsPage;
