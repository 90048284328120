import React from "react";
import { useQuery } from "@apollo/client";
import { GET_GRAPHIC_SUPPLIER } from "graphql/query/graphic-gql";
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import GraphicRoute from "components/routes/graphic/graphic-route";
import { Localize } from "components/service";

const GraphicPage = ({ match, history }) => {
  useActiveMenuItem(["variable-lists"], ["variable-lists"]);

  const path = history.location.pathname.split("/").pop();

  const graphicID = match ? parseInt(match.params.id) : null;

  const { data: { graphicSupplier } = {}, loading } = useQuery(
    GET_GRAPHIC_SUPPLIER,
    {
      skip: !graphicID,
      variables: {
        id: graphicID,
      },
    }
  );

  const pageNavbar = [
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_Overview" }).props
        .children,
      path: "",
    },
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_Rules" }).props.children,
      path: "rules",
    },
  ];

  return (
    <PageWrapDefault
      className="page-graphic"
      loading={loading}
      title={
        path === "create" ? (
          <Localize>PAGES.Title_CreateGraphicRules</Localize>
        ) : !loading && graphicSupplier ? (
          graphicSupplier.title
        ) : (
          "Page 404"
        )
      }
      dataExist={path === "create" ? true : !loading && graphicSupplier}
      pageNavbar={
        !loading &&
        graphicSupplier &&
        !history.location.pathname.includes("exhibition-types/create")
          ? pageNavbar
          : false
      }
      staticPath={`/graphic-rules/${graphicID}`}
    >
      <GraphicRoute
        graphic={graphicSupplier}
        history={history}
        loading={loading}
      />
    </PageWrapDefault>
  );
};

export default GraphicPage;
