import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { STAND_TYPE_DELETE } from "graphql/mutation/stand-gql";
import { GET_STAND_TYPES_CURSOR } from "graphql/query/stand-gql";
import StandConst from "../stand-const";
import {
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationStandDelete = (id, variables) => {
  const history = useHistory();

  const [_setMutationStandDelete, { loading }] = useMutation(
    STAND_TYPE_DELETE,
    {
      update(cache, { data }) {
        const {
          standTypeDelete: { label, message },
        } = data;

        history.push(StandConst.basePath);

        cache.evict({
          id: cache.identify({ id, __typename: "StandType" }),
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message,
        });
      },
      onError(error) {
        errorNotification(error);
      },
      refetchQueries: [
        {
          query: GET_STAND_TYPES_CURSOR,
          variables,
        },
      ],
    }
  );

  return {
    _setMutationStandDelete,
    loadingMutationStandDelete: loading,
  };
};

export default useMutationStandDelete;
