import { useEffect } from "react";
import { breadCrumbsVar } from "graphql/cache";

const useBreadCrumbs = (breadcrumbs) => {
  useEffect(() => {
    breadCrumbsVar(breadcrumbs);
    return () => breadCrumbsVar([]);
  }, [breadcrumbs]);
};

export default useBreadCrumbs;
