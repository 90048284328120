import Exhibition from "components/exhibition";
import { useBreadCrumbs } from "components/use-hooks";
import { Localize } from "components/service";

const exhibitionBasePath = Exhibition.Const.basePath;

const ExhibitionOverviewSubPage = ({ exhibition, history }) => {
  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_Exhibitions" }).props
        .children,
      path: `${exhibitionBasePath}`,
    },
    {
      label:
        (exhibition && exhibition?.title) ||
        Localize({ children: "BREADCRUMBS.Label_Create" }).props.children,
      path:
        (exhibition && `${exhibitionBasePath}/${exhibition?.id}`) ||
        `${exhibitionBasePath}/create`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  return (
    <div className="row-grid col-lg-2">
      <div className="col-left">
        <Exhibition.Forms.Edit exhibition={exhibition} history={history} />
      </div>
      <div className="col-right"></div>
    </div>
  );
};

export default ExhibitionOverviewSubPage;
