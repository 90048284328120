import React from "react";
import Equipment from "components/equipment";
import { DrawerStandart, Localize } from "components/service";

const StandEquipmentInfoField = ({
  stand,
  standEquipment,
  equipment,
  lang,
}) => {
  return (
    <DrawerStandart
      extraClass={"modal-form filters"}
      modalButton={
        <strong style={{ cursor: "pointer" }}>{equipment?.title}</strong>
      }
    >
      <strong className="modal-title">
        <span className="text">
          <Localize>FORMS.Title_Text_EditEquipmentRule</Localize>
        </span>
      </strong>

      <Equipment.Forms.AddRule
        stand={stand}
        standEquipment={standEquipment}
        equipment={equipment}
        lang={lang}
      />
    </DrawerStandart>
  );
};

export default StandEquipmentInfoField;
