import React, { useState } from "react";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { useApolloClient } from "@apollo/client";

import { GET_ME } from "graphql/query/user-gql";
import { GET_STAND_TYPES_CURSOR } from "graphql/query/stand-gql";

import Stand from "components/stand";
import Icons from "components/icons";
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import { Localize } from "components/service";
import { gqlBuilderWhere } from "utils";

import "./stand.scss";

const basePath = Stand.Const.basePath;
const tableHelper = Stand.Helpers.TableMain;

const StandsPage = (stand) => {
  useActiveMenuItem(["stands"], ["stands"]);

  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_StandTypes" }).props
        .children,
      path: `${basePath}`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const history = useHistory();

  const client = useApolloClient();
  const cachedMe = client.readQuery({
    query: GET_ME,
  });

  const defaultLang = cachedMe.me.lang_api;

  const disabledColor = cachedMe.me.roles.filter(
    ({ slug }) => slug === "role_library_manager"
  ).length
    ? true
    : false;

  const objOrderBy = [{ column: "ID", order: "DESC" }];

  const [searchText, setSearchText] = useState("");
  const [viewMode, setViewMode] = useState("card");
  const [filters, setFilters] = useState();
  const [filtersExtra, setFiltersExtra] = useState();
  const [filtersMore, setFiltersMore] = useState();
  const [resetSorting, setResetSorting] = useState(false);

  const standFilters = JSON.parse(
    localStorage.getItem(`standFilters_groupID-${stand?.group_id || 0}`)
  );
  const standFiltersExtra = JSON.parse(
    localStorage.getItem(`standFiltersExtra_groupID-${stand?.group_id || 0}`)
  );
  const standFiltersMore = JSON.parse(
    localStorage.getItem(`standFiltersMore_groupID-${stand?.group_id || 0}`)
  );
  const standsSorting = JSON.parse(
    localStorage.getItem(`standsSorting_groupID-${stand?.group_id || 0}`)
  );

  const objectFilters = filters || gqlBuilderWhere(standFilters);
  const objectFilterExtra = filtersExtra || gqlBuilderWhere(standFiltersExtra);
  const objectFiterMore = filtersMore || gqlBuilderWhere(standFiltersMore);

  return (
    <PageWrapDefault
      className="page-stands"
      dataExist={true}
      title={<Localize>PAGES.Title_StandTypes</Localize>}
      staticPath=""
    >
      <Tables.Cursor
        model="standTypesCursor"
        query={GET_STAND_TYPES_CURSOR}
        varSet={{
          perPage: 30,
          whereOrientations: objectFilterExtra,
          whereExhibitionTypeStandTypes: objectFiterMore,
        }}
        extraObj={disabledColor}
        routeUrl={`${basePath}`}
        language={defaultLang}
        searchText={searchText}
        tableHelper={tableHelper}
        viewMode={viewMode}
        viewGrid={Stand.Helpers.GridMain}
        objOrderBy={standsSorting || objOrderBy}
        sortingName={`standsSorting_groupID-${stand?.group_id || 0}`}
        resetSorting={resetSorting}
        resetPaginationPage={
          typeof objectFilters === "object" ||
          typeof objectFilterExtra === "object" ||
          typeof objectFiterMore === "object"
        }
        objectWhere={{
          ...objectFilters,
          AND: [
            {
              column: "LANG",
              value: defaultLang,
            },
          ],
        }}
      >
        <div className="table-action-bar">
          <div className="col">
            <Stand.Filters.Table
              {...{
                stand,
                defaultLang,
                setSearchText,
                filters,
                filtersExtra,
                filtersMore,
                setFilters,
                setFiltersExtra,
                setFiltersMore,
                standFilters,
                standFiltersExtra,
                standFiltersMore,
                setResetSorting,
                objOrderBy,
              }}
            />
          </div>

          <div className="col">
            <div className="switch-btn-holder">
              {viewMode === "table" ? (
                <Button
                  onClick={() => setViewMode("card")}
                  icon={<Icons.Grid />}
                />
              ) : (
                <Button
                  onClick={() => setViewMode("table")}
                  icon={<Icons.Table />}
                />
              )}
            </div>

            <Button
              type="primary"
              icon={<Icons.Plus />}
              onClick={() => history.push(`${basePath}/create/${defaultLang}`)}
            >
              <span className="ellipsis">
                <Localize>GLOBAL.Button_Text_CreateNew</Localize>
              </span>
            </Button>
          </div>
        </div>
      </Tables.Cursor>
    </PageWrapDefault>
  );
};

export default StandsPage;
