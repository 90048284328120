import React from "react";
import { Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { standUseMutation } from "../hooks";
import StandForms from "../forms/index";
import {
  EntityRemoveButton,
  Localize,
  ModalStandard,
} from "components/service";
import Icons from "components/icons";

const StandFileActionField = ({ stand, standFile, variables }) => {
  const { _setMutationStandFileDelete, loadingMutationStandFileDelete } =
    standUseMutation.fileDelete({
      id: standFile?.id,
    });

  const items = [
    {
      key: "1",
      icon: (
        <ModalStandard
          extraClass={"modal-form filters"}
          modalButton={
            <span style={{ display: "flex", alignItems: "center", width: 100 }}>
              <Icons.Edit /> <Localize>MENUS_DROP.Label_Edit</Localize>
            </span>
          }
        >
          <strong className="modal-title">
            <span className="text">
              <Localize>FORMS.Title_Text_EditFile</Localize>
            </span>
          </strong>

          <StandForms.EditFile
            stand={stand}
            standFile={standFile}
            variables={variables}
          />
        </ModalStandard>
      ),
    },
    {
      key: "2",
      icon: (
        <EntityRemoveButton
          modalButton={
            <span style={{ display: "flex", alignItems: "center", width: 100 }}>
              <Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize>
            </span>
          }
          nameEntity={
            Localize({ children: "ENTITY.Modal_Title_File" }).props.children
          }
          dataNameEntity={standFile?.original_name + "." + standFile?.extension}
          loading={loadingMutationStandFileDelete}
          deleteMutation={_setMutationStandFileDelete}
          variables={{
            id: standFile?.id,
          }}
        />
      ),
    },
  ];

  return (
    <Dropdown
      // trigger={['click']}
      placement="bottomRight"
      menu={{ items }}
      overlayClassName="drop-more-menu"
      arrow
    >
      <EllipsisOutlined className="btn-more" />
    </Dropdown>
  );
};

export default StandFileActionField;
