import React from "react";
import { Form, Select } from "antd";
import Icons from "components/icons";
import { Localize } from "components/service";

const UserLanguageSelect = ({
  disabled = false,
  rules = true,
  initialValue,
  mode = "",
  name = "language_slug",
  label = "FORMS.Input_Label_Language",
}) => {
  return (
    <Form.Item
      name={name}
      label={Localize({ children: label }).props.children}
      initialValue={initialValue}
      rules={
        rules && [
          {
            required: true,
            message: `
            ${Localize({ children: label }).props.children}`,
          },
        ]
      }
    >
      <Select
        showSearch
        suffixIcon={<Icons.Arrow />}
        mode={mode}
        disabled={disabled}
        placeholder={`
          ${Localize({ children: label }).props.children}`}
        popupClassName="select-serch-dropdown"
        optionFilterProp="value"
      >
        <Select.Option key="en" value="en">
          <Localize>FORMS.Select_OptionLabel_English</Localize>{" "}
        </Select.Option>
        <Select.Option key="de" value="de">
          <Localize>FORMS.Select_OptionLabel_Deutsch</Localize>{" "}
        </Select.Option>
      </Select>
    </Form.Item>
  );
};

export default UserLanguageSelect;
