import Graphic from "components/graphic";
import { useBreadCrumbs } from "components/use-hooks";
import { Localize } from "components/service";

const graphicBasePath = Graphic.Const.basePath;

const GraphicOverviewSubPage = ({ graphic, history }) => {
  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_GraphicRules" }).props
        .children,
      path: `${graphicBasePath}`,
    },
    {
      label:
        (graphic && graphic?.title) ||
        Localize({ children: "BREADCRUMBS.Label_Create" }).props.children,
      path:
        (graphic && `${graphicBasePath}/${graphic?.id}`) ||
        `${graphicBasePath}/create`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  return (
    <div className="row-grid col-lg-2">
      <div className="col-left">
        <Graphic.Forms.Edit graphic={graphic} history={history} />
      </div>
      <div className="col-right"></div>
    </div>
  );
};

export default GraphicOverviewSubPage;
