import React from "react";
import { Link } from "react-router-dom";
import GraphicConst from "../graphic-const";

const GraphicInfoField = ({ graphic }) => {
  return (
    <Link
      className="table-link-underline"
      to={`${GraphicConst.basePath}/${graphic?.id}`}
    >
      {graphic?.title}
    </Link>
  );
};

export default GraphicInfoField;
