import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { Button, Input } from "antd";

import { GET_EQUIPMENT_VARIATIONS } from "graphql/query/equipment-gql";

import { useBreadCrumbs } from "components/use-hooks";
import { Tables } from "components/layout";
import { Localize, ModalStandard } from "components/service";
import Icons from "components/icons";
import Variation from "components/varation";
import Equipment from "components/equipment";

const { Search } = Input;

const equipmentBasePath = Equipment.Const.basePath;

const EquipmentVariationsSubPage = ({ equipment, lang }) => {
  const basePath = Variation.Const.basePath;
  const tableHelper = Variation.Helpers.TableMain;

  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_Equipment" }).props
        .children,
      path: `${equipmentBasePath}`,
    },
    {
      label: equipment?.title ?? Localize({ children: "VARIABLE.Label_EMPTY" }),
      path: `${basePath}/edit/${equipment?.group_id}/${lang}`,
    },
    {
      label: Localize({ children: "BREADCRUMBS.Label_Variations" }).props
        .children,
      path: `${equipmentBasePath}/edit/${equipment?.group_id}/${lang}/variations`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const equipmentID = {
    column: "EQUIPMENT_GROUP ",
    operator: "EQ",
    value: parseInt(equipment?.group_id),
  };

  let match = useRouteMatch(`${basePath}/page/:pageNum`);

  const objOrderBy = [{ column: "ID", order: "DESC" }];

  const [searchText, setSearchText] = useState("");
  const [variables, setVariables] = useState("");

  return (
    <Tables.Main
      model="equipmentVariations"
      query={GET_EQUIPMENT_VARIATIONS}
      routeUrl={`${equipmentBasePath}/edit/${equipment?.group_id}/variations`}
      currentPage={match ? parseInt(match.params.pageNum) : 1}
      searchText={searchText}
      objOrderBy={objOrderBy}
      varSet={{ perPage: 30 }}
      objectWhere={{ ...equipmentID, AND: [{}] }}
      tableHelper={tableHelper}
      setVariables={setVariables}
    >
      <div className="table-action-bar">
        <div className="col">
          <Search
            className="filter-search"
            prefix={<Icons.Search />}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>

        <div className="col">
          <ModalStandard
            width={500}
            extraClass={"modal-form"}
            modalButton={
              <Button type="primary" icon={<Icons.Plus />}>
                <span className="ellipsis">
                  <Localize>GLOBAL.Button_Text_CreateNew</Localize>
                </span>
              </Button>
            }
          >
            <strong className="modal-title">
              <span className="text">
                <Localize>FORMS.Title_CreateVariation</Localize>
              </span>
            </strong>

            <Variation.Forms.Edit
              groupID={equipment?.group_id}
              variables={variables}
            />
          </ModalStandard>
        </div>
      </div>
    </Tables.Main>
  );
};

export default EquipmentVariationsSubPage;
