import React from "react";
import { useHistory } from "react-router-dom";
import { Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { userUseMutation } from "../hooks";
import UserConst from "../users-const";
import UserAction from "../actions";
import { EntityRemoveButton, Localize, ModalStandard } from "components/service";
import Icons from "components/icons";

const UserActionField = ({ userId, userName }) => {
  const history = useHistory();

  const { _setMutationUserDelete, loadingMutationUserDelete } =
    userUseMutation.delete(userId);

  const items = [
    {
      label: <Localize>MENUS_DROP.Label_Edit</Localize>,
      key: "1",
      icon: <Icons.Edit />,
      onClick: () => history.push(`${UserConst.basePath}/${userId}`),
    },
    {
      key: "2",
      icon: (
        <ModalStandard
          width={400}
          modalButton={
            <span style={{ display: "flex", alignItems: "center" }}>
              <Icons.SendPass /> <Localize>MENUS_DROP.Label_SendPass</Localize>
            </span>
          }
        >
          <UserAction.Fields.SendPass userID={userId} />
        </ModalStandard>
      ),
    },
    {
      key: "3",
      icon: (
        <EntityRemoveButton
          modalButton={
            <span style={{ display: "flex", alignItems: "center", width: 100 }}>
              <Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize>
            </span>
          }
          nameEntity={
            Localize({ children: "ENTITY.Modal_Title_User" }).props.children
          }
          dataNameEntity={userName}
          loading={loadingMutationUserDelete}
          deleteMutation={_setMutationUserDelete}
          variables={{
            id: userId,
          }}
        />
      ),
    },
  ];

  return (
    <Dropdown
      // trigger={['click']}
      placement="bottomRight"
      menu={{ items }}
      overlayClassName="drop-more-menu"
      arrow
    >
      <EllipsisOutlined className="btn-more" />
    </Dropdown>
  );
};

export default UserActionField;
