import React, { useState } from "react";
import { Button, Input } from "antd";
import { useRouteMatch, useHistory } from "react-router-dom";

import { GET_ROLES } from 'graphql/query/role-gql';

import Roles from 'components/role';
import Icons from "components/icons";
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import { Localize } from "components/service";

import './role.scss';

const { Search } = Input;


const basePath = Roles.Const.basePath;
const tableHelper = Roles.Helpers.TableMain;


const RolesPage = () => {

    useActiveMenuItem([ "users" ], [ "users" ]);

    const breadcrumbs = [
        {
            label: Localize({ children: "BREADCRUMBS.Label_Roles" }).props.children,
            path: `${ basePath }`
        }
    ]

    useBreadCrumbs(breadcrumbs);

    const history = useHistory();

    let match = useRouteMatch(`${ basePath }/page/:pageNum`);

    const [ searchText, setSearchText ] = useState("");

    const pageNavbar = [
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Users" }).props.children,
            path: "users"
        },

        {
            label: Localize({ children: "NAVBAR_PAGES.Label_UserRoles" }).props.children,
            path: "user-roles"
        } ];


    return (
        <PageWrapDefault
            className="page-roles"
            dataExist={ true }
            title={ <Localize>PAGES.Title_Users</Localize> }
            pageNavbar={ pageNavbar }
            staticPath=""
        >
            <Tables.Main
                model="roles"
                query={ GET_ROLES }
                varSet={ { perPage: 30 } }
                routeUrl={ `${ basePath }` }
                currentPage={ match ? parseInt(match.params.pageNum) : 1 }
                searchText={ searchText }
                tableHelper={ tableHelper }
            >
                <div className="table-action-bar">

                    <div className="col">

                        <Search
                            className="filter-search"
                            prefix={ <Icons.Search /> }
                            onChange={ (e) => setSearchText(e.target.value) }
                        />

                        <Button
                            disabled
                            icon={ <Icons.Filter /> }>
                            <span className="ellipsis"><Localize>FORMS.Button_Text_Filters</Localize></span>
                        </Button>
                    </div>

                    <div className="col">
                        <Button
                            type="primary"
                            icon={ <Icons.Plus /> }
                            onClick={ () => history.push(`${ basePath }/create`) }
                        >
                            <span className="ellipsis"><Localize>GLOBAL.Button_Text_CreateNew</Localize></span>
                        </Button>
                    </div>
                </div>
            </Tables.Main>
        </PageWrapDefault>
    )
};

export default RolesPage;