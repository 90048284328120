import { LoginPage, ForgotPasswordPage } from "./authorization";
import { UsersPage, UserPage } from "./user";
import { RolesPage, RolePage } from "./role";
import { SuppliersPage, SupplierPage } from "./supplier";
import { VariableGroupPage, VariableListPage } from "./variable";
import { TeamPage, TeamsPage } from "./team";
import { FlooringsPage, FlooringPage } from "./flooring";
import { EquipmentsPage, EquipmentPage } from "./equipment";
import { StandsPage, StandPage } from "./stand";
import { OrganizersPage, OrganizerPage } from "./organizer";
import { ExhibitionsPage, ExhibitionPage } from "./exhibition";
import { GraphicsPage, GraphicPage } from "./graphic";

import Dashboard from "./dashboard/dashboard-page";
import Page404 from "./404/404-page";

import "./pages.scss";

export const Pages = {
  Dashboard,
  Users: UsersPage,
  User: UserPage,
  Roles: RolesPage,
  Role: RolePage,
  Supliers: SuppliersPage,
  Suplier: SupplierPage,
  Floorings: FlooringsPage,
  Flooring: FlooringPage,
  Equipments: EquipmentsPage,
  Equipment: EquipmentPage,
  Stands: StandsPage,
  Stand: StandPage,
  VariableGroup: VariableGroupPage,
  VariableList: VariableListPage,
  Team: TeamPage,
  Teams: TeamsPage,
  Organizers: OrganizersPage,
  Organizer: OrganizerPage,
  Exhibitions: ExhibitionsPage,
  Exhibition: ExhibitionPage,
  Graphics: GraphicsPage,
  Graphic: GraphicPage,
  Page404,
};

export const GuestPages = {
  LoginPage,
  ForgotPasswordPage,
};
