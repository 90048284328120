import GraphicFields from "../fields";
import { Localize } from "components/service";

const graphicRulesColumns = [
  {
    title: <Localize>TABLES.Column_Title_Leistungscode</Localize>,
    dataIndex: "title",
    columnIndex: "TITLE",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_EPFertigungListe</Localize>,
    dataIndex: "price_manufacture",
    columnIndex: "PRICE_MANUFACTURE",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_EPMontageWerkstatt</Localize>,
    dataIndex: "price_on_supplier",
    columnIndex: "PRICE_ON_SUPPLIER ",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_EPMontageStand</Localize>,
    dataIndex: "price_on_stand",
    columnIndex: "PRICE_ON_STAND",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_Lettering</Localize>,
    dataIndex: "lettering",
    columnIndex: "LETTERING",
    sorter: true,
  },
  {
    // title: '',
    dataIndex: "action",
    align: "right",
    width: "5%",
  },
];

const graphicRulesData = (graphics, currentObj, variables) => {
  if (!graphics) {
    return [];
  }

  return graphics.map((graphic) => {
    return {
      key: graphic.id,
      title: (
        <GraphicFields.RuleInfo
          graphic={graphic}
          graphSupplierID={currentObj?.id}
          variables={variables}
        />
      ),
      price_manufacture: graphic.price_manufacture.toFixed(2) + " €" ?? (
        <strong>&mdash;</strong>
      ),
      price_on_supplier: graphic.price_on_supplier.toFixed(2) + " €" ?? (
        <strong>&mdash;</strong>
      ),
      price_on_stand: graphic.price_on_stand.toFixed(2) + " €" ?? (
        <strong>&mdash;</strong>
      ),
      lettering: graphic.lettering ? "Yes" : "No" ?? <strong>&mdash;</strong>,
      action: (
        <GraphicFields.RuleAction
          graphic={graphic}
          variables={variables}
          graphSupplierID={currentObj?.id}
        />
      ),
    };
  });
};

const GraphicRulesTableHelper = {
  columns: graphicRulesColumns,
  data: graphicRulesData,
};

export default GraphicRulesTableHelper;
