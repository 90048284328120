import Team from "components/team";
import { useBreadCrumbs } from "components/use-hooks";
import { Localize } from "components/service";

const teamBasePath = Team.Const.basePath;

const TeamOverviewSubPage = ({ team, history }) => {
  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_Teams" }).props.children,
      path: `${teamBasePath}`,
    },
    {
      label:
        (team && `${team?.title}`) ||
        Localize({ children: "BREADCRUMBS.Label_Create" }).props.children,
      path: (team && `${teamBasePath}/${team?.id}`) || `${teamBasePath}/create`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  return (
    <div className="row-grid col-lg-2">
      <div className="col-left">
        <Team.Forms.Edit team={team} history={history} />
      </div>
      <div className="col-right"></div>
    </div>
  );
};

export default TeamOverviewSubPage;