import React from "react";
import { Link } from "react-router-dom";
import OrganizerConst from "../organizer-const";

const OrganizerInfoField = ({ organizer }) => {
  return (
    <Link
      className="table-link-underline"
      to={`${OrganizerConst.basePath}/${organizer?.id}`}
    >
      {organizer?.title}
    </Link>
  );
};

export default OrganizerInfoField;
