import React from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { Pages } from "components/pages";

export default function StandsRoute() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} exact>
        <Pages.Stands />
      </Route>

      <Route path={`${path}/edit/:group_id`} exact>
        {({ match }) => {
          const { group_id } = match.params;
          return <Redirect to={`${path}/edit/${group_id}/de`} />;
        }}
      </Route>

      <Route path={`${path}/edit/:group_id/:lang`}>
        {({ match }) => <Pages.Stand match={match} exact />}
      </Route>

      <Route path={`${path}/create/:lang`} exact>
        <Pages.Stand />
      </Route>
    </Switch>
  );
}
