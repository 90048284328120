import { Localize } from "components/service";
import React from "react";
import { Link } from "react-router-dom";
import RoleConst from "../role-const";

const RoleRoleField = ({ role }) => {
  return Object.keys(role).length ? (
    <Link
      className="table-link-underline"
      style={{ color: role?.colorHex }}
      to={`${RoleConst.basePath}/${role?.id}`}
    >
      <strong>
        <Localize>{role.title}</Localize>
      </strong>
    </Link>
  ) : (
    <strong>&mdash;</strong>
  );
};

export default RoleRoleField;
