import React from "react";

import { GET_STAND_TYPES_CURSOR } from "graphql/query/stand-gql";
import { GET_EQUIPMENT_CURSOR } from "graphql/query/equipment-gql";
import { GET_FLOORING_CURSOR } from "graphql/query/flooring-gql";

import { exhibitionUseMutation } from "../hooks";
import { EntityRemoveButton, Localize } from "components/service";
import Icons from "components/icons";

const ExhibitionRemoveField = ({
  disabled = false,
  et_id,
  dataQuery,
  variables,
  model,
  identifier = "group_id",
}) => {
  let query = "";

  switch (model) {
    case "Equipment":
      query = GET_EQUIPMENT_CURSOR;
      break;
    case "StandType":
      query = GET_STAND_TYPES_CURSOR;
      break;
    case "Flooring":
      query = GET_FLOORING_CURSOR;
      break;
    default:
      break;
  }

  const { _setMutationExhibitionRemove, loadingMutationExhibitionRemove } =
    exhibitionUseMutation.remove(query, variables);

  return (
    <EntityRemoveButton
      okText={
        Localize({ children: "GLOBAL.Button_Text_Remove" }).props.children
      }
      disabled={disabled}
      modalButton={
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            fontSize: "22px",
            color: !disabled
              ? "var(--table_colorIconRemove)"
              : "var(--table_colorIconRemoveDisabled)",
            cursor: !disabled ? "pointer" : "default",
          }}
        >
          <Icons.Close />
        </span>
      }
      nameEntity={
        Localize({ children: `ENTITY.Modal_Title_${model}` }).props.children
      }
      dataNameEntity={dataQuery?.title}
      loading={loadingMutationExhibitionRemove}
      deleteMutation={_setMutationExhibitionRemove}
      variables={{
        et_id,
        model,
        model_id: [dataQuery[identifier]],
      }}
    />
  );
};

export default ExhibitionRemoveField;
