import React, { useState } from "react";
import { Button } from "antd";
import { useHistory } from "react-router-dom";

import { GET_GRAPHIC_SUPPLIERS_CURSOR } from "graphql/query/graphic-gql";

import Graphic from "components/graphic";
import Icons from "components/icons";
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import { Localize } from "components/service";
import { gqlBuilderWhere } from "utils";

import "./graphic.scss";

const basePath = Graphic.Const.basePath;
const tableHelper = Graphic.Helpers.TableMain;

const GraphicsPage = () => {
  useActiveMenuItem([ "variable-lists" ], [ "variable-lists" ]);

  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_GraphicRules" }).props
        .children,
      path: `${basePath}`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const pageNavbar = [
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_VariableLists" }).props
        .children,
      path: "variable-lists",
    },
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_GraphicRules" }).props
        .children,
      path: "graphic-rules",
    },
  ];

  const history = useHistory();

  const objOrderBy = [{ column: "ID", order: "DESC" }];

  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState();
  const [resetSorting, setResetSorting] = useState(false);

  const graphicFilters = JSON.parse(localStorage.getItem("graphicFilters"));
  const graphicsSorting = JSON.parse(localStorage.getItem("graphicsSorting"));

  const objectFilters = filters || gqlBuilderWhere(graphicFilters);

  return (
    <PageWrapDefault
      className="page-graphics"
      dataExist={true}
      title={<Localize>PAGES.Title_Settings</Localize>}
      pageNavbar={pageNavbar}
      staticPath=""
    >
      <Tables.Cursor
        model="graphicSuppliersCursor"
        query={GET_GRAPHIC_SUPPLIERS_CURSOR}
        varSet={{ perPage: 50 }}
        routeUrl={`${basePath}`}
        searchText={searchText}
        tableHelper={tableHelper}
        objectWhere={objectFilters}
        objOrderBy={graphicsSorting || objOrderBy}
        sortingName="graphicsSorting"
        resetSorting={resetSorting}
      >
        <div className="table-action-bar">
          <div className="col">
            <Graphic.Filters.Table
              {...{
                setSearchText,
                filters,
                setFilters,
                graphicFilters,
                setResetSorting,
                objOrderBy,
              }}
            />
          </div>

          <div className="col">
            <Button
              type="primary"
              icon={<Icons.Plus />}
              onClick={() => history.push(`${basePath}/create`)}
            >
              <span className="ellipsis">
                <Localize>GLOBAL.Button_Text_CreateNew</Localize>
              </span>
            </Button>
          </div>
        </div>
      </Tables.Cursor>
    </PageWrapDefault>
  );
};

export default GraphicsPage;
