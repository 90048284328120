import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { FLOORING_DELETE } from "graphql/mutation/flooring-gql";
import FlooringConst from "../flooring-const";
import {
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationFlooringDelete = (id) => {
  const history = useHistory();

  const [_setMutationFlooringDelete, { loading }] = useMutation(
    FLOORING_DELETE,
    {
      update(cache, { data }) {
        const {
          flooringDelete: { label, message },
        } = data;

        history.push(FlooringConst.basePath);

        cache.evict({
          id: cache.identify({ id, __typename: "Flooring" }),
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message,
        });
      },
      onError(error) {
        errorNotification(error);
      },
    }
  );

  return {
    _setMutationFlooringDelete,
    loadingMutationFlooringDelete: loading,
  };
};

export default useMutationFlooringDelete;
