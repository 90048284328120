import React, { useState, useEffect } from "react";
import { Avatar, Button, Form, Select, Input } from "antd";
import { FileImageOutlined } from "@ant-design/icons";
import { Localize } from "components/service";
import { GET_ORGANIZERS_CURSOR } from "graphql/query/organizer-gql";
import { GET_VARIABLE_LISTS_CURSOR } from "graphql/query/variable-gql";
import { FormElements } from "components/layout";
import Icons from "components/icons";

const StandFiltersForm = ({
  form,
  action,
  defaultLang,
  stand,
  setCount,
  setFilters,
  setFiltersExtra,
  setFiltersMore,
  setFilterList,
  setFilterListExtra,
  setFilterListMore,
  filterList,
  filterListExtra,
  filterListMore,
  standFilters,
  standFiltersExtra,
  standFiltersMore,
  gqlBuilderWhere,
}) => {
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");

  /*eslint-disable */
  useEffect(() => {
    const applyPriceFilters = () => {
      if (minPrice && maxPrice) {
        setFilterList({
          ...filterList,
          PRICE: {
            column: "PRICE",
            operator: "BETWEEN",
            value: [minPrice, maxPrice],
          },
        });
      } else if (minPrice) {
        setFilterList({
          ...filterList,
          PRICE: {
            column: "PRICE",
            operator: "GTE",
            value: minPrice,
          },
        });
      } else if (maxPrice) {
        setFilterList({
          ...filterList,
          PRICE: {
            column: "PRICE",
            operator: "LTE",
            value: maxPrice,
          },
        });
      }
    };

    applyPriceFilters();
  }, [minPrice, maxPrice]);
  /*eslint-enable */

  const onChangeSetFilter = (e, name, value) => {
    if (e) {
      name = e.target.name.toUpperCase();
      value = e.target.value;
    }

    if (name === "ORIENTATION_KEY") {
      value.toString().length > 0
        ? setFilterListExtra({
            ...filterListExtra,
            [name]: {
              column: name,
              operator: "EQ",
              value: Array.isArray(value) ? value : [value],
            },
          })
        : setFilterListExtra({ ...filterListExtra, [name]: {} });
    } else if (name === "ORGANIZER_ID") {
      value.toString().length > 0
        ? setFilterListMore({
            ...filterListMore,
            [name]: {
              column: name,
              operator: "EQ",
              value: Array.isArray(value) ? value : [value],
            },
          })
        : setFilterListMore({ ...filterListMore, [name]: {} });
    } else {
      value.toString().length > 0
        ? setFilterList({
            ...filterList,
            [name]: {
              column: name,
              operator: "EQ",
              value: Array.isArray(value) ? value : [value],
            },
          })
        : setFilterList({ ...filterList, [name]: {} });
    }
  };

  return (
    <div className="ant-form ant-form-vertical filters-form">
      <div>
        <FormElements.RadioSelect
          skipQuery={!standFiltersMore}
          location="filter"
          form={form}
          query={GET_ORGANIZERS_CURSOR}
          model="organizersCursor"
          name="ORGANIZER_ID"
          label="FORMS.Input_Label_Organizer"
          modalTitle="FORMS.Title_SelectOrganizer"
          initialValue={
            standFiltersMore?.ORGANIZER_ID?.value
              ? [{ value: standFiltersMore?.ORGANIZER_ID?.value }]
              : false
          }
          onChangeSetFilter={onChangeSetFilter}
        />

        <FormElements.CheckboxSelect
          skipQuery={!standFiltersExtra}
          location="filter"
          extraClassName="light-theme"
          form={form}
          name="ORIENTATION_KEY"
          model="variableListsCursor"
          query={GET_VARIABLE_LISTS_CURSOR}
          tagInfo={false}
          label="FORMS.Input_Label_Orientations"
          modalTitle="FORMS.Title_AddOrientations"
          modalButtonText="MODAL.Button_Text_AddOrientation"
          modelCursorLabel={["slug"]}
          modelCursorValue="slug"
          initialLang={defaultLang}
          onChangeSetFilter={onChangeSetFilter}
          initialValue={
            standFiltersExtra?.ORIENTATION_KEY?.value?.length
              ? standFiltersExtra.ORIENTATION_KEY.value.map((value) => ({
                  value,
                }))
              : false
          }
          objectWhere={{
            AND: [
              {
                column: "LANG",
                operator: "EQ",
                value: defaultLang,
              },
              {
                column: "GROUP_NAME",
                operator: "EQ",
                value: "stand orientation",
              },
            ],
          }}
          jsx={(img_path) => (
            <div style={{ minWidth: "30px" }}>
              <Avatar
                style={{
                  borderRadius: 0,
                  border: "1px solid var(--page_colorBgLayoutInner)",
                }}
                shape="square"
                size={22}
                icon={
                  img_path ? (
                    <img src={img_path} alt="avatar" />
                  ) : (
                    <FileImageOutlined />
                  )
                }
              />
            </div>
          )}
        />

        <Form.Item
          name="COMPLEXITY"
          label={<Localize>FORMS.Input_Label_Complexity</Localize>}
          initialValue={standFilters?.COMPLEXITY?.value}
        >
          <Select
            suffixIcon={<Icons.Arrow />}
            onChange={(value) => {
              form.setFieldsValue({ COMPLEXITY: value });
              onChangeSetFilter(false, "COMPLEXITY", value);
            }}
          >
            {Array.from({ length: 5 }, (_, i) => i + 1).map((value) => (
              <Select.Option key={value} value={String(value)}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="MIN_PRICE"
          label={<Localize>FORMS.Input_Label_MinPrice</Localize>}
          initialValue={
            standFilters?.PRICE?.operator === "GTE"
              ? standFilters?.PRICE?.value
              : standFilters?.PRICE?.operator === "BETWEEN"
              ? standFilters?.PRICE?.value[0]
              : ""
          }
          onChange={(e) => setMinPrice(e.target.value)}
        >
          <Input name="MIN_PRICE" />
        </Form.Item>

        <Form.Item
          name="MAX_PRICE"
          label={<Localize>FORMS.Input_Label_MaxPrice</Localize>}
          initialValue={
            standFilters?.PRICE?.operator === "LTE"
              ? standFilters?.PRICE?.value
              : standFilters?.PRICE?.operator === "BETWEEN"
              ? standFilters?.PRICE?.value[1]
              : ""
          }
          onChange={(e) => setMaxPrice(e.target.value)}
        >
          <Input name="MAX_PRICE" />
        </Form.Item>
      </div>

      <div className="form-btn-holder">
        <Button
          className="light-bg"
          htmlType="submit"
          onClick={() => {
            setCount({});
            setFilterList({});
            setFilterListExtra({});
            setFilterListMore({});
            setFilters(gqlBuilderWhere({}));
            setFiltersExtra(gqlBuilderWhere({}));
            setFiltersMore(gqlBuilderWhere({}));
            localStorage.removeItem(
              `standFilters_groupID-${stand?.group_id || 0}`
            );
            localStorage.removeItem(
              `standFiltersExtra_groupID-${stand?.group_id || 0}`
            );
            localStorage.removeItem(
              `standFiltersMore_groupID-${stand?.group_id || 0}`
            );
            localStorage.removeItem(
              `standsSorting_groupID-${stand?.group_id || 0}`
            );
          }}
        >
          <Localize>FORMS.Button_Text_ResetFilters</Localize>
        </Button>

        <Button
          className="btn-right"
          type="primary"
          htmlType="submit"
          onClick={() => {
            setFilters(gqlBuilderWhere({ ...standFilters, ...filterList }));
            setFiltersExtra(
              gqlBuilderWhere({ ...standFiltersExtra, ...filterListExtra })
            );
            setFiltersMore(
              gqlBuilderWhere({ ...standFiltersMore, ...filterListMore })
            );
            setCount({
              ...standFilters,
              ...filterList,
              ...standFiltersExtra,
              ...filterListExtra,
              ...standFiltersMore,
              ...filterListMore,
            });
            action();
          }}
        >
          <Localize>FORMS.Button_Text_ApplyFilters</Localize>
        </Button>
      </div>
    </div>
  );
};

export default StandFiltersForm;
