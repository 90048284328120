import React from "react";
import { Button } from "antd";
import { Localize } from "components/service";
import { GET_SUPPLIER_CURSOR } from "graphql/query/supplier-gql";
import { GET_ORGANIZERS_CURSOR } from "graphql/query/organizer-gql";
import { FormElements } from "components/layout";

const GraphicFiltersForm = ({
  form,
  action,
  setCount,
  graphicFilters,
  setFilters,
  setFilterList,
  filterList,
  gqlBuilderWhere,
}) => {
  const onChangeSetFilter = (e, name, value) => {
    if (e) {
      name = e.target.name;
      value = e.target.value;
    }

    value.toString().length > 0
      ? setFilterList({
          ...filterList,
          [name]: {
            column: name,
            operator: "IN",
            value: Array.isArray(value) ? value : [value],
          },
        })
      : setFilterList({ ...filterList, [name]: {} });
  };

  return (
    <div className="ant-form ant-form-vertical filters-form">
      <div>
        <FormElements.RadioSelect
          skipQuery={!graphicFilters}
          location="filter"
          form={form}
          query={GET_SUPPLIER_CURSOR}
          model="suppliersCursor"
          name="SUPPLIER_ID"
          label="FORMS.Input_Label_PrintingCompany"
          modalTitle="FORMS.Title_SelectPrintingCompany"
          objectWhere={{
            column: "TYPE",
            value: "supplier_type_digital_print_supplier",
          }}
          initialValue={
            graphicFilters?.SUPPLIER_ID?.value
              ? [{ value: graphicFilters?.SUPPLIER_ID?.value }]
              : false
          }
          onChangeSetFilter={onChangeSetFilter}
        />
        <FormElements.RadioSelect
          skipQuery={!graphicFilters}
          location="filter"
          form={form}
          query={GET_ORGANIZERS_CURSOR}
          model="organizersCursor"
          name="ORGANIZER_ID"
          label="FORMS.Input_Label_Organizer"
          modalTitle="FORMS.Title_SelectOrganizer"
          initialValue={
            graphicFilters?.ORGANIZER_ID?.value
              ? [{ value: graphicFilters?.ORGANIZER_ID?.value }]
              : false
          }
          onChangeSetFilter={onChangeSetFilter}
        />
      </div>

      <div className="form-btn-holder">
        <Button
          className="light-bg"
          htmlType="submit"
          onClick={() => {
            setCount({});
            setFilterList({});
            setFilters(gqlBuilderWhere({}));
            localStorage.removeItem("graphicFilters");
            localStorage.removeItem("graphicsSorting");
          }}
        >
          <Localize>FORMS.Button_Text_ResetFilters</Localize>
        </Button>

        <Button
          className="btn-right"
          type="primary"
          htmlType="submit"
          onClick={() => {
            setFilters(gqlBuilderWhere({ ...graphicFilters, ...filterList }));
            setCount({
              ...graphicFilters,
              ...filterList,
            });
            action();
          }}
        >
          <Localize>FORMS.Button_Text_ApplyFilters</Localize>
        </Button>
      </div>
    </div>
  );
};

export default GraphicFiltersForm;
