import React from "react";
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import SubPage from 'components/pages/supplier/sub-pages';


const SupplierRoute = ({ supplier, history }) => {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={ `${ path }/users` } exact>
                <SubPage.Users supplier={ supplier } />
            </Route>

            <Route path={ `${ path }/users/page/:pageNum` } exact>
                <SubPage.Users supplier={ supplier } />
            </Route>

            <Route path={ `${ path }` }>
                <SubPage.OverView supplier={ supplier } history={ history } />
            </Route>
        </Switch>
    );
};

export default SupplierRoute;