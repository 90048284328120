import VariationFields from "./fields";
import VariationForms from "./forms";
import VariationConst from "./variation-const";
import VariationsHelpers from "./helpers";
// import VariationsFilters from './filters';
import { variationUseMutation } from "./hooks";


const Variation = {
  // Filters: VariationsFilters,
  Fields: VariationFields,
  Forms: VariationForms,
  Const: VariationConst,
  Helpers: VariationsHelpers,
  Hooks: variationUseMutation
}

export default Variation;
