import React, { useState } from "react";
import { Button, Input } from "antd";
import { useRouteMatch } from "react-router-dom";

import { GET_USERS } from "graphql/query/user-gql";
import { SUPPLIER_SYNC_USERS } from "graphql/mutation/supplier-gql";

import Supplier from "components/supplier";
import Icons from "components/icons";
import { useBreadCrumbs } from "components/use-hooks";
import { Tables } from "components/layout";
import { Localize, ModalStandard } from "components/service";
import User from "components/user";

const { Search } = Input;

const SupplierUsersSubPage = ({ supplier }) => {
  const basePath = Supplier.Const.basePath;
  const tableHelper = Supplier.Helpers.TableUsers;

  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_Suppliers" }).props
        .children,
      path: `${basePath}`,
    },
    {
      label: supplier?.title,
      path: `${basePath}/${supplier?.id}`,
    },
    {
      label: Localize({ children: "BREADCRUMBS.Label_Users" }).props.children,
      path: `${basePath}/${supplier?.id}/users`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const supplierID = {
    column: "SUPPLIER_ID",
    operator: "EQ",
    value: parseInt(supplier.id),
  };

  let match = useRouteMatch(`${basePath}/page/:pageNum`);

  const objOrderBy = [{ column: "ID", order: "DESC" }];

  const [searchText, setSearchText] = useState("");

  return (
    <div className="col-left">
      <Tables.Main
        model="users"
        query={GET_USERS}
        routeUrl={`${basePath}/${supplier.id}/users`}
        currentPage={match ? parseInt(match.params.pageNum) : 1}
        searchText={searchText}
        objOrderBy={objOrderBy}
        varSet={{ perPage: 30 }}
        currentObj={supplier}
        tableHelper={tableHelper}
        objectWhere={{ ...supplierID, AND: [{}] }}
      >
        <div className="table-action-bar">
          <div className="col">
            <Search
              className="filter-search"
              prefix={<Icons.Search />}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>

          <div className="col">
            <ModalStandard
              width={400}
              extraClass={"modal-form"}
              modalButton={
                <Button type="primary" icon={<Icons.Plus />}>
                  <span className="ellipsis">
                    <Localize>GLOBAL.Button_Text_AddNew</Localize>
                  </span>
                </Button>
              }
            >
              <strong className="modal-title">
                <span className="text">
                  <Localize>FORMS.Title_AddUsers</Localize>
                </span>
              </strong>

              <User.Forms.Add
                propsObj={{
                  query: GET_USERS,
                  queryVar: {
                    first: 10000,
                    where: supplierID,
                  },
                  mutation: SUPPLIER_SYNC_USERS,
                  mutationName: "supplierSyncToUsers",
                  modelID: { supplier_id: supplier?.id },
                }}
              />
            </ModalStandard>
          </div>
        </div>
      </Tables.Main>
    </div>
  );
};

export default SupplierUsersSubPage;
