import { useMutation } from "@apollo/client";
import { STAND_TYPE_NOTE_ATTACHMENT_DELETE } from "graphql/mutation/stand-gql";
import {
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationStandNoteAttachmentNoteAttachmentDelete = ({ id }) => {
  const [_setMutationStandNoteAttachmentDelete, { loading }] = useMutation(
    STAND_TYPE_NOTE_ATTACHMENT_DELETE,
    {
      update(cache, { data }) {
        const {
          attachmentDelete: { label, message },
        } = data;

        cache.evict({
          id: cache.identify({ id, __typename: "Attachment" }),
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message,
        });
      },
      onError(error) {
        errorNotification(error);
      },
    }
  );

  return {
    _setMutationStandNoteAttachmentDelete,
    loadingMutationStandNoteAttachmentDelete: loading,
  };
};

export default useMutationStandNoteAttachmentNoteAttachmentDelete;
