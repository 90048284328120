import Role from "components/role";
import { useBreadCrumbs } from "components/use-hooks";
import { Localize } from "components/service";

const roleBasePath = Role.Const.basePath;

const RoleOverviewSubPage = ({ role, history }) => {
  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_Roles" }).props.children,
      path: `${roleBasePath}`,
    },
    {
      label:
        (role && <Localize>{role.title}</Localize>) ||
        Localize({ children: "BREADCRUMBS.Label_Create" }).props.children,
      path: (role && `${roleBasePath}/${role?.id}`) || `${roleBasePath}/create`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  return (
    <div className="row-grid col-xl-2">
      <div className="col-left">
        <Role.Forms.Edit role={role} history={history} />
      </div>
      <div className="col-right"></div>
    </div>
  );
};

export default RoleOverviewSubPage;
