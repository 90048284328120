import React from "react";
import { Form, Select } from "antd";
import { useApolloClient } from "@apollo/client";
import { GET_VARIABLE_LIST_ALL } from "graphql/query/variable-gql";
import { GET_ME } from "graphql/query/user-gql";
import Flooring from "components/flooring";
import Equipment from "components/equipment";
import { Localize } from "components/service";
import Icons from "components/icons";

const VariableVariablesSelect = ({
  form,
  disabled = false,
  disabledColor = false,
  rules = false,
  onChangeSetFilter = () => {},
  initialValue,
  activeLang = false,
  mode = false,
  name = "role_id",
  groupName = "",
  label = Localize({ children: "FORMS.Input_Label_Model" }).props.children,
  requiredText = Localize({ children: "FORM_RULES.Required_Choose" }).props
    .children,
  notAssignedOption = (
    <Select.Option key="notAssigned" value="">
      <Localize>FORMS.Select_OptionLabel_NotAssigned</Localize>
    </Select.Option>
  ),
}) => {
  const client = useApolloClient();

  const cachedVariables = client.readQuery({
    query: GET_VARIABLE_LIST_ALL,
  });

  const cachedMe = client.readQuery({
    query: GET_ME,
  });

  const lang = cachedMe.me.lang_app;

  const variableListAll = cachedVariables?.variableListAll;

  const variables = variableListAll?.filter(
    ({ group_name }) => group_name.toLowerCase() === groupName.toLowerCase()
  );

  const uniqueItems = new Set();
  const result = [];

  variables?.forEach((item) => {
    const itemStr = JSON.stringify(item);
    if (!uniqueItems.has(itemStr)) {
      uniqueItems.add(itemStr);
      result.push(item);
    }
  });

  const translationCache = (lang) =>
    result.filter((item) => item?.lang === lang);

  const handleChange = (value) => {
    form.setFieldsValue({ [name]: value });
    onChangeSetFilter(false, name, value);
  };

  const { colorHexList: flooringColors } = Flooring.Const;
  const { colorHexList: equipmentColors } = Equipment.Const;

  const renderOption = ({ slug, value }) => {
    if (value === null || value === undefined || value === "") {
      return (
        <Select.Option key={slug} value={slug}>
          <Localize>VARIABLE.Label_EMPTY</Localize>
        </Select.Option>
      );
    }

    return (
      <Select.Option key={slug} value={slug}>
        {name.toLowerCase() === "color" && disabledColor ? (
          <div className="d-flex align-items-center">
            <div
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                marginRight: "10px",
                background:
                  groupName === "flooring color"
                    ? flooringColors(slug)
                    : equipmentColors[slug],
              }}
            ></div>
            <Localize>{value}</Localize>
          </div>
        ) : (
          <span>{value}</span>
        )}
      </Select.Option>
    );
  };

  return (
    <Form.Item
      name={name}
      label={label}
      initialValue={initialValue}
      rules={
        rules && [
          {
            required: true,
            message: requiredText,
          },
        ]
      }
    >
      <Select
        showSearch
        showArrow
        suffixIcon={<Icons.Arrow />}
        mode={mode}
        className="select-serch-input"
        disabled={disabled}
        onChange={handleChange}
        placeholder={
          Localize({ children: "FORMS.Input_Placeholder_Choose" }).props
            .children
        }
        popupClassName="select-serch-dropdown"
        optionFilterProp="value"
      >
        {notAssignedOption}
        {translationCache(activeLang ? activeLang : lang)?.map(renderOption)}
      </Select>
    </Form.Item>
  );
};

export default VariableVariablesSelect;
