import { useLocation } from "react-router-dom";
import Stand from "components/stand";
import Exhibition from "components/exhibition";
import { Localize } from "components/service";

const StandsGridHelper = ({ model, language, variables, currentObj, extraObj }) => {
  const location = useLocation();
  const containsOrganizers = location.pathname.includes("organizers");
  const containsExhibitions = location.pathname.includes("exhibition-types");
  return (
    <div className="card-view-vertical">
      {model?.map((item) => (
        <div key={item.id} className="card">
          <div className="card-image">
            <Stand.Fields.Image stand={item} lang={language} grid={true} />
          </div>
          <div className="nav-bar">
            <Stand.Fields.Langs stand={item} />
            {containsOrganizers ? (
              <Exhibition.Fields.Remove
                model="StandType"
                et_id={currentObj?.et_default_id}
                dataQuery={item}
                variables={variables}
              />
            ) : containsExhibitions ? (
              <Exhibition.Fields.Remove
                model="StandType"
                et_id={currentObj?.id}
                dataQuery={item}
                variables={variables}
              />
            ) : (
              <Stand.Fields.Action
                stand={item}
                variables={variables}
                language={language}
              />
            )}
          </div>
          <div className="card-details">
            <div className="d-flex justify-content-between">
              <span className="title">
                <Stand.Fields.Info stand={item} lang={language} />
              </span>
            </div>

            <Stand.Fields.Orientation stand={item} />

            <ul className={`info-list ${extraObj ? "role" : ""}`}>
              <li>
                <Localize>CARD.Info_List_NavisionNumb</Localize>:
                <span className="info">
                  {item?.navision_number ?? <strong>&mdash;</strong>}
                </span>
              </li>
              <li>
                <Localize>CARD.Info_List_Size</Localize>:
                <span className="info">
                  {item.size_min ? (
                    <>
                      {" "}
                      {item.size_min} <Localize>GLOBAL.Text_Sqm</Localize>
                    </>
                  ) : (
                    <strong>&mdash;</strong>
                  )}
                  &nbsp; - &nbsp;
                  {item.size_max ? (
                    <>
                      {" "}
                      {item.size_max} <Localize>GLOBAL.Text_Sqm</Localize>{" "}
                    </>
                  ) : (
                    <strong>&mdash;</strong>
                  )}
                </span>
              </li>
              <li>
                <Localize>CARD.Info_List_PricePer</Localize>:
                <span className="info">
                  {item?.price ? (
                    <>{item?.price.toFixed(2)} €</>
                  ) : (
                    <strong>&mdash;</strong>
                  )}
                </span>
              </li>
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default StandsGridHelper;
