import React from "react";
import { useQuery } from "@apollo/client";
import { GET_FLOORING } from "graphql/query/flooring-gql";
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import Flooring from "components/flooring";
import FlooringRoute from "components/routes/flooring/flooring-route";
import { Localize } from "components/service";

const FlooringPage = ({ match, history }) => {
  useActiveMenuItem(["flooring"], ["flooring"]);

  const basePath = Flooring.Const.basePath;
  const path = history.location.pathname.split("/").pop();
  const flooringID = match ? parseInt(match.params.id) : null;

  const { data: { flooring } = {}, loading } = useQuery(GET_FLOORING, {
    skip: !flooringID,
    variables: {
      id: flooringID,
    },
  });

  const pageNavbar = [
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_Overview" }).props
        .children,
      path: "",
    },
  ];

  return (
    <PageWrapDefault
      className="page-flooring"
      loading={loading}
      title={
        path === "create" ? (
          <Localize>PAGES.Title_CreateFlooring</Localize>
        ) : !loading && flooring ? (
          `${flooring.title}`
        ) : (
          "Page 404"
        )
      }
      dataExist={path === "create" ? true : !loading && flooring}
      pageNavbar={!loading && flooring ? pageNavbar : false}
      staticPath={`${basePath}/${flooringID}`}
    >
      <FlooringRoute flooring={flooring} history={history} loading={loading} />
    </PageWrapDefault>
  );
};

export default FlooringPage;
