import React from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button, Switch } from "antd";
import { GET_SUPPLIER_CURSOR } from "graphql/query/supplier-gql";
import { GET_ORGANIZERS_CURSOR } from "graphql/query/organizer-gql";

import { exhibitionUseMutation } from "../../hooks";
import Organizer from "components/organizer";
import { Localize, EntityRemoveButton } from "components/service";
import { FormElements } from "components/layout";
import Icons from "components/icons";

const ExhibitionEditForm = ({ exhibition, organizer }) => {
    const [form] = Form.useForm();

    const {
        _setExhibitionCreateUpdate,
        loadingMutationExhibitionCreateUpdate: loading,
    } = exhibitionUseMutation.createUpdate(exhibition?.id, organizer);

    const { _setMutationExhibitionDelete, loadingMutationExhibitionDelete } =
        exhibitionUseMutation.delete(exhibition?.id, organizer);

    const getInitialValueBySupplierGroup = (exhibition, supplierGroup) => {
        if (!exhibition) {
            return false;
        }

        const suppliersInGroup = exhibition.suppliers.filter(
            (supplier) => supplier.supplier_group === supplierGroup
        );

        return suppliersInGroup.length > 0
            ? suppliersInGroup.map(({ supplier_id, supplier_title }) => ({
                  value: supplier_id,
                  label: supplier_title,
              }))
            : false;
    };

    const valueFieldStatus = Form.useWatch("status", form);

    const getInitialValueByOrganizer = {
        exhibition: exhibition?.organizer?.id
            ? [
                  {
                      value: exhibition?.organizer?.id,
                      label: exhibition?.organizer?.title,
                  },
              ]
            : false,
        organizer: organizer?.id
            ? [
                  {
                      value: organizer?.id,
                      label: organizer?.title,
                  },
              ]
            : false,
    };

    const exhibitionTypeSuppliers = (group) => {
        if (exhibition?.default) {
            return null;
        } else {
            return {
                whereExhibitionTypeSuppliers: {
                    column: "ET_ID",
                    operator: "EQ",
                    value:
                        organizer?.et_default_id ||
                        exhibition?.organizer?.et_default_id,
                    AND: [
                        {
                            column: "SUPPLIER_GROUP",
                            operator: "EQ",
                            value: group,
                        },
                    ],
                },
            };
        }
    };

    return (
        <Form
            key="edit-exhibition-form"
            layout="vertical"
            form={form}
            className="model-form edit-exhibition-form"
            onFinish={(values) => {
                const selectedSuppliers = [];
                const groups = ["equipment", "print_house", "flooring"];
                const processGroup = (group) => {
                    if (values[`suppliers-${group}`]) {
                        selectedSuppliers.push(
                            ...values[`suppliers-${group}`].map((supplier) => ({
                                supplier_group: group,
                                supplier_id: supplier,
                            }))
                        );
                    }
                };

                groups.forEach(processGroup);

                _setExhibitionCreateUpdate({
                    variables: {
                        input: {
                            id: exhibition?.id ? +exhibition.id : undefined,
                            title: values.title,
                            organizer_id: values.organizer_id,
                            suppliers: selectedSuppliers,
                            status:
                                typeof values.status === "string"
                                    ? exhibition?.status
                                    : values?.status === true
                                    ? "active"
                                    : "closed",
                        },
                    },
                });
            }}
        >
            <div className="switch-holder">
                <Form.Item
                    name="status"
                    label={<Localize>FORMS.Input_Label_Status</Localize>}
                    initialValue={
                        exhibition?.status === "active" ? true : false
                    }
                    valuePropName="checked"
                    className="form-item-switch"
                >
                    <Switch
                        disabled={
                            exhibition?.default &&
                            exhibition?.status === "active"
                        }
                    />
                </Form.Item>
                {valueFieldStatus ? (
                    <span
                        style={{ color: "var(--switch_colorPrimary)" }}
                        className="switch-status-text"
                    >
                        <Localize>GLOBAL.Text_Active</Localize>
                    </span>
                ) : (
                    <span
                        style={{ color: "var(--switch_colorTextQuaternary)" }}
                        className="switch-status-text"
                    >
                        <Localize>GLOBAL.Text_Blocked</Localize>
                    </span>
                )}
            </div>

            <div className="row-grid grid-gap-10 row-gap-0 col-md-2">
                <Form.Item
                    name="title"
                    label={
                        <Localize>FORMS.Input_Label_ExhibitionName</Localize>
                    }
                    initialValue={exhibition?.title}
                    rules={[
                        {
                            required: true,
                            message: (
                                <Localize>
                                    FORM_RULES.Required_ExhibitionName
                                </Localize>
                            ),
                        },
                    ]}
                >
                    <Input name="title" />
                </Form.Item>

                <FormElements.RadioSelect
                    disabled={exhibition?.id || organizer?.id}
                    form={form}
                    query={GET_ORGANIZERS_CURSOR}
                    model="organizersCursor"
                    name="organizer_id"
                    label="FORMS.Input_Label_Organizer"
                    modalTitle="FORMS.Title_AddOrganizer"
                    initialValue={
                        getInitialValueByOrganizer[
                            exhibition ? "exhibition" : "organizer"
                        ]
                    }
                    tagInfo={
                        exhibition && (
                            <Link
                                className="select-link"
                                to={`${Organizer.Const.basePath}/${exhibition?.organizer?.id}`}
                            >
                                <Icons.Information />
                            </Link>
                        )
                    }
                />
            </div>

            {(organizer || exhibition) && (
                <>
                    <FormElements.CheckboxSelect
                        form={form}
                        query={GET_SUPPLIER_CURSOR}
                        model="suppliersCursor"
                        name="suppliers-equipment"
                        label="FORMS.Input_Label_EquipmentSuppliers"
                        modalTitle="FORMS.Title_AddEquipmentSuppliers"
                        modalButtonText="MODAL.Button_Text_AddSupplier"
                        extraVariables={exhibitionTypeSuppliers("equipment")}
                        initialValue={getInitialValueBySupplierGroup(
                            exhibition,
                            "equipment"
                        )}
                    />

                    <FormElements.CheckboxSelect
                        form={form}
                        query={GET_SUPPLIER_CURSOR}
                        model="suppliersCursor"
                        name="suppliers-print_house"
                        label="FORMS.Input_Label_PrintHouseSuppliers"
                        modalTitle="FORMS.Title_AddPrintHouseSuppliers"
                        modalButtonText="MODAL.Button_Text_AddSupplier"
                        extraVariables={exhibitionTypeSuppliers("print_house")}
                        initialValue={getInitialValueBySupplierGroup(
                            exhibition,
                            "print_house"
                        )}
                    />

                    <FormElements.CheckboxSelect
                        form={form}
                        query={GET_SUPPLIER_CURSOR}
                        model="suppliersCursor"
                        name="suppliers-flooring"
                        label="FORMS.Input_Label_FlooringSuppliers"
                        modalTitle="FORMS.Title_AddFlooringSuppliers"
                        modalButtonText="MODAL.Button_Text_AddSupplier"
                        extraVariables={exhibitionTypeSuppliers("flooring")}
                        initialValue={getInitialValueBySupplierGroup(
                            exhibition,
                            "flooring"
                        )}
                    />
                </>
            )}

            <div className="form-btn-holder">
                {exhibition?.id && (
                    <EntityRemoveButton
                        modalButton={
                            <Button icon={<Icons.Delete />}>
                                <span className="ellipsis">
                                    <Localize>
                                        EXHIBITION.Button_Text_Delete
                                    </Localize>
                                </span>
                            </Button>
                        }
                        nameEntity={
                            Localize({
                                children: "ENTITY.Modal_Title_ExhibitionType",
                            }).props.children
                        }
                        dataNameEntity={exhibition?.title}
                        loading={loadingMutationExhibitionDelete}
                        deleteMutation={_setMutationExhibitionDelete}
                        variables={{
                            id: exhibition?.id,
                        }}
                    />
                )}
                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"
                    icon={exhibition?.id ? <Icons.Edit /> : <Icons.Plus />}
                    loading={loading}
                >
                    <span className="ellipsis">
                        {exhibition?.id ? (
                            <Localize>GLOBAL.Button_Text_SaveChanges</Localize>
                        ) : (
                            <Localize>GLOBAL.Button_Text_CreateNew</Localize>
                        )}
                    </span>
                </Button>
            </div>
        </Form>
    );
};

export default ExhibitionEditForm;
