import React from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button, Upload } from "antd";
import { FileOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import { standUseMutation } from "../../hooks";
import { EntityRemoveButton, Localize } from "components/service";
import Icons from "components/icons";

const StandNoteEditForm = ({ stand, standNote, action, variables }) => {
  const [form] = Form.useForm();

  const formItemValue = Form.useWatch("file", form);

  const { _setStandNoteCreateUpdate, loadingMutationStandNoteCreateUpdate } =
    standUseMutation.noteCreateUpdate({
      id: standNote?.id,
      variables,
    });

  const { _setMutationStandNoteDelete, loadingMutationStandNoteDelete } =
    standUseMutation.noteDelete({
      id: standNote?.id,
    });

  const {
    _setMutationStandNoteAttachmentDelete,
    loadingMutationStandNoteAttachmentDelete,
  } = standUseMutation.noteAttachmentDelete({
    id: standNote?.attachments[0]?.id,
  });

  const handleSubmit = (values) => {
    let updatedValues = {
      ...values,
      id: standNote?.id ? standNote.id : undefined,
      stand_type_group: !standNote?.id ? stand?.group_id : undefined,
    };

    if (values.file && values.file.length > 0) {
      updatedValues.file = values.file[0].originFileObj;
    } else {
      delete updatedValues.file;
    }

    _setStandNoteCreateUpdate({
      variables: {
        input: updatedValues,
      },
    });

    action();
    form.resetFields();
  };

  return (
    <Form
      key={`form-stand-file-edit`}
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
    >
      <Form.Item
        name="description"
        label={<Localize>FORMS.Input_Label_AddText</Localize>}
        initialValue={standNote?.description}
      >
        <Input.TextArea
          autoSize={{ minRows: 3, maxRows: 6 }}
          style={{ fontWeight: 700 }}
        />
      </Form.Item>

      <Form.Item
        name="file"
        valuePropName="fileList"
        getValueFromEvent={(e) => e && e?.fileList}
      >
        <Upload
          className="form-upload-file"
          name="file"
          beforeUpload={() => {
            return false;
          }}
          maxCount={1}
          listType="text"
          showUploadList={{
            // showRemoveIcon: false,
            removeIcon: <Icons.Delete />,
          }}
        >
          {standNote?.attachments[0]?.original_name ? (
            <div className="">
              <div
                className="d-flex align-items-center"
                style={{ textTransform: "none" }}
              >
                <FileOutlined
                  style={{ marginRight: "8px", fontSize: "18px" }}
                />

                <div style={{ width: "85%", overflow: "hidden" }}>
                  <div className="download-file-info">
                    <Link
                      to="#"
                      className="download-file-name"
                      onClick={() => {
                        window.open(standNote?.attachments[0]?.path, "_blank");
                      }}
                    >
                      <strong className="file-name">
                        {standNote?.attachments[0]?.original_name}
                      </strong>
                      <strong>.{standNote?.attachments[0]?.extension}</strong>
                    </Link>
                  </div>
                </div>
              </div>

              <span
                className="download-file-delete-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  _setMutationStandNoteAttachmentDelete({
                    variables: {
                      id: standNote?.attachments[0]?.id,
                    },
                  });
                }}
                style={{
                  marginLeft: "10px",
                  fontSize: "20px",
                  color: "var(--stormGray)",
                }}
              >
                {loadingMutationStandNoteAttachmentDelete ? (
                  <Loading3QuartersOutlined spin />
                ) : (
                  <Icons.Delete />
                )}
              </span>
            </div>
          ) : (
            !formItemValue?.length && (
              <Button type="text" icon={<Icons.Plus />}>
                <span className="ellipsis">
                  <Localize>GLOBAL.Button_Text_AddFile</Localize>
                </span>
              </Button>
            )
          )}
        </Upload>
      </Form.Item>

      <div className="form-btn-holder">
        {standNote?.id && (
          <EntityRemoveButton
            modalButton={
              <Button icon={<Icons.Delete />} className="light-bg">
                <span className="ellipsis">
                  <Localize>GLOBAL.Button_Text_DeleteNote</Localize>
                </span>
              </Button>
            }
            nameEntity={
              Localize({ children: "ENTITY.Modal_Title_Note" }).props.children
            }
            dataNameEntity={standNote?.description || ""}
            loading={loadingMutationStandNoteDelete}
            deleteMutation={_setMutationStandNoteDelete}
            variables={{
              id: standNote?.id,
            }}
          />
        )}
        <Button
          className="btn-right"
          type="primary"
          htmlType="submit"
          icon={standNote?.id ? <Icons.Edit /> : <Icons.Plus />}
          loading={loadingMutationStandNoteCreateUpdate}
        >
          <span className="ellipsis">
            {standNote?.id ? (
              <Localize>GLOBAL.Button_Text_Edit</Localize>
            ) : (
              <Localize>GLOBAL.Button_Text_Add</Localize>
            )}
          </span>
        </Button>
      </div>
    </Form>
  );
};

export default StandNoteEditForm;
