import React from "react";
import { Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import Stand from "components/stand";
import { EntityRemoveButton, Localize } from "components/service";
import Icons from "components/icons";

const StandFlooringActionField = ({ flooring, standTypeGroup, variables }) => {
  const { _setMutationFlooringRemove, loadingMutationFlooringRemove } =
    Stand.Hooks.flooringRemove(standTypeGroup);

  const { _setStandTypeFlooringUpdate } = Stand.Hooks.flooringUpdate(variables);

  const items = [
    {
      label: <Localize>MENUS_DROP.Label_MakeDefault</Localize>,
      key: "1",
      icon: <Icons.Bookmark />,
      onClick: () => {
        _setStandTypeFlooringUpdate({
          variables: {
            stand_type_group: standTypeGroup,
            flooring_id: flooring?.id,
          },
        });
      },
    },
    {
      key: "2",
      icon: (
        <EntityRemoveButton
          modalButton={
            <span style={{ display: "flex", alignItems: "center", width: 100  }}>
              <Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize>
            </span>
          }
          nameEntity={
            Localize({ children: "ENTITY.Modal_Title_Flooring" }).props.children
          }
          dataNameEntity={flooring?.title}
          loading={loadingMutationFlooringRemove}
          deleteMutation={_setMutationFlooringRemove}
          variables={{
            flooring_id: flooring?.id,
            stand_type_group: standTypeGroup,
          }}
        />
      ),
    },
  ];

  return (
    <Dropdown
      // trigger={['click']}
      placement="bottomRight"
      menu={{ items }}
      overlayClassName="drop-more-menu"
      arrow
    >
      <EllipsisOutlined className="btn-more" />
    </Dropdown>
  );
};

export default StandFlooringActionField;
