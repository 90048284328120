import React from "react";
import { Link } from "react-router-dom";
import EquipmentConst from "../equipment-const";

const EquipmentInfoField = ({ equipment, lang }) => (
  <Link
    className="table-link-underline"
    to={`${EquipmentConst.basePath}/edit/${equipment?.group_id}/${lang}`}
  >
    {`${equipment?.title}`}
  </Link>
);

export default EquipmentInfoField;
