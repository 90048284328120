import { useMutation } from "@apollo/client";
import { EQUIPMENT_VARIATION_DELETE } from "graphql/mutation/equipment-gql";

import {
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationVariationDelete = (id) => {
  const [_setMutationVariationDelete, { loading }] = useMutation(
    EQUIPMENT_VARIATION_DELETE,
    {
      update(cache, { data }) {
        const {
          equipmentVariationDelete: { label, message },
        } = data;

        cache.evict({
          id: cache.identify({ id, __typename: "EquipmentVariation" }),
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message,
        });
      },
      onError(error) {
        errorNotification(error);
      },
    }
  );

  return {
    _setMutationVariationDelete,
    loadingMutationVariationDelete: loading,
  };
};

export default useMutationVariationDelete;
