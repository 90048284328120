import { useHistory } from 'react-router-dom';
import { useMutation } from "@apollo/client";
import { TEAM_DELETE } from "graphql/mutation/team-gql";
import TeamConst from '../team-const';
import { successNotification, errorNotification } from "components/request-result";


const useMutationTeamDelete = (id) => {


  const history = useHistory();

  const [ _setMutationTeamDelete, { loading } ] = useMutation(TEAM_DELETE,
    {
      update(cache, { data }) {

        const {
          teamDelete: {
            label,
            message
          }
        } = data;

        history.push(TeamConst.basePath);

        cache.evict({
          id: cache.identify({ id, __typename: "Team" })
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message
        });
      },
      onError(error) {
        errorNotification(error);
      }
    });

  return {
    _setMutationTeamDelete,
    loadingMutationTeamDelete: loading,
  }
};

export default useMutationTeamDelete;