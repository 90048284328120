import { Localize } from "components/service";
import React from "react";
import EquipmentConst from "../equipment-const";

const EquipmentColorField = ({ equipment, disabledColor }) => {
  const { colorHexList } = EquipmentConst;

  return (
    <div className="d-flex align-items-center">
      {!disabledColor ? (
        <div
          style={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            marginRight: "7px",
            background: colorHexList[equipment?.color],
          }}
        ></div>
      ) : null}

      <Localize>{equipment?.color}</Localize>
    </div>
  );
};

export default EquipmentColorField;
