import React, { useState, useEffect } from "react";
import { Input, Button, Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { gqlBuilderWhere, helperFunc } from "utils";
import StandForms from "../../forms";
import Icons from "components/icons";
import { DrawerStandart, Localize } from "components/service";

const { Search } = Input;

const StandTableFilter = ({
  setFilters = () => {},
  setFiltersExtra = () => {},
  setFiltersMore = () => {},
  setResetSorting = () => {},
  stand,
  defaultLang,
  setSearchText,
  filters,
  filtersExtra,
  filtersMore,
  standFilters,
  standFiltersExtra,
  standFiltersMore,
  objOrderBy,
}) => {
  const [form] = Form.useForm();

  const [filterList, setFilterList] = useState(null);
  const [filterListExtra, setFilterListExtra] = useState(null);
  const [filterListMore, setFilterListMore] = useState(null);
  const [count, setCount] = useState();

  let filterCount = helperFunc.countFilterList(count);

  /*eslint-disable */
  useEffect(() => {
    if (filters) {
      localStorage.setItem(
        `standFilters_groupID-${stand?.group_id || 0}`,
        JSON.stringify({ ...standFilters, ...filterList })
      );
    } else form.resetFields(["COMPLEXITY", "MIN_PRICE", "MAX_PRICE"]);

    if (filtersExtra) {
      localStorage.setItem(
        `standFiltersExtra_groupID-${stand?.group_id || 0}`,
        JSON.stringify({ ...standFiltersExtra, ...filterListExtra })
      );
    } else form.resetFields(["ORIENTATION_KEY"]);

    if (filtersMore) {
      localStorage.setItem(
        `standFiltersMore_groupID-${stand?.group_id || 0}`,
        JSON.stringify({ ...standFiltersMore, ...filterListMore })
      );
    } else form.resetFields(["ORGANIZER_ID"]);
  }, [filters, filtersExtra, filtersMore]);

  useEffect(() => {
    if (standFilters) {
      setFilters(gqlBuilderWhere(standFilters));
    }

    if (standFiltersExtra) {
      setFiltersExtra(gqlBuilderWhere(standFiltersExtra));
    }

    if (standFiltersMore) {
      setFiltersMore(gqlBuilderWhere(standFiltersMore));
    }

    setCount({ ...standFilters, ...standFiltersExtra, ...standFiltersMore });
  }, []);
  /*eslint-enable */

  return (
    <>
      <Search
        className="filter-search"
        prefix={<SearchOutlined />}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <Form
        key="filters-form"
        className="clear-css-prop table-filter-form"
        layout="vertical"
        form={form}
      >
        <DrawerStandart
          extraClass={"modal-form filters"}
          modalButton={
            <Button icon={<Icons.Filter />}>
              <span className="ellipsis">
                <Localize>FORMS.Button_Text_Filters</Localize>
              </span>
              {filterCount && (
                <span className="filter-count">{filterCount}</span>
              )}
            </Button>
          }
        >
          <strong className="modal-title">
            <span className="text">
              <Localize>FORMS.Title_Text_Filters</Localize>
            </span>
          </strong>

          <StandForms.Filter
            {...{
              defaultLang,
              setCount,
              setFilters,
              setFiltersExtra,
              setFiltersMore,
              setFilterList,
              setFilterListExtra,
              setFilterListMore,
              filterList,
              filterListExtra,
              filterListMore,
              standFilters,
              standFiltersExtra,
              standFiltersMore,
              gqlBuilderWhere,
              form,
            }}
          />
        </DrawerStandart>

        {(filters || filtersExtra || filtersMore) && (
          <Button
            className="underline"
            onClick={() => {
              setCount({});
              setFilterList({});
              setFilterListExtra({});
              setFilterListMore({});
              setFilters(gqlBuilderWhere({}));
              setFiltersExtra(gqlBuilderWhere({}));
              setFiltersMore(gqlBuilderWhere({}));
              setResetSorting(objOrderBy);
              localStorage.removeItem(
                `standFilters_groupID-${stand?.group_id || 0}`
              );
              localStorage.removeItem(
                `standFiltersExtra_groupID-${stand?.group_id || 0}`
              );
              localStorage.removeItem(
                `standFiltersMore_groupID-${stand?.group_id || 0}`
              );
              localStorage.removeItem(
                `standsSorting_groupID-${stand?.group_id || 0}`
              );
            }}
          >
            <span>
              <Localize>FORMS.Button_Text_Reset</Localize>
            </span>
          </Button>
        )}
      </Form>
    </>
  );
};

export default StandTableFilter;
