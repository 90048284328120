import React from "react";
import GraphicRuleForms from "../forms";
import { Localize, ModalStandard } from "components/service";

const GraphicRuleInfoField = ({ graphic, graphSupplierID, variables }) => (
  <ModalStandard
    width={550}
    extraClass={"modal-form"}
    modalButton={
      <strong style={{ cursor: "pointer" }}>{graphic?.title}</strong>
    }
  >
    <strong className="modal-title">
      <span className="text">
        <Localize>FORMS.Title_EditGraphicRule</Localize>
      </span>
    </strong>

    <GraphicRuleForms.RuleEdit
      graphic={graphic}
      graphSupplierID={graphSupplierID}
      variables={variables}
    />
  </ModalStandard>
);

export default GraphicRuleInfoField;
