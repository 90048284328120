import React from "react";
import { useQuery } from "@apollo/client";
import { GET_TEAM } from "graphql/query/team-gql";
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import Team from "components/team";
import TeamRoute from 'components/routes/team/team-route';
import { Localize } from "components/service";


const TeamPage = ({ match, history }) => {

    useActiveMenuItem([ "teams" ], [ "teams" ]);


    const basePath = Team.Const.basePath;
    const path = history.location.pathname.split('/').pop()
    const teamID = match ? parseInt(match.params.id) : null;

    const { data: { team } = {}, loading } = useQuery(GET_TEAM, {
        skip: !teamID,
        variables: {
            id: teamID
        }
    });

    const pageNavbar = [
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Overview" }).props.children,
            path: ""
        },
    ];


    return (
        <PageWrapDefault
            className="page-team"
            loading={ loading }
            title={ path === 'create' ? <Localize>PAGES.Title_CreateTeam</Localize> : !loading && team ? `${ team.title }` : 'Page 404' }
            dataExist={ path === 'create' ? true : !loading && team }
            pageNavbar={ !loading && team ? pageNavbar : false }
            staticPath={ `${ basePath }/${ teamID }` }
        >
            <TeamRoute
                team={ team }
                history={ history }
                loading={ loading } />

        </PageWrapDefault>
    );
};

export default TeamPage;