import React, { useState, useEffect } from "react";
import { Input, Button, Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { gqlBuilderWhere, helperFunc } from "utils";
import EquipmentForms from "../../forms";
import Icons from "components/icons";
import { DrawerStandart, Localize } from "components/service";

const { Search } = Input;

const SupplierTableFilter = ({
  setFilters = () => {},
  setResetSorting = () => {},
  supplierFilters,
  filters,
  setSearchText,
  objOrderBy,
}) => {
  const [form] = Form.useForm();

  const [filterList, setFilterList] = useState(null);
  const [count, setCount] = useState();

  let filterCount = helperFunc.countFilterList(count);

  /*eslint-disable */
  useEffect(() => {
    if (filters) {
      localStorage.setItem(
        "supplierFilters",
        JSON.stringify({ ...supplierFilters, ...filterList })
      );
    } else form.resetFields(["TYPE"]);
  }, [filters]);

  useEffect(() => {
    if (supplierFilters) {
      setFilters(gqlBuilderWhere(supplierFilters));
    }

    setCount({
      ...supplierFilters,
    });
  }, []);
  /*eslint-enable */

  return (
    <>
      <Search
        className="filter-search"
        prefix={<SearchOutlined />}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <Form
        key="filters-form"
        className="clear-css-prop table-filter-form"
        layout="vertical"
        form={form}
      >
        <DrawerStandart
          extraClass={"modal-form filters"}
          modalButton={
            <Button icon={<Icons.Filter />}>
              <span className="ellipsis">
                <Localize>FORMS.Button_Text_Filters</Localize>
              </span>
              {filterCount && (
                <span className="filter-count">{filterCount}</span>
              )}
            </Button>
          }
        >
          <strong className="modal-title">
            <span className="text">
              <Localize>FORMS.Title_Text_Filters</Localize>
            </span>
          </strong>

          <EquipmentForms.Filter
            {...{
              setCount,
              setFilters,
              setFilterList,
              filterList,
              supplierFilters,
              gqlBuilderWhere,
              form,
            }}
          />
        </DrawerStandart>

        {filters && (
          <Button
            className="underline"
            onClick={() => {
              setCount({});
              setFilterList({});
              setFilters(gqlBuilderWhere({}));
              setResetSorting(objOrderBy);
              localStorage.removeItem("supplierFilters");
              localStorage.removeItem("suppliersSorting");
            }}
          >
            <span>
              <Localize>FORMS.Button_Text_Reset</Localize>
            </span>
          </Button>
        )}
      </Form>
    </>
  );
};

export default SupplierTableFilter;
