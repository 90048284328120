import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { breadCrumbsVar } from "graphql/cache";
import { useReactiveVar } from "@apollo/client";

import "./breadcrumbs.scss";

const Breadcrumbs = () => {
  const breadcrumbs = useReactiveVar(breadCrumbsVar);

  const items =
    breadcrumbs.length &&
    breadcrumbs.map((item) => ({
      title: <Link to={item.path}>{item.label}</Link>,
    }));

  return <Breadcrumb items={items} className="main-breadcrumb" />;
};

export default Breadcrumbs;
