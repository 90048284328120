import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_EQUIPMENT_GROUP } from "graphql/query/equipment-gql";
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import Equipment from "components/equipment";
import EquipmentRoute from "components/routes/equipment/equipment-route";
import { Localize } from "components/service";

const EquipmentPage = ({ match }) => {
  useActiveMenuItem(["equipment"], ["equipment"]);

  const history = useHistory();
  const basePath = Equipment.Const.basePath;
  const equipmentID = match ? parseInt(match.params.group_id) : null;
  const { lang } = useParams();

  const containsCreate = (url) => url.includes("create");
  const conditionCreate = containsCreate(history.location.pathname);

  const { data: { equipmentsGroup } = {}, loading } = useQuery(
    GET_EQUIPMENT_GROUP,
    {
      skip: !equipmentID,
      variables: {
        group_id: equipmentID,
      },
      fetchPolicy: "cache-and-network",
      // nextFetchPolicy: "cache-first",
    }
  );

  const equipment = equipmentsGroup?.filter((item) => item?.lang === lang)[0];

  const pageNavbar = [
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_Overview" }).props
        .children,
      path: "",
    },
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_Variations" }).props
        .children,
      path: "variations",
    },
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_Substitude" }).props
        .children,
      path: "substitude",
    },
  ];

  return (
    <PageWrapDefault
      className="page-equipment"
      loading={loading}
      title={
        conditionCreate ? (
          <Localize>PAGES.Title_CreateEquipment</Localize>
        ) : !loading && equipment ? (
          equipment.title ? (
            equipment.title
          ) : (
            <Localize>VARIABLE.Label_EMPTY</Localize>
          )
        ) : (
          "Page 404"
        )
      }
      dataExist={conditionCreate ? true : !loading && equipment}
      pageNavbar={!loading && equipment ? pageNavbar : false}
      staticPath={`${basePath}/edit/${equipmentID}/${lang}`}
    >
      <EquipmentRoute
        equipment={equipment}
        equipmentsGroup={equipmentsGroup}
        history={history}
        loading={loading}
        conditionCreate={conditionCreate}
        lang={lang}
      />
    </PageWrapDefault>
  );
};

export default EquipmentPage;
