import React, { useState, useEffect } from "react";
import { Form, Select, Input, InputNumber, Button, Avatar } from "antd";
import { FileImageOutlined } from "@ant-design/icons";

import { GET_VARIABLE_LISTS_CURSOR } from "graphql/query/variable-gql";

import { standUseMutation } from "../../hooks";
import StandHelpers from "../../helpers";
import {
  StandOrganizerForm,
  getInputFromValues,
} from "../stand-organizer-form";

import { FormElements } from "components/layout";
import { Localize, EntityRemoveButton } from "components/service";
import Variable from "components/variable";
import Icons from "components/icons";

const StandEditForm = ({
  stand,
  variables,
  conditionCreate,
  // disabledField,
  activeLang,
}) => {
  const [form] = Form.useForm();

  const [descriptionItems, setDescriptionItems] = useState([]);
  const [deletedDescriptionItems, setDeletedDescriptionItems] = useState([]);

  const generateUniqueId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  useEffect(() => {
    const metaFields = stand?.meta_fields?.map(
      ({ id, meta_key, meta_value, meta_group }) => ({
        id,
        meta_key,
        meta_value,
        meta_group,
      })
    );
    setDescriptionItems(metaFields || []);
  }, [stand]);

  useEffect(() => {
    form.setFieldsValue({
      title: !conditionCreate ? stand?.title : "",
    });
  }, [stand, conditionCreate, form]);

  const handleAddDescription = () => {
    const newDescriptionItem = {
      id: generateUniqueId(),
      meta_key: "",
      description: "",
    };

    setDescriptionItems([...descriptionItems, newDescriptionItem]);
  };

  const handleRemoveDescription = (id) => {
    let removedItem = descriptionItems.find((item) => item.id === id);
    if (removedItem && removedItem.id && removedItem.meta_key !== "") {
      setDeletedDescriptionItems([
        ...deletedDescriptionItems,
        { ...removedItem, action: "delete" },
      ]);
    }
    const updatedItems = descriptionItems.filter((item) => item.id !== id);
    setDescriptionItems(updatedItems);
  };

  function extractMeta(data) {
    const metaList = descriptionItems
      .filter((item) => !item.action || item.action !== "delete")
      .map((item) => ({
        id: item.id,
        meta_key: data[`type_${item.id}`],
        meta_value: data[`description_${item.id}`],
        meta_group: "description",
      }));

    return metaList;
  }

  const validateSizeMin = (_, value) => {
    const sizeMaxValue = form.getFieldValue("size_max");
    if (value > sizeMaxValue) {
      return Promise.reject("Size should be less than or equal to Max size");
    }
    return Promise.resolve();
  };

  const validateSizeMax = (_, value) => {
    const sizeMinValue = form.getFieldValue("size_min");
    if (value < sizeMinValue) {
      return Promise.reject("Size should be greater than or equal to Min size");
    }
    return Promise.resolve();
  };

  const { _setStandCreateUpdate, loadingMutationStandCreateUpdate } =
    standUseMutation.createUpdate(stand?.group_id);

  const { _setMutationStandDelete, loadingMutationStandDelete } =
    standUseMutation.delete(stand?.group_id, variables);

  const selectedOrganizer = Form.useWatch("organizer_id", form);
  const defaultOrganizers = stand?.organizerExhibitionTypes || [];
  const [organizers, setOrganizers] = useState(defaultOrganizers);

  const {
    Functions: { sort },
  } = StandHelpers;

  return (
    <Form
      key="edit-stand-form"
      layout="vertical"
      form={form}
      className="model-form edit-stand-form"
      onFinish={(values) => {
        const metaValues = extractMeta(values);

        const updatedValues = { ...values };

        metaValues.push(...deletedDescriptionItems);

        descriptionItems.forEach((item) => {
          delete updatedValues[`type_${item.id}`];
          delete updatedValues[`description_${item.id}`];
        });

        delete updatedValues.organizer_id;

        const keysToDelete = Object.keys(updatedValues).filter((key) =>
          key.startsWith("et_id")
        );

        keysToDelete.forEach((key) => {
          delete updatedValues[key];
        });

        if (stand?.id) {
          _setStandCreateUpdate({
            variables: {
              stand_type_id: stand?.id,
              group_id: stand?.group_id,
              metaSetInput: metaValues,
              updateInput: {
                id: stand?.id,
                group_id: stand?.group_id,
                lang: activeLang,
                ...updatedValues,
              },
              syncOrganizer: getInputFromValues(values, organizers),
            },
          });
        } else {
          _setStandCreateUpdate({
            variables: {
              input: {
                lang: activeLang,
                ...updatedValues,
              },
            },
          });
        }
      }}
    >
      <Form.Item
        name="title"
        initialValue={stand?.title}
        label={<Localize>FORMS.Input_Label_NameOfArticle</Localize>}
        rules={[
          {
            required: true,
            message: <Localize>FORM_RULES.Required_StandName</Localize>,
          },
        ]}
      >
        <Input name="title" />
      </Form.Item>

      <div className="row-grid grid-gap-10 row-gap-0 col-md-2">
        <Form.Item
          name="navision_number"
          label={<Localize>FORMS.Input_Label_NavisionNumb</Localize>}
          initialValue={stand?.navision_number}
          rules={[
            {
              required: true,
              message: <Localize>FORM_RULES.Required_NavisionArticle</Localize>,
            },
          ]}
        >
          <Input name="navision_number" /*disabled={fieldDisabled}*/ />
        </Form.Item>

        <Form.Item
          name="complexity"
          label={<Localize>FORMS.Input_Label_Complexity</Localize>}
          initialValue={stand?.complexity || "1"}
        >
          <Select /*disabled={fieldDisabled}*/ suffixIcon={<Icons.Arrow />}>
            {Array.from({ length: 5 }, (_, i) => i + 1).map((value) => (
              <Select.Option key={value} value={String(value)}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <div className="row-grid grid-gap-10 row-gap-0 col-md-2">
          <Form.Item
            name="size_min"
            label={<Localize>FORMS.Input_Label_MinSizeSqm</Localize>}
            initialValue={stand?.size_min}
            rules={[
              {
                required: true,
                message: <Localize>FORM_RULES.Required_MinSizeSqm</Localize>,
              },
              {
                validator: validateSizeMin,
              },
            ]}
          >
            <InputNumber
              name="size_min"
              style={{ width: "100%" }}
              /*disabled={fieldDisabled}*/
            />
          </Form.Item>

          <Form.Item
            name="size_max"
            label={<Localize>FORMS.Input_Label_MaxSizeSqm</Localize>}
            initialValue={stand?.size_max}
            rules={[
              {
                required: true,
                message: <Localize>FORM_RULES.Required_MaxSizeSqm</Localize>,
              },
              {
                validator: validateSizeMax,
              },
            ]}
          >
            <InputNumber
              name="size_max"
              style={{ width: "100%" }}
              /*disabled={fieldDisabled}*/
            />
          </Form.Item>
        </div>

        <Form.Item
          name="price"
          label={<Localize>FORMS.Input_Label_PricePerSqm</Localize>}
          initialValue={stand?.price}
          rules={[
            {
              required: true,
              message: <Localize>FORM_RULES.Required_PricePerSqm</Localize>,
            },
          ]}
        >
          <InputNumber
            name="price"
            style={{ width: "100%" }}
            /*disabled={fieldDisabled}*/
          />
        </Form.Item>
      </div>

      <FormElements.CheckboxSelect
        form={form}
        name="orientation"
        model="variableListsCursor"
        query={GET_VARIABLE_LISTS_CURSOR}
        tagInfo={false}
        label="FORMS.Input_Label_Orientations"
        modalTitle="FORMS.Title_AddOrientations"
        modalButtonText="MODAL.Button_Text_AddOrientation"
        modelCursorLabel={["slug"]}
        modelCursorValue="slug"
        numberOfCol={"col-auto-150"}
        initialLang={activeLang}
        disableSearch={true}
        extraClass="searh-border-bottom-none"
        sort={true}
        sortByCustomOrder={sort}
        initialValue={
          stand?.orientations?.length
            ? stand.orientations.map(({ orientation_key, img_path }) => ({
                value: orientation_key,
                label: orientation_key,
                img_path: img_path,
              }))
            : false
        }
        objectWhere={{
          AND: [
            {
              column: "LANG",
              operator: "EQ",
              value: activeLang,
            },
            {
              column: "GROUP_NAME",
              operator: "EQ",
              value: "stand orientation",
            },
          ],
        }}
        jsx={(img_path) => (
          <div style={{ minWidth: "30px" }}>
            <Avatar
              style={{
                borderRadius: 0,
                border: "1px solid var(--page_colorBgLayoutInner)",
              }}
              shape="square"
              size={22}
              icon={
                img_path ? (
                  <img src={img_path} alt="avatar" />
                ) : (
                  <FileImageOutlined />
                )
              }
            />
          </div>
        )}
      />

      {stand?.id && (
        <>
          <StandOrganizerForm
            form={form}
            organizers={organizers}
            setOrganizers={setOrganizers}
            selectedOrganizer={selectedOrganizer}
          />

          <br />
          <h5 className="form-heading">
            <Localize>FORMS.Headig_Text_Description</Localize>
          </h5>
          <ol className="form-description-list">
            {descriptionItems.map((item) => {
              return (
                <li key={item.id}>
                  <div className="holder">
                    <Variable.Forms.Fields.VariablesSelect
                      disabled={!!item?.meta_key}
                      form={form}
                      initialValue={item.meta_key}
                      name={`type_${item.id}`}
                      label={false}
                      groupName="stand description header type"
                      activeLang={activeLang}
                    />

                    <Form.Item
                      initialValue={item.meta_value}
                      name={`description_${item.id}`}
                    >
                      <Input.TextArea
                        autoSize={{ minRows: 1, maxRows: 6 }}
                        style={{ fontWeight: 400 }}
                      />
                    </Form.Item>
                  </div>
                  <div
                    className="close-icon"
                    onClick={() => handleRemoveDescription(item.id)}
                  >
                    <Icons.Close />
                  </div>
                </li>
              );
            })}
          </ol>
          <Button
            type="text"
            icon={<Icons.Plus />}
            onClick={handleAddDescription}
          >
            {descriptionItems.length ? (
              <span className="ellipsis">
                <Localize>GLOBAL.Button_Text_AddMore</Localize>
              </span>
            ) : (
              <span className="ellipsis">
                <Localize>GLOBAL.Button_Text_AddDescription</Localize>
              </span>
            )}
          </Button>
        </>
      )}

      <div className="form-btn-holder">
        {!conditionCreate && (
          <EntityRemoveButton
            modalButton={
              <Button icon={<Icons.Delete />}>
                <span className="ellipsis">
                  <Localize>GLOBAL.Button_Text_DeleteStand</Localize>
                </span>
              </Button>
            }
            nameEntity="Stand"
            dataNameEntity={`${stand?.title}`}
            loading={loadingMutationStandDelete}
            deleteMutation={_setMutationStandDelete}
            variables={{
              group_id: stand?.group_id,
            }}
          />
        )}
        <Button
          className="btn-right"
          type="primary"
          htmlType="submit"
          icon={!conditionCreate ? <Icons.Edit /> : <Icons.Plus />}
          loading={loadingMutationStandCreateUpdate}
        >
          <span className="ellipsis">
            {conditionCreate ? (
              <Localize>GLOBAL.Button_Text_Create</Localize>
            ) : stand?.title ? (
              <Localize>GLOBAL.Button_Text_SaveChanges</Localize>
            ) : (
              <Localize>GLOBAL.Button_Text_AddTranslation</Localize>
            )}
          </span>
        </Button>
      </div>
    </Form>
  );
};

export default StandEditForm;
