import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Pages } from "components/pages";

export default function FlooringsRoute() {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={ `${ path }` } exact>
                <Pages.Floorings />
            </Route>
            <Route path={ `${ path }/:id` } >
                { ({ match, history }) => <Pages.Flooring match={ match } history={ history } exact /> }
            </Route>
            <Route path={ `${ path }/create` } exact>
                <Pages.Flooring />
            </Route>
        </Switch>
    );
}

