import React, { useState } from "react";
import { Button, Input } from "antd";
import { useHistory } from "react-router-dom";

import { GET_ORGANIZERS_CURSOR } from "graphql/query/organizer-gql";

import Organizer from "components/organizer";
import Icons from "components/icons";
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import { Localize } from "components/service";

import "./organizer.scss";

const { Search } = Input;

const basePath = Organizer.Const.basePath;
const tableHelper = Organizer.Helpers.TableMain;

const OrganizersPage = () => {
  useActiveMenuItem(["organizers"], ["organizers"]);

  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_Organizers" }).props
        .children,
      path: `${basePath}`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const pageNavbar = [
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_Organizers" }).props
        .children,
      path: "organizers",
    },
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_ExhibitionTypes" }).props
        .children,
      path: "exhibition-types",
    },
  ];

  const history = useHistory();
  const [searchText, setSearchText] = useState("");

  return (
    <PageWrapDefault
      className="page-organizers"
      dataExist={true}
      title={<Localize>PAGES.Title_Organizers</Localize>}
      pageNavbar={pageNavbar}
      staticPath=""
    >
      <Tables.Cursor
        model="organizersCursor"
        query={GET_ORGANIZERS_CURSOR}
        varSet={{ perPage: 50 }}
        routeUrl={`${basePath}`}
        searchText={searchText}
        tableHelper={tableHelper}
      >
        <div className="table-action-bar">
          <div className="col">
            <Search
              className="filter-search"
              prefix={<Icons.Search />}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>

          <div className="col">
            <Button
              type="primary"
              icon={<Icons.Plus />}
              onClick={() => history.push(`${basePath}/create`)}
            >
              <span className="ellipsis">
                <Localize>GLOBAL.Button_Text_CreateNew</Localize>
              </span>
            </Button>
          </div>
        </div>
      </Tables.Cursor>
    </PageWrapDefault>
  );
};

export default OrganizersPage;
