import { useState } from "react";
import { Button } from "antd";
import { useApolloClient } from "@apollo/client";
import { GET_STAND_TYPE_EQUIPMENTS_CURSOR } from "graphql/query/stand-gql";
import { GET_ME } from "graphql/query/user-gql";
import { useBreadCrumbs } from "components/use-hooks";
import { DrawerStandart, Localize } from "components/service";
import { Tables } from "components/layout";
import Stand from "components/stand";
import Equipment from "components/equipment";
import Icons from "components/icons";

const StandEquipmentsSubPage = ({ stand, lang }) => {
  const basePath = Stand.Const.basePath;
  const tableHelper = Stand.Helpers.TableEquipments;
  const client = useApolloClient();
  const cachedMe = client.readQuery({
    query: GET_ME,
  });

  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_StandTypes" }).props
        .children,
      path: `${basePath}`,
    },
    {
      label: stand?.title ?? Localize({ children: "VARIABLE.Label_EMPTY" }),
      path: `${basePath}/edit/${stand?.group_id}/${lang}`,
    },
    {
      label: Localize({ children: "BREADCRUMBS.Label_Equipments" }).props
        .children,
      path: `${basePath}/edit/${stand?.group_id}/${lang}/equipments`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const [variables, setVariables] = useState("");

  return (
    <Tables.Cursor
      model="standTypeEquipmentsCursor"
      query={GET_STAND_TYPE_EQUIPMENTS_CURSOR}
      tableHelper={tableHelper}
      language={cachedMe?.me?.lang_app}
      currentObj={stand}
      setVariables={setVariables}
      objectWhere={{
        column: "STAND_TYPE_GROUP",
        operator: "EQ",
        value: parseInt(stand?.group_id),
      }}
    >
      <div className="table-action-bar">
        <div className="col"></div>

        <div className="col">
          <DrawerStandart
            extraClass={"modal-form filters"}
            modalButton={
              <Button type="primary" icon={<Icons.Plus />}>
                <span className="ellipsis">
                  <Localize>GLOBAL.Button_Text_AddNew</Localize>
                </span>
              </Button>
            }
          >
            <strong className="modal-title">
              <span className="text">
                <Localize>FORMS.Title_Text_AddEquipmentRule</Localize>
              </span>
            </strong>

            <Equipment.Forms.AddRule
              stand={stand}
              lang={cachedMe?.me?.lang_app}
              variables={variables}
            />
          </DrawerStandart>
        </div>
      </div>
    </Tables.Cursor>
  );
};

export default StandEquipmentsSubPage;
