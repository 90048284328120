import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { EXHIBITION_DELETE } from "graphql/mutation/exhibition-gql";
import ExhibitionConst from "../exhibition-const";
import Organizer from "components/organizer";
import {
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationExhibitionDelete = (id, organizer) => {
  const history = useHistory();

  const [_setMutationExhibitionDelete, { loading }] = useMutation(
    EXHIBITION_DELETE,
    {
      update(cache, { data }) {
        const {
          exhibitionTypeDelete: { label, message },
        } = data;

        !organizer
          ? history.push(ExhibitionConst.basePath)
          : history.push(
              `${Organizer.Const.basePath}/${organizer.id}${ExhibitionConst.basePath}`
            );

        cache.evict({
          id: cache.identify({ id, __typename: "ExhibitionType" }),
        });
        cache.gc();

        successNotification({
          title: label.toUpperCase(),
          description: message,
        });
      },
      onError(error) {
        errorNotification(error);
      },
    }
  );

  return {
    _setMutationExhibitionDelete,
    loadingMutationExhibitionDelete: loading,
  };
};

export default useMutationExhibitionDelete;
