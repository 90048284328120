import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Localize } from "components/service";
import ExhibitionConst from "../exhibition-const";

const ExhibitionInfoField = ({ exhibition }) => {
  const {
    location: { pathname },
  } = useHistory();

  const border = pathname.includes("/organizers/");

  useEffect(() => {
    const rows = document.querySelectorAll(".ant-table-row");

    rows.forEach((row) => {
      const defaultElement = row.querySelector(".badge");

      if (defaultElement && border) {
        row.classList.add("with-border");
      } else {
        row.classList.remove("with-border");
      }
    });
  }, [exhibition, border]);
  return (
    <div className="d-flex align-items-center">
      <Link
        className="table-link-underline"
        to={`${ExhibitionConst.basePath}/${exhibition?.id}`}
      >
        {exhibition?.title}
      </Link>

      {exhibition?.default && (
        <strong className="badge">
          <Localize>CARD.Info_List_Default</Localize>
        </strong>
      )}
    </div>
  );
};

export default ExhibitionInfoField;
