function langExists(defaultModel, language) {
  let relativesArray = defaultModel;

  if (defaultModel && defaultModel.relatives) {
    relativesArray = defaultModel.relatives;
  }

  if (!Array.isArray(relativesArray) || !language) {
    return [];
  }

  return relativesArray.filter(({ lang }) => lang === language);
}

export default langExists;

