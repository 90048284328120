import { useState } from "react";
import { Link } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import { Button, Input, Skeleton, Empty } from "antd";
import { FileOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { GET_STAND_TYPE_NOTES_CURSOR } from "graphql/query/stand-gql";
import { useBreadCrumbs, useDebounce } from "components/use-hooks";
import { ModalStandard, Localize, cursorPagination } from "components/service";
import { Loader } from "components/request-result";
import Stand from "components/stand";
import Icons from "components/icons";
import { timeFormat } from "utils/helper-functions";

const { Search } = Input;

const StandNotesSubPage = ({ stand, lang }) => {
  const basePath = Stand.Const.basePath;

  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_StandTypes" }).props
        .children,
      path: `${basePath}`,
    },
    {
      label: stand?.title ?? Localize({ children: "VARIABLE.Label_EMPTY" }),
      path: `${basePath}/edit/${stand?.group_id}/${lang}`,
    },
    {
      label: Localize({ children: "BREADCRUMBS.Label_Notes" }).props.children,
      path: `${basePath}/edit/${stand?.group_id}/${lang}/notes`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const [searchValue, setSearchValue] = useState("");

  const variables = {
    text: useDebounce(searchValue),
    first: 50,
    where: {
      column: "STAND_TYPE_GROUP",
      operator: "EQ",
      value: parseInt(stand?.group_id),
    },
  };

  let { data, loading, fetchMore } = useQuery(GET_STAND_TYPE_NOTES_CURSOR, {
    variables: { ...variables },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    notifyOnNetworkStatusChange: true,
  });

  const { fetchMoreAction, pageInfo } = cursorPagination({
    model: "standTypeNotesCursor",
    data,
    loading,
    fetchMore,
  });

  const EmptyData = () => (
    <div className="no-data-box">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      <span className="no-data-text">
        <Localize>GLOBAL.Text_NoData</Localize>
      </span>
    </div>
  );

  return (
    <>
      <div className="table-action-bar">
        <div className="col">
          <Search
            className="filter-search"
            prefix={<Icons.Search />}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>

        <div className="col">
          <ModalStandard
            width={500}
            extraClass={"modal-form"}
            modalButton={
              <Button type="primary" icon={<Icons.Plus />}>
                <span className="ellipsis">
                  <Localize>GLOBAL.Button_Text_AddNote</Localize>
                </span>
              </Button>
            }
          >
            <strong className="modal-title">
              <span className="text">
                <Localize>FORMS.Title_AddNote</Localize>
              </span>
            </strong>
            <Stand.Forms.EditNote stand={stand} variables={variables} />
          </ModalStandard>
        </div>
      </div>

      {loading ? (
        <Skeleton active paragraph={{ rows: 6 }} />
      ) : data?.standTypeNotesCursor?.edges?.length ? (
        <div className="row-grid col-lg-2">
          <ul className="notes-list">
            {data?.standTypeNotesCursor?.edges?.map(({ node }) => {
              return (
                <li key={node.id} className="note">
                  <div className="date">
                    <span>{timeFormat(node?.created_at, "DD.MM.YYYY")}</span>{" "}
                    <br />
                    <span>{timeFormat(node?.created_at, "HH:mm")}</span>
                  </div>

                  <div className="content">
                    <h5>
                      {node?.user?.name} {node?.user?.surname}
                    </h5>
                    <p>{node?.description}</p>

                    {node?.attachments.length ? (
                      <div className="file">
                        <FileOutlined />
                        <Link
                          className="file-link "
                          to="#"
                          onClick={() => {
                            window.open(node?.attachments[0].path, "_blank");
                          }}
                        >
                          <span>
                            {node?.attachments[0]?.original_name}.
                            {node?.attachments[0]?.extension}
                          </span>
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="d-flex justify-content-end">
                    <Stand.Fields.NoteAction
                      stand={stand}
                      standNote={node}
                      variables={variables}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <EmptyData />
      )}

      {pageInfo && pageInfo.hasNextPage && (
        <>
          {loading && (
            <div className="d-flex justify-content-center">
              <Loader style={{ marginTop: "-30px" }} />
            </div>
          )}
          <Waypoint onEnter={() => fetchMoreAction()} />
        </>
      )}
    </>
  );
};

export default StandNotesSubPage;
