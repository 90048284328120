import StandFields from "../fields";
import { Localize } from "components/service";

const standFilesColumns = [
  {
    title: <Localize>TABLES.Column_Title_FileName</Localize>,
    dataIndex: "original_name",
    columnIndex: "ORIGINAL_NAME",
    // sorter: true
  },
  {
    title: <Localize>TABLES.Column_Title_Title</Localize>,
    dataIndex: "title",
    columnIndex: "TITLE",
    sorter: true,
  },
  {
    title: <Localize>TABLES.Column_Title_Description</Localize>,
    dataIndex: "description",
    columnIndex: "DESCRIPTION",
    sorter: true,
  },

  {
    // title: '',
    dataIndex: "action",
    align: "right",
    width: "5%",
  },
];

const StandFilesData = (standFiles, currentObj, variables, language) => {
  if (!standFiles) {
    return [];
  }

  return standFiles?.map((standFiles) => {
    return {
      key: standFiles?.id,
      original_name: <StandFields.FileAttachment attachment={standFiles} />,
      title: standFiles?.title ?? <strong>&mdash;</strong>,
      description: standFiles?.description ?? <strong>&mdash;</strong>,

      action: (
        <StandFields.FilesAction
          stand={currentObj}
          standFile={standFiles}
          variables={variables}
        />
      ),
    };
  });
};

const StandFilesTableHelper = {
  columns: standFilesColumns,
  data: StandFilesData,
};

export default StandFilesTableHelper;
