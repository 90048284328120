import { useState } from "react";
import { Avatar, Modal } from "antd";
import { FileImageOutlined } from "@ant-design/icons";
import Icons from "components/icons";

const OrganizerImageField = ({ organizer }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleImageClick = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  return (
    <>
      <Avatar
        shape="square"
        onClick={handleImageClick}
        style={{
          width: "134px",
          height: "46px",
          cursor: organizer.logo_path && "pointer",
        }}
        icon={
          organizer?.logo_path ? (
            <img
              src={organizer.logo_path}
              style={{ objectFit: "contain" }}
              alt="img"
            />
          ) : (
            <div className="d-flex align-items-center justify-content-center h-100">
              <FileImageOutlined />
            </div>
          )
        }
      />

      {organizer?.logo_path && (
        <Modal
          className="modal-image"
          width={400}
          open={modalVisible}
          onCancel={handleCloseModal}
          closeIcon={<Icons.Close />}
          footer={null}
          centered
        >
          <img src={organizer?.logo_path} alt="img" style={{ width: "100%" }} />
        </Modal>
      )}
    </>
  );
};

export default OrganizerImageField;
