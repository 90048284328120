import React from "react";
import { useQuery } from "@apollo/client";
import { GET_ORGANIZER } from "graphql/query/organizer-gql";
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import OrganizerRoute from "components/routes/organizer/organizer-route";
import { Localize } from "components/service";

const OrganizerPage = ({ match, history }) => {
  useActiveMenuItem(["organizers"], ["organizers"]);

  const path = history.location.pathname.split("/").pop();

  const organizerID = match ? parseInt(match.params.id) : null;

  const { data: { organizer } = {}, loading } = useQuery(GET_ORGANIZER, {
    skip: !organizerID,
    variables: {
      id: organizerID,
    },
  });

  const pageNavbar = [
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_Overview" }).props
        .children,
      path: "",
    },
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_ExhibitionTypes" }).props
        .children,
      path: "exhibition-types",
    },
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_StandTypes" }).props
        .children,
      path: "stand-types",
    },
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_Equipment" }).props
        .children,
      path: "equipment",
    },
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_Flooring" }).props
        .children,
      path: "flooring",
    },
  ];

  const getPageTitle = () => {
    if (history.location.pathname.includes("organizers/create")) {
      return <Localize>PAGES.Title_CreateOrganizer</Localize>;
    } else if (history.location.pathname.includes("exhibition-types/create")) {
      return <Localize>PAGES.Title_CreateExhibition</Localize>;
    } else if (!loading && organizer) {
      return organizer?.title;
    } else {
      return "Page 404";
    }
  };

  return (
    <PageWrapDefault
      className="page-organizer"
      loading={loading}
      title={getPageTitle()}
      dataExist={path === "create" ? true : !loading && organizer}
      pageNavbar={
        !loading &&
        organizer &&
        !history.location.pathname.includes("exhibition-types/create")
          ? pageNavbar
          : false
      }
      staticPath={`/organizers/${organizerID}`}
    >
      <OrganizerRoute
        organizer={organizer}
        history={history}
        loading={loading}
      />
    </PageWrapDefault>
  );
};

export default OrganizerPage;
