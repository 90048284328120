import React from "react";
import { useHistory } from "react-router-dom";
import { Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { standUseMutation } from "../hooks";
import StandConst from "../stand-const";
import { EntityRemoveButton, Localize } from "components/service";
import Icons from "components/icons";

const StandActionField = ({ stand, variables, language }) => {
  const history = useHistory();

  const { _setMutationStandDelete, loadingMutationStandDelete } =
    standUseMutation.delete(stand?.group_id, variables);

  const items = [
    {
      label: <Localize>MENUS_DROP.Label_Edit</Localize>,
      key: "1",
      icon: <Icons.Edit />,
      onClick: () =>
        history.push(
          `${StandConst.basePath}/edit/${stand?.group_id}/${language}`
        ),
    },
    {
      key: "2",
      icon: (
        <EntityRemoveButton
          modalButton={
            <span style={{ display: "flex", alignItems: "center", width: 100 }}>
              <Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize>
            </span>
          }
          nameEntity={
            Localize({ children: "ENTITY.Modal_Title_Stand" }).props.children
          }
          dataNameEntity={stand?.title}
          loading={loadingMutationStandDelete}
          deleteMutation={_setMutationStandDelete}
          variables={{
            group_id: stand?.group_id,
          }}
        />
      ),
    },
  ];

  return (
    <Dropdown
      // trigger={['click']}
      placement="bottomRight"
      menu={{ items }}
      overlayClassName="drop-more-menu"
      arrow
    >
      <EllipsisOutlined className="btn-more" />
    </Dropdown>
  );
};

export default StandActionField;
