import React, { useState } from "react";
import { Button } from "antd";
import { useRouteMatch, useHistory } from "react-router-dom";

import { GET_USERS } from "graphql/query/user-gql";

import Users from "components/user";
import Icons from "components/icons";
import { useActiveMenuItem, useBreadCrumbs } from "components/use-hooks";
import { PageWrapDefault, Tables } from "components/layout";
import { Localize } from "components/service";
import { gqlBuilderWhere } from "utils";
import "./user.scss";

const basePath = Users.Const.basePath;
const tableHelper = Users.Helpers.TableMain;

const UsersPage = () => {
  useActiveMenuItem(["users"], ["users"]);

  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_Users" }).props.children,
      path: `${basePath}`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const history = useHistory();

  let match = useRouteMatch(`${basePath}/page/:pageNum`);

  const objOrderBy = [{ column: "ID", order: "DESC" }];

  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState();
  const [filtersExtra, setFiltersExtra] = useState();
  const [filtersMore, setFiltersMore] = useState();
  const [resetSorting, setResetSorting] = useState(false);

  const usersFilters = JSON.parse(localStorage.getItem("usersFilters"));
  const usersFiltersExtra = JSON.parse(
    localStorage.getItem("usersFiltersExtra")
  );
  const usersFiltersMore = JSON.parse(localStorage.getItem("usersFiltersMore"));
  const usersSorting = JSON.parse(localStorage.getItem("usersSorting"));

  const objectFilters = filters || gqlBuilderWhere(usersFilters);
  const objectFilterExtra = filtersExtra || gqlBuilderWhere(usersFiltersExtra);
  const objectFiterMore = filtersMore || gqlBuilderWhere(usersFiltersMore);

  const pageNavbar = [
    {
      label: Localize({ children: "NAVBAR_PAGES.Label_Users" }).props.children,
      path: "users",
    },

    {
      label: Localize({ children: "NAVBAR_PAGES.Label_UserRoles" }).props
        .children,
      path: "user-roles",
    },
  ];

  return (
    <PageWrapDefault
      className="page-users"
      dataExist={true}
      title={<Localize>PAGES.Title_Users</Localize>}
      pageNavbar={pageNavbar}
      staticPath=""
    >
      <Tables.Main
        model="users"
        query={GET_USERS}
        varSet={{
          perPage: 30,
          whereRoles: objectFilterExtra,
          whereTeams: objectFiterMore,
        }}
        routeUrl={`${basePath}`}
        currentPage={match ? parseInt(match.params.pageNum) : 1}
        searchText={searchText}
        objectWhere={objectFilters}
        resetPaginationPage={
          typeof objectFilters === "object" ||
          typeof objectFilterExtra === "object" ||
          typeof objectFiterMore === "object"
        }
        objOrderBy={usersSorting || objOrderBy}
        sortingName="usersSorting"
        tableHelper={tableHelper}
        resetSorting={resetSorting}
      >
        <div className="table-action-bar">
          <div className="col">
            <Users.Filters.Table
              {...{
                setSearchText,
                filters,
                filtersExtra,
                filtersMore,
                setFilters,
                setFiltersExtra,
                setFiltersMore,
                usersFilters,
                usersFiltersExtra,
                usersFiltersMore,
                setResetSorting,
                objOrderBy,
              }}
            />
          </div>

          <div className="col">
            <Button
              type="primary"
              icon={<Icons.Plus />}
              onClick={() => history.push(`${basePath}/create`)}
            >
              <span className="ellipsis">
                <Localize>GLOBAL.Button_Text_CreateNew</Localize>
              </span>
            </Button>
          </div>
        </div>
      </Tables.Main>
    </PageWrapDefault>
  );
};

export default UsersPage;
