import React, { useState } from "react";
import { Button, Input } from "antd";

import { GET_GRAPHIC_SUPPLIER_RULES_CURSOR } from "graphql/query/graphic-gql";

import Graphic from "components/graphic";
import Icons from "components/icons";
import { ModalStandard } from "components/service";
import { useBreadCrumbs } from "components/use-hooks";
import { Tables } from "components/layout";
import { Localize } from "components/service";

const { Search } = Input;

const graphicBasePath = Graphic.Const.basePath;
const tableHelper = Graphic.Helpers.TableRules;

const GraphicRulesSubPage = ({ graphic }) => {
  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_GraphicRules" }).props
        .children,
      path: `${graphicBasePath}`,
    },
    {
      label: graphic?.title,
      path: `${graphicBasePath}/${graphic?.id}`,
    },
    {
      label: Localize({ children: "BREADCRUMBS.Label_Rules" }).props.children,
      path: `${graphicBasePath}/${graphic?.id}/rules`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const [searchText, setSearchText] = useState("");
  const [variables, setVariables] = useState("");

  return (
    <Tables.Cursor
      model="graphicSupplierRulesCursor"
      query={GET_GRAPHIC_SUPPLIER_RULES_CURSOR}
      varSet={{
        perPage: 50,
      }}
      routeUrl={`${graphicBasePath}/${graphic?.id}/rules`}
      searchText={searchText}
      setVariables={setVariables}
      tableHelper={tableHelper}
      currentObj={graphic}
    >
      <div className="table-action-bar">
        <div className="col">
          <Search
            className="filter-search"
            prefix={<Icons.Search />}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>

        <div className="col">
          <ModalStandard
            width={600}
            extraClass={"modal-form"}
            modalButton={
              <Button type="primary" icon={<Icons.Plus />}>
                <span className="ellipsis">
                  <Localize>GLOBAL.Button_Text_CreateNew</Localize>
                </span>
              </Button>
            }
          >
            <strong className="modal-title">
              <span className="text">
                <Localize>FORMS.Title_CreateGraphicRule</Localize>
              </span>
            </strong>

            <Graphic.Forms.RuleEdit
              graphSupplierID={graphic?.id}
              variables={variables}
            />
          </ModalStandard>
        </div>
      </div>
    </Tables.Cursor>
  );
};

export default GraphicRulesSubPage;
