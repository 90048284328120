import React from "react";
import { Link } from 'react-router-dom';
import Supplier from "components/supplier";



const UserSupplierField = ({ user }) => (

    user?.supplier?.id
        ?
        <Link className="table-link-underline" to={ `${ Supplier.Const.basePath }/${ user?.supplier?.id }` }>
            { user?.supplier?.title }
        </Link>
        : <strong>&mdash;</strong>
);

export default UserSupplierField;