import React, { useState } from "react";
import { Form, Button, InputNumber, Select, Avatar } from "antd";
import { FileImageOutlined } from "@ant-design/icons";
import { GET_EQUIPMENT_CURSOR } from "graphql/query/equipment-gql";
import { GET_ORGANIZERS_CURSOR } from "graphql/query/organizer-gql";
import { Localize } from "components/service";

import { FormElements } from "components/layout";
import Stand from "components/stand";
import Icons from "components/icons";

const EquipmentAddRuleForm = ({
  stand,
  standEquipment,
  equipment,
  lang,
  variables,
  action,
}) => {
  const [form] = Form.useForm();
  const [submitCount, setSubmitCount] = useState(0);
  const fieldRuleType = Form.useWatch("rule_type", form);
  const {
    _setStandEquipmentCreateUpdate,
    loadingMutationStandEquipmentCreateUpdate,
  } = Stand.Hooks.equipmentCreateUpdate(standEquipment?.id, variables);


  return (
    <Form
      key={`add-rule-equipment-form-${submitCount}`}
      layout="vertical"
      className="add-rule-equipment-form model-form"
      form={form}
      onFinish={(values) => {
        _setStandEquipmentCreateUpdate({
          variables: {
            input: {
              id: standEquipment?.id ? +standEquipment.id : undefined,
              stand_type_group: stand?.group_id,
              ...values,
            },
          },
        });

        action();
        form.resetFields();
        setSubmitCount(submitCount + 1);
      }}
    >
      <div className="holder">
        <FormElements.RadioSelectDrawer
          form={form}
          query={GET_EQUIPMENT_CURSOR}
          model="equipmentsCursor"
          name="equipment_group"
          label="FORMS.Input_Label_EquipmentName"
          modalTitle="FORMS.Title_ChooseEquipment"
          location="drawer"
          extraClass="model-form multi-drawer"
          modelCursorLabel={["title", "navision_number"]}
          modelCursorValue="group_id"
          modelImagePath="getImages[0]?.sizes['200x200']"
          initialValue={
            equipment?.group_id
              ? [
                  {
                    value: equipment.group_id,
                    label: equipment.title,
                    img_path: equipment?.getImages[0]?.sizes["200x200"],
                  },
                ]
              : false
          }
          objectWhere={{ column: "LANG", operator: "EQ", value: lang }}
          jsx={(img_path) => (
            <Avatar
              style={{
                marginRight: 10,
                borderRadius: 4,
                border: "1px solid var(--lightGray);",
              }}
              shape="square"
              size={50}
              icon={
                img_path ? (
                  <img src={img_path} alt="avatar" />
                ) : (
                  <FileImageOutlined />
                )
              }
            />
          )}
        />

        <FormElements.RadioSelect
          form={form}
          query={GET_ORGANIZERS_CURSOR}
          model="organizersCursor"
          name="organizer_id"
          label="FORMS.Input_Label_Organizer"
          modalTitle="FORMS.Title_AddOrganizer"
          location="drawer"
          initialValue={
            standEquipment?.relOrganizer?.id
              ? [
                  {
                    value: standEquipment.relOrganizer.id,
                    label: standEquipment.relOrganizer.title,
                  },
                ]
              : false
          }
        />

        <Form.Item
          name="rule_type"
          label={<Localize>FORMS.Input_Label_StandRuleType</Localize>}
          initialValue={standEquipment?.rule_type}
          rules={[
            {
              required: true,
              message: <Localize>FORM_RULES.Required_RuleType</Localize>,
            },
          ]}
        >
          <Select
            showSearch
            suffixIcon={<Icons.Arrow />}
            popupClassName="select-serch-dropdown"
            optionFilterProp="value"
          >
            <Select.Option key="EQ" value="EQ">
              <Localize>STANDTYPE.Equipment_Rule_EQ</Localize>
            </Select.Option>
            <Select.Option key="GTE" value="GTE">
              <Localize>STANDTYPE.Equipment_Rule_GTE</Localize>
            </Select.Option>
            <Select.Option key="LTE" value="LTE">
              <Localize>STANDTYPE.Equipment_Rule_LTE</Localize>
            </Select.Option>
            <Select.Option key="BETWEEN" value="BETWEEN">
              <Localize>STANDTYPE.Equipment_Rule_BETWEEN</Localize>
            </Select.Option>
          </Select>
        </Form.Item>

        {(fieldRuleType === "EQ" ||
          fieldRuleType === "GTE" ||
          fieldRuleType === "BETWEEN") && (
          <Form.Item
            name="size_min"
            initialValue={standEquipment?.size_min}
            label={<Localize>FORMS.Input_Label_StandSizeMinSqm</Localize>}
            rules={[
              {
                required: true,
                message: <Localize>FORM_RULES.Required_SizeMinMax</Localize>,
              },
            ]}
          >
            <InputNumber name="size_min" style={{ width: "100%" }} />
          </Form.Item>
        )}

        {(fieldRuleType === "LTE" || fieldRuleType === "BETWEEN") && (
          <Form.Item
            name="size_max"
            initialValue={standEquipment?.size_max}
            label={<Localize>FORMS.Input_Label_StandSizeMaxSqm</Localize>}
            rules={[
              {
                required: true,
                message: <Localize>FORM_RULES.Required_SizeMinMax</Localize>,
              },
            ]}
          >
            <InputNumber name="size_max" style={{ width: "100%" }} />
          </Form.Item>
        )}

        <Form.Item
          name="amount"
          initialValue={standEquipment?.amount}
          label={<Localize>FORMS.Input_Label_Amount</Localize>}
          rules={[
            {
              required: true,
              message: <Localize>FORM_RULES.Required_Amount</Localize>,
            },
          ]}
        >
          <InputNumber name="amount" style={{ width: "100%" }} />
        </Form.Item>
      </div>

      <div className="form-btn-holder">
        <Button className="light-bg" onClick={action}>
          <Localize>GLOBAL.Button_Text_Cancel</Localize>
        </Button>

        <Button
          // disabled={true}
          className="btn-right"
          type="primary"
          htmlType="submit"
          loading={loadingMutationStandEquipmentCreateUpdate}
        >
          {!standEquipment?.id ? (
            <Localize>GLOBAL.Button_Text_AddRule</Localize>
          ) : (
            <Localize>GLOBAL.Button_Text_EditRule</Localize>
          )}
        </Button>
      </div>
    </Form>
  );
};

export default EquipmentAddRuleForm;
