import Organizer from "components/organizer";
import { useBreadCrumbs } from "components/use-hooks";
import { GET_ORGANIZER } from "graphql/query/organizer-gql";
import { Localize } from "components/service";
import { UploadLogo } from "components/layout";

const organizerBasePath = Organizer.Const.basePath;

const OrganizerOverviewSubPage = ({ organizer, history }) => {
  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_Organizers" }).props
        .children,
      path: `${organizerBasePath}`,
    },
    {
      label:
        (organizer && organizer?.title) ||
        Localize({ children: "BREADCRUMBS.Label_Create" }).props.children,
      path:
        (organizer && `${organizerBasePath}/${organizer?.id}`) ||
        `${organizerBasePath}/create`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  return (
    <div className="row-grid col-lg-2">
      <div className={`col-left ${organizer?.id && "col-border-right"} `}>
        <Organizer.Forms.Edit organizer={organizer} history={history} />
      </div>
      <div className="col-right">
        {organizer?.id && (
          <UploadLogo
            label="FORMS.Input_Label_Logo"
            uploadButtonText="GLOBAL.Text_AddLogo"
            uploadImgSize="MODAL.Button_Text_LogoSize"
            model="Organizer"
            modelId={organizer?.id}
            modelField="logo_path"
            defaultMedia={organizer?.logo_path}
            query={GET_ORGANIZER}
          />
        )}
      </div>
    </div>
  );
};

export default OrganizerOverviewSubPage;
