import { useMutation } from "@apollo/client";
import { GET_STAND_TYPE_FILES_CURSOR } from "graphql/query/stand-gql";
import {
  STAND_TYPE_FILE_CREATE,
  STAND_TYPE_FILE_UPDATE,
} from "graphql/mutation/stand-gql";

import {
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationStandFileCreateUpdate = ({ id, variables }) => {
  const STAND_TYPE_FILE_CREATE_UPDATE = !id
    ? STAND_TYPE_FILE_CREATE
    : STAND_TYPE_FILE_UPDATE;
  const standCreateUpdate = !id ? "standTypeFileCreate" : "standTypeFileUpdate";

  const shouldRefetchQueries =
    STAND_TYPE_FILE_CREATE_UPDATE === STAND_TYPE_FILE_UPDATE;

  const [_setStandFileCreateUpdate, { loading, data }] = useMutation(
    STAND_TYPE_FILE_CREATE_UPDATE,
    {
      update(cache, { data }) {
        if (id) {
          const {
            [standCreateUpdate]: { label, message },
          } = data;

          successNotification({
            title: label,
            description: message,
          });
        }
      },
      onError(error) {
        errorNotification(error);
      },
      refetchQueries: shouldRefetchQueries
        ? [
            {
              query: GET_STAND_TYPE_FILES_CURSOR,
              variables,
            },
          ]
        : [],
    }
  );

  return {
    _setStandFileCreateUpdate,
    loadingMutationStandFileCreateUpdate: loading,
    dataMutationStandFile: data,
  };
};

export default useMutationStandFileCreateUpdate;
