import React from "react";
import { Link } from "react-router-dom";
import Role from "components/role";
import { Localize } from "components/service";

const UserRoleField = ({ role }) => {
  return !!role.length ? (
    role.map((role, index) => {
      return (
        <div key={index} style={{ marginBottom: "3px" }}>
          <Link
            className="table-link-underline"
            style={{ color: role?.colorHex }}
            to={`${Role.Const.basePath}/${role?.id}`}
          >
            <strong>
              <Localize>{role?.title}</Localize>
            </strong>
          </Link>
          <br />
        </div>
      );
    })
  ) : (
    <strong>&mdash;</strong>
  );
};

export default UserRoleField;
