import { useApolloClient } from "@apollo/client";
import { GET_EQUIPMENT_GROUP } from "graphql/query/equipment-gql";
import { GET_ME } from "graphql/query/user-gql";
import Equipment from "components/equipment";
import { useBreadCrumbs } from "components/use-hooks";
import { Localize } from "components/service";
import { UploadMedia } from "components/layout";

const equipmentBasePath = Equipment.Const.basePath;

const EquipmentOverviewSubPage = ({
  equipment,
  equipmentsGroup,
  conditionCreate,
  lang,
}) => {
  const client = useApolloClient();
  const cachedMe = client.readQuery({
    query: GET_ME,
  });

  const defaultLang = cachedMe.me.lang_api;
  const activeLang = lang || defaultLang;

  const disabledField =
    equipment?.id !== equipment?.group_id ||
    (conditionCreate && activeLang !== defaultLang);

  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_Equipment" }).props
        .children,
      path: `${equipmentBasePath}`,
    },
    {
      label: equipment
        ? equipment?.title
        : Localize({ children: "BREADCRUMBS.Label_Create" }).props.children,
      path: equipment
        ? `${equipmentBasePath}/edit/${equipment?.group_id}/${lang}`
        : `${equipmentBasePath}/create/${lang}`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  return (
    <>
      <Equipment.Fields.Langs
        equipment={equipment}
        equipmentsGroup={equipmentsGroup}
        activeLang={activeLang}
        conditionCreate={conditionCreate}
        extraClass="form"
      />

      <div className="row-grid col-lg-2">
        <div className={`col-left ${equipment?.id && "col-border-right"} `}>
          <Equipment.Forms.Edit
            activeLang={activeLang}
            equipment={equipment}
            conditionCreate={conditionCreate}
            disabledField={disabledField}
          />
        </div>
        <div className="col-right">
          {equipment?.id && (
            <UploadMedia
              model="Equipment"
              identifier="group_id"
              modelId={equipment?.group_id}
              defaultMedia={equipment?.getImages}
              query={GET_EQUIPMENT_GROUP}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default EquipmentOverviewSubPage;
