import { Localize } from "components/service";
import React from "react";
import FlooringConst from "../flooring-const";

const FlooringArticleField = ({ flooring, disabledColor }) => {
  const { colorHexList } = FlooringConst;
  return (
    <div className="d-flex align-items-center">
      {!disabledColor ? (
        <div
          style={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            marginRight: "7px",
            background: colorHexList(flooring?.color),
          }}
        ></div>
      ) : null}

      <Localize>{flooring?.color}</Localize>
    </div>
  );
};

export default FlooringArticleField;
