import React from "react";
import { Link } from "react-router-dom";
import Organizer from "components/organizer";

const GraphicOrganizerField = ({ graphic }) =>
  graphic?.organizer?.id ? (
    <Link
      className="table-link-underline"
      to={`${Organizer.Const.basePath}/${graphic?.organizer?.id}`}
    >
      {graphic?.organizer?.title}
    </Link>
  ) : (
    <strong>&mdash;</strong>
  );

export default GraphicOrganizerField;
