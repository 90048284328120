import { useMutation } from "@apollo/client";
import { EQUIPMENT_REMOVE_SUBSTITUDE } from "graphql/mutation/equipment-gql";
import { GET_EQUIPMENT_CURSOR } from "graphql/query/equipment-gql";

import {
  successNotification,
  errorNotification,
} from "components/request-result";

const useMutationEquipmentRemove = (variables) => {
  const [_setMutationEquipmentRemove, { loading }] = useMutation(
    EQUIPMENT_REMOVE_SUBSTITUDE,
    {
      update(cache, { data }) {
        const {
          equipmentRemoveSubstitude: { label, message },
        } = data;

        successNotification({
          title: label.toUpperCase(),
          description: message,
        });
      },

      refetchQueries: [
        {
          query: GET_EQUIPMENT_CURSOR,
          variables,
        },
      ],

      onError(error) {
        errorNotification(error);
      },
    }
  );

  return {
    _setMutationEquipmentRemove,
    loadingMutationEquipmentRemove: loading,
  };
};

export default useMutationEquipmentRemove;
