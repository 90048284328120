import React, { useState } from "react";
import { Button, Input } from "antd";

import { GET_EXHIBITIONS_CURSOR } from "graphql/query/exhibition-gql";

import Exhibition from "components/exhibition";
import Organizer from "components/organizer";
import Icons from "components/icons";
import { useBreadCrumbs } from "components/use-hooks";
import { Tables } from "components/layout";
import { Localize } from "components/service";

const { Search } = Input;

const basePath = Exhibition.Const.basePath;
const organizerBasePath = Organizer.Const.basePath;
const tableHelper = Exhibition.Helpers.TableMain;

const OrganizerEhibitionSubPage = ({ organizer, history }) => {
  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_Organizers" }).props
        .children,
      path: `${organizerBasePath}`,
    },
    {
      label: organizer?.title,
      path: `${organizerBasePath}/${organizer?.id}`,
    },
    {
      label: Localize({ children: "BREADCRUMBS.Label_Exhibitions" }).props
        .children,
      path: `${organizerBasePath}/${organizer?.id}${basePath}`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const [searchText, setSearchText] = useState("");

  return (
    <Tables.Cursor
      model="exhibitionTypesCursor"
      query={GET_EXHIBITIONS_CURSOR}
      varSet={{ perPage: 50 }}
      routeUrl={`${basePath}`}
      searchText={searchText}
      tableHelper={tableHelper}
      currentObj={organizer}
      objectWhere={{
        column: "ORGANIZER_ID",
        operator: "EQ",
        value: organizer?.id,
      }}
    >
      <div className="table-action-bar">
        <div className="col">
          <Search
            className="filter-search"
            prefix={<Icons.Search />}
            onChange={(e) => setSearchText(e.target.value)}
          />

          <Button disabled icon={<Icons.Filter />}>
            <span className="ellipsis">
              <Localize>FORMS.Button_Text_Filters</Localize>
            </span>
          </Button>
        </div>

        <div className="col">
          <Button
            type="primary"
            icon={<Icons.Plus />}
            onClick={() =>
              history.push(
                `${organizerBasePath}/${organizer?.id}${basePath}/create`
              )
            }
          >
            <span className="ellipsis">
              <Localize>GLOBAL.Button_Text_CreateNew</Localize>
            </span>
          </Button>
        </div>
      </div>
    </Tables.Cursor>
  );
};

export default OrganizerEhibitionSubPage;
