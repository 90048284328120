import React from "react";
import { Form, Input, Button, Switch } from "antd";
import { GET_SUPPLIER_CURSOR } from "graphql/query/supplier-gql";

import { organizerUseMutation } from "../../hooks";
import { Localize, EntityRemoveButton } from "components/service";
import { FormElements } from "components/layout";
import Icons from "components/icons";

const OrganizerEditForm = ({ organizer }) => {
  const [form] = Form.useForm();

  const {
    _setOrganizerCreateUpdate,
    loadingMutationOrganizerCreateUpdate: loading,
  } = organizerUseMutation.createUpdate(organizer?.id);

  const { _setMutationOrganizerDelete, loadingMutationOrganizerDelete } =
    organizerUseMutation.delete(organizer?.id);

  const getInitialValueBySupplierGroup = (organizer, supplierGroup) => {
    if (!organizer) {
      return false;
    }

    const suppliersInGroup = organizer.suppliers.filter(
      (supplier) => supplier.supplier_group === supplierGroup
    );

    return suppliersInGroup.length > 0
      ? suppliersInGroup.map(({ supplier_id, supplier_title }) => ({
          value: supplier_id,
          label: supplier_title,
        }))
      : false;
  };

  const valueFieldStatus = Form.useWatch("status", form);

  return (
    <Form
      key="edit-organizer-form"
      layout="vertical"
      form={form}
      className="model-form edit-organizer-form"
      onFinish={(values) => {
        const selectedSuppliers = [];
        const groups = ["equipment", "print_house", "flooring"];
        const processGroup = (group) => {
          if (values[`suppliers-${group}`]) {
            selectedSuppliers.push(
              ...values[`suppliers-${group}`].map((supplier) => ({
                supplier_group: group,
                supplier_id: supplier,
              }))
            );
          }
        };

        groups.forEach(processGroup);

        _setOrganizerCreateUpdate({
          variables: {
            input: {
              id: organizer?.id ? +organizer.id : undefined,
              title: values.title,
              suppliers: selectedSuppliers,
              status:
                typeof values.status === "string"
                  ? organizer?.status
                  : values?.status === true
                  ? "active"
                  : "closed",
            },
          },
        });
      }}
    >
      <div className="switch-holder">
        <Form.Item
          name="status"
          label={<Localize>FORMS.Input_Label_Status</Localize>}
          initialValue={organizer?.status === "active" ? true : false}
          valuePropName="checked"
          className="form-item-switch"
        >
          <Switch />
        </Form.Item>
        {valueFieldStatus ? (
          <span
            style={{ color: "var(--switch_colorPrimary)" }}
            className="switch-status-text"
          >
            <Localize>GLOBAL.Text_Active</Localize>
          </span>
        ) : (
          <span
            style={{ color: "var(--switch_colorTextQuaternary)" }}
            className="switch-status-text"
          >
            <Localize>GLOBAL.Text_Blocked</Localize>
          </span>
        )}
      </div>

      <Form.Item
        name="title"
        label={<Localize>FORMS.Input_Label_OrganizerName</Localize>}
        initialValue={organizer?.title}
        rules={[
          {
            required: true,
            message: <Localize>FORM_RULES.Required_OrganizerName</Localize>,
          },
          {
            max: 128,
            message: <Localize>FORM_RULES.Required_MaxCharacter</Localize>,
          },
        ]}
      >
        <Input name="title" />
      </Form.Item>

      <FormElements.CheckboxSelect
        form={form}
        query={GET_SUPPLIER_CURSOR}
        model="suppliersCursor"
        name="suppliers-equipment"
        label="FORMS.Input_Label_EquipmentSuppliers"
        modalTitle="FORMS.Title_AddEquipmentSuppliers"
        modalButtonText="MODAL.Button_Text_AddSupplier"
        initialValue={getInitialValueBySupplierGroup(organizer, "equipment")}
      />

      <FormElements.CheckboxSelect
        form={form}
        query={GET_SUPPLIER_CURSOR}
        model="suppliersCursor"
        name="suppliers-print_house"
        label="FORMS.Input_Label_PrintHouseSuppliers"
        modalTitle="FORMS.Title_AddPrintHouseSuppliers"
        modalButtonText="MODAL.Button_Text_AddSupplier"
        initialValue={getInitialValueBySupplierGroup(organizer, "print_house")}
      />

      <FormElements.CheckboxSelect
        form={form}
        query={GET_SUPPLIER_CURSOR}
        model="suppliersCursor"
        name="suppliers-flooring"
        label="FORMS.Input_Label_FlooringSuppliers"
        modalTitle="FORMS.Title_AddFlooringSuppliers"
        modalButtonText="MODAL.Button_Text_AddSupplier"
        initialValue={getInitialValueBySupplierGroup(organizer, "flooring")}
      />

      <div className="form-btn-holder">
        {organizer?.id && (
          <EntityRemoveButton
            modalButton={
              <Button icon={<Icons.Delete />}>
                <span className="ellipsis">
                  <Localize>ORGANIZER.Button_Text_Delete</Localize>
                </span>
              </Button>
            }
            nameEntity={
              Localize({ children: "ENTITY.Modal_Title_Organizer" }).props
                .children
            }
            dataNameEntity={organizer?.title}
            loading={loadingMutationOrganizerDelete}
            deleteMutation={_setMutationOrganizerDelete}
            variables={{
              id: organizer?.id,
            }}
          />
        )}
        <Button
          className="btn-right"
          type="primary"
          htmlType="submit"
          icon={organizer?.id ? <Icons.Edit /> : <Icons.Plus />}
          loading={loading}
        >
          <span className="ellipsis">
            {organizer?.id ? (
              <Localize>GLOBAL.Button_Text_SaveChanges</Localize>
            ) : (
              <Localize>GLOBAL.Button_Text_CreateNew</Localize>
            )}
          </span>
        </Button>
      </div>
    </Form>
  );
};

export default OrganizerEditForm;
