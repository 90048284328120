import React from "react";
import { Dropdown } from "antd";
import { EllipsisOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import { variableUseMutation } from "../hooks";
import { EntityRemoveButton, Localize, UploadFile } from "components/service";
import Icons from "components/icons";

const VariableActionField = ({ variableList: { id, slug, group_name } }) => {
  const { _setVariableUploadFile, loadingMutationVariableUploadFile } =
    variableUseMutation.uploadFile(id);

  const { _setMutationVariableDelete, loadingMutationVariableDelete } =
    variableUseMutation.delete(id);

  const items = [
    {
      key: "1",
      icon: (
        <UploadFile
          setMutation={_setVariableUploadFile}
          loading={loadingMutationVariableUploadFile}
          accept="image/*"
          varSetMutation={{
            input: {
              model: "VariableList",
              model_id: id,
              model_field: "img_path",
            },
          }}
          uploadBtn={
            <div className="">
              <span style={{ display: "flex", alignItems: "center" }}>
                {loadingMutationVariableUploadFile ? (
                  <Loading3QuartersOutlined spin />
                ) : (
                  <Icons.Upload />
                )}{" "}
                <Localize>MENUS_DROP.Label_UploadIcon</Localize>
              </span>
            </div>
          }
        />
      ),
    },

    {
      key: "2",
      icon: (
        <EntityRemoveButton
          modalButton={
            <span style={{ display: "flex", alignItems: "center" }}>
              <Icons.Delete /> <Localize>MENUS_DROP.Label_Delete</Localize>
            </span>
          }
          nameEntity={
            Localize({ children: "ENTITY.Modal_Title_Key" }).props.children
          }
          dataNameEntity={slug}
          loading={loadingMutationVariableDelete}
          deleteMutation={_setMutationVariableDelete}
          variables={{
            group_name,
            slug,
          }}
        />
      ),
    },
  ];

  return (
    <Dropdown
      // trigger={['click']}
      placement="bottomRight"
      menu={{ items }}
      overlayClassName="drop-more-menu"
      arrow
    >
      <EllipsisOutlined className="btn-more" />
    </Dropdown>
  );
};

export default VariableActionField;
