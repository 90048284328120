import React from "react";
import { Link } from "react-router-dom";
import Organizer from "components/organizer";

const VariationOrganizerField = ({ variation }) =>
  variation?.organizer?.id ? (
    <Link
      className="table-link-underline"
      to={`${Organizer.Const.basePath}/${variation?.organizer?.id}`}
    >
      {variation?.organizer?.title}
    </Link>
  ) : (
    <strong>&mdash;</strong>
  );

export default VariationOrganizerField;
