import React, { useState } from "react";
import { Button, Input } from "antd";
import { useRouteMatch } from "react-router-dom";

import { GET_USERS } from "graphql/query/user-gql";
import { ROLE_SYNC_USERS } from "graphql/mutation/role-gql";

import Role from "components/role";
import User from "components/user";
import { useBreadCrumbs } from "components/use-hooks";
import { Tables } from "components/layout";
import { Localize, ModalStandard } from "components/service";
import Icons from "components/icons";

const { Search } = Input;

const RoleUsersSubPage = ({ role }) => {
  const basePath = Role.Const.basePath;
  const tableHelper = Role.Helpers.TableUsers;

  const breadcrumbs = [
    {
      label: Localize({ children: "BREADCRUMBS.Label_Roles" }).props.children,
      path: `${basePath}`,
    },
    {
      label: <Localize>{role.title}</Localize>,
      path: `${basePath}/${role?.id}`,
    },
    {
      label: Localize({ children: "BREADCRUMBS.Label_Users" }).props.children,
      path: `${basePath}/${role?.id}/users`,
    },
  ];

  useBreadCrumbs(breadcrumbs);

  const roleID = { column: "ID", operator: "EQ", value: parseInt(role.id) };

  let match = useRouteMatch(`${basePath}/page/:pageNum`);

  const objOrderBy = [{ column: "ID", order: "DESC" }];

  const [searchText, setSearchText] = useState("");

  return (
    <Tables.Main
      model="users"
      query={GET_USERS}
      routeUrl={`${basePath}/${role.id}/users`}
      currentPage={match ? parseInt(match.params.pageNum) : 1}
      searchText={searchText}
      objOrderBy={objOrderBy}
      varSet={{ whereRoles: roleID, perPage: 30 }}
      currentObj={role}
      tableHelper={tableHelper}
    >
      <div className="table-action-bar">
        <div className="col">
          <Search
            className="filter-search"
            prefix={<Icons.Search />}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>

        <div className="col">
          <ModalStandard
            width={400}
            extraClass={"modal-form"}
            modalButton={
              <Button type="primary" icon={<Icons.Plus />}>
                <span className="ellipsis">
                  <Localize>GLOBAL.Button_Text_AddNew</Localize>
                </span>
              </Button>
            }
          >
            <strong className="modal-title">
              <span className="text">
                <Localize>FORMS.Title_AddUsers</Localize>
              </span>
            </strong>

            <User.Forms.Add
              propsObj={{
                query: GET_USERS,
                queryVar: {
                  first: 10000,
                  whereRoles: roleID,
                },
                mutation: ROLE_SYNC_USERS,
                mutationName: "roleSyncToUsers",
                modelID: { role_id: role?.id },
              }}
            />
          </ModalStandard>
        </div>
      </div>
    </Tables.Main>
  );
};

export default RoleUsersSubPage;
