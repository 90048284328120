import React from "react";
import { useQuery } from "@apollo/client";
import { GET_SUPPLIER } from "graphql/query/supplier-gql";
import { PageWrapDefault } from "components/layout";
import { useActiveMenuItem } from "components/use-hooks";
import Supplier from "components/supplier";
import SupplierRoute from 'components/routes/supplier/supplier-route';
import { Localize } from "components/service";


const SupplierPage = ({ match, history }) => {

    useActiveMenuItem([ "suppliers" ], [ "suppliers" ]);


    const basePath = Supplier.Const.basePath;
    const path = history.location.pathname.split('/').pop()
    const supplierID = match ? parseInt(match.params.id) : null;

    const { data: { supplier } = {}, loading } = useQuery(GET_SUPPLIER, {
        skip: !supplierID,
        variables: {
            id: supplierID
        }
    });

    const pageNavbar = [
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Overview" }).props.children,
            path: ""
        },
        {
            label: Localize({ children: "NAVBAR_PAGES.Label_Users" }).props.children,
            path: "users"
        },
    ];


    return (
        <PageWrapDefault
            className="page-supplier"
            loading={ loading }
            title={ path === 'create' ? <Localize>PAGES.Title_CreateSupplier</Localize> : !loading && supplier ? `${ supplier.title }` : 'Page 404' }
            dataExist={ path === 'create' ? true : !loading && supplier }
            pageNavbar={ !loading && supplier ? pageNavbar : false }
            staticPath={ `${ basePath }/${ supplierID }` }
        >
            <SupplierRoute
                supplier={ supplier }
                history={ history }
                loading={ loading } />

        </PageWrapDefault>
    );
};

export default SupplierPage;
